import React, { useContext, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Context } from "../../SDK/context";

import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout.js";

import { FixedContent } from "../../components/AngelStyledComponents/FixedContent.js";
import { StyledDiv } from "../../components/AngelStyledComponents/StyledDiv.js";
import { WideInput } from "../../components/AngelStyledComponents/WideInput.js";
import { ContextualNotice } from "../../components/AngelStyledComponents/ContextualNotice.js";
import { TertiaryButton } from "../../components/AngelStyledComponents/TertiaryButton.js";
import { PrimaryButton } from "../../components/AngelStyledComponents/PrimaryButton.js";
import { SecondaryButton } from "../../components/AngelStyledComponents/SecondaryButton.js";
import { MemoryAnchor } from "../../components/AngelStyledComponents/Icons.js";
import { Form } from "antd";
import LoadingScreen from "../../components/LoadingScreen";
import "./SignIn.css";

import FOV from "../../SDK/src/main";

const SignIn = () => {
  const history = useHistory();
  const { state, signIn } = useContext(Context);

  const [errorVisible, setErrorVisible] = useState(false);
  const [resetVisible, setResetVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();
  const [resetForm] = Form.useForm();
  const [resettingPassword, setResettingPassword] = useState(false);
  const [passwordResetError, setPasswordResetError] = useState("");

  const [submitting, setSubmitting] = useState(false);

  function openModal() {
    const email = form.getFieldsValue().email;
    setEmail(email);
    FOV.api
      .forgotPassword(email)
      .then((result) => {
        setModalVisible(true);
      })
      .catch();
  }
  function cancelReset() {
    setModalVisible(false);
    setEmail("");
  }
  const reset = (values) => {
    setResettingPassword(true);
    FOV.api
      .resetPassword(email, values.confirmationCode, values.password)
      .then(() => {
        setResettingPassword(false);
        setModalVisible(false);
      })
      .catch((result) => {
        if (result.resonse && result.response.data.message) {
          setPasswordResetError(result.response.data.message);
        }
        setResettingPassword(false);
      });
  };
  const onFinish = (values) => {
    setSubmitting(true);
    signIn(values)
      .then((email) => {
        setSubmitting(false);
        history.push(`/${email}`);
      })
      .catch((result) => {
        if (result.response) {
          const data = result.response.data;
          switch (data.code) {
            case "UserNotFoundException":
              // show email not recognized
              setResetVisible(false);
              setErrorVisible(true);
              break;
            case "NotAuthorizedException":
              setErrorVisible(false);
              setResetVisible(true);
              // show reset password
              break;
            default:
              break;
          }
        }
      });
  };
  return !state.signedIn || !state.email ? (
    <>{submitting && <LoadingScreen />}
      <StyledLayout backgroundColor="#0000" style={{ height: "100%" }}>
        <FixedContent>
          <div className="signIn__memoryAnchorDiv">
            <MemoryAnchor className="signIn__memoryAnchor" />
          </div>
          <Form
            className="signIn__form"
            form={form}
            onFinish={onFinish}
            autoComplete="offfdfdjkd"
          >
            <StyledDiv className="signIn__styledDiv" >
              <h2 className="signIn__headerStyle">Enter email:</h2>
            </StyledDiv>

            <WideInput
              type="email"
              placeholder="Enter Email"

              formProps={{
                name: "email",
                hasFeedback: true,
                rules: [{ required: true }],
              }}
            />
            {errorVisible && (
              <ContextualNotice type="error">
                <p
                  style={{
                    color: "white",
                    margin: "auto 5px",
                  }}
                >
                  Email not recognized
                </p>
              </ContextualNotice>
            )}
            <StyledDiv
              style={{
                boxShadow: "0 0 3px rgba(0, 0, 0, 0.16)",
              }}
            >
              <h2 className="signIn__headerStyle">password:</h2>
            </StyledDiv>
            <WideInput
              type="password"
              placeholder="Enter Password"
              formProps={{
                name: "password",
                hasFeedback: true,
                rules: [{ required: true }],
              }}
            />
            {resetVisible && (
              <ContextualNotice type="warning">
                <p
                  style={{
                    color: "white",
                    margin: "auto 5px",
                  }}
                >
                  Incorrect password
                </p>
                <TertiaryButton onClick={openModal}>
                  Reset Password
                </TertiaryButton>
              </ContextualNotice>
            )}
            <StyledDiv
              style={{
                gridTemplateColumns: "1fr",
                justifyItems: "center",
                boxShadow:
                  "0 0 3px rgba(0, 0, 0, 0.16),0 3px 6px rgba(0, 0, 0, 0.16)",
              }}
            >
              <PrimaryButton htmlType="submit" disabled={submitting}>Login</PrimaryButton>
            </StyledDiv>
          </Form>
        </FixedContent>
        {modalVisible && (
          <div className="signIn__passwordModal">
            <Form className="signIn__form" form={resetForm} onFinish={reset}>
              <StyledDiv
                className="signIn__styledDiv"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h2 style={{ fontSize: "15px" }}>
                  A verification code has been sent to
                  <br />
                  {email}
                </h2>
              </StyledDiv>
              <WideInput
                readOnly={true}
                type="text"
                placeholder="Enter Confirmation Code"
                formProps={{
                  name: "confirmationCode",
                  hasFeedback: true,
                  rules: [{ required: true }],
                }}
              />
              <WideInput
                readOnly={true}
                type="password"
                placeholder="Enter New Password"
                formProps={{
                  name: "password",
                  hasFeedback: true,
                  rules: [{ required: true }],
                }}
              />
              {passwordResetError && (
                <h2 style={{ fontSize: "15px" }}>{passwordResetError}</h2>
              )}
              <StyledDiv
                style={{
                  gridTemplateColumns: "1fr",
                  justifyItems: "center",
                  boxShadow:
                    "0 0 3px rgba(0, 0, 0, 0.16),0 3px 6px rgba(0, 0, 0, 0.16)",
                  padding: 0,
                }}
              >
                <StyledDiv
                  style={{
                    width: "100%",
                    gridTemplateColumns: "1fr 1fr",
                    justifyItems: "center",
                  }}
                >
                  <SecondaryButton
                    style={{}}
                    htmlType="submit"
                    disabled={resettingPassword}
                  >
                    Set Password
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={cancelReset}
                    disabled={resettingPassword}
                  >
                    Cancel
                  </PrimaryButton>
                </StyledDiv>
              </StyledDiv>
            </Form>
          </div>
        )}
      </StyledLayout>
    </>
  ) : (
    <Redirect to={{ pathname: `${state.email}` }} />
  );
};

export default SignIn;
