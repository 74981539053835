import Request from '../utility/Request.js'

class Profile extends Request {
  constructor() {
    super('get', `profile`)
  }
  sendRequest() {
    return this.sendRequestPromise({})
  }
}

export default Profile
