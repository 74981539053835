import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Context } from "../../SDK/context";
import { Link } from "react-router-dom";
import FOV from "../../SDK/src/main";

import { PageTitle } from "../../components/AngelStyledComponents/PageTitle";
import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent";
import { CenteredDiv } from "../../components/AngelStyledComponents/CenteredDiv";
import { TertiaryButton } from "../../components/AngelStyledComponents/TertiaryButton";
import { SaveOrDiscard } from "../../components/AngelStyledComponents/SaveOrDiscard";
import PathEditor, { mapToArrayObj } from "../../components/catmaps/PathEditor";

import "styled-components/macro";
import AutoOverview from "../../SDK/components/AutoOverview";
import { StyledDiv } from "../../components/AngelStyledComponents/StyledDiv";
import { SecondaryButton } from "../../components/AngelStyledComponents/SecondaryButton";

import WarningLink from "../../components/WarningLink/warning-link";
import LoadingScreen from "../../components/LoadingScreen";

const midPoint = (point1, point2) => {
  return [(point1[0] + point2[0]) / 2, (point1[1] + point2[1]) / 2];
};

const emptyPoint = [0, 0];

export const SiteLinks = ({ email, cemeteryID }) => {
  const history = useHistory();
  function navigateToSiteEditor() {
    history.push(`/${email}/${cemeteryID}/edit`);
  }
  function navigateToMapEditor() {
    history.push(`/${email}/${cemeteryID}/map`);
  }
  return (
    <StyledDiv
      style={{
        gridTemplateColumns: "1fr 1fr",
        justifyItems: "center",
        backgroundColor: "#475f75",
      }}
    >
      <SecondaryButton
        style={{ height: "56px" }}
        onClick={navigateToSiteEditor}
      >
        Site Editor
      </SecondaryButton>
      <SecondaryButton style={{ height: "56px" }} onClick={navigateToMapEditor}>
        Map Editor
      </SecondaryButton>
    </StyledDiv>
  );
};

const PathingEditor = () => {
  const { cemetery } = useParams();
  const { state } = useContext(Context);
  const originalFile = React.useRef(new Map());
  const [pathFile, setPathFile] = useState(new Map());
  const [lastNode, setLastNode] = useState();
  const [submitting, setSubmitting] = useState(false);

  const [imageCoords, setImageCoords] = useState([
    [0, 0],
    [1, 0],
    [1, -1],
    [0, -1],
  ]);

  const [jump, setJump] = useState(emptyPoint);

  const [randomQuery, setRandomQuery] = useState("?" + Math.random());

  useEffect(() => {
    setImageCoords([
      [state.overview.MapALng, state.overview.MapALat],
      [state.overview.MapBLng, state.overview.MapBLat],
      [state.overview.MapCLng, state.overview.MapCLat],
      [state.overview.MapDLng, state.overview.MapDLat],
    ]);
  }, [state.overview]);

  useEffect(() => {
    async function getPathFile(fileUrl) {
      return await (await fetch(fileUrl)).json();
    }
    FOV.api
      .getCemetery(cemetery)
      .then((result) => {
        const newCemeteryItem = result.data.Item;
        if (newCemeteryItem.PathFile) {
          getPathFile(newCemeteryItem.PathFile + randomQuery).then((res) => {
            originalFile.current = new Map(
              res.map.map((entry) => [entry.key, entry.value])
            );
            setPathFile(
              new Map(res.map.map((entry) => [entry.key, entry.value]))
            );
          });
        }
      })
      .catch(console.error);
  }, [cemetery]);

  function containsCoords(item) {
    if (!item) return false;
    const keys = Object.keys(item);
    return (
      keys.includes("MapALng") &&
      keys.includes("MapALat") &&
      keys.includes("MapCLng") &&
      keys.includes("MapCLat")
    );
  }

  useEffect(() => {
    if (containsCoords(state.overview)) {
      setJump(
        midPoint(
          [state.overview.MapALng, state.overview.MapALat],
          [state.overview.MapCLng, state.overview.MapCLat]
        )
      );
    }
  }, [state.overview]);
  // /**
  //  * Returns number representing a boolean for
  //  * the graph being connected
  //  * @param {Array} matrix adjacency matrix
  //  * @returns number
  //  */
  /*
  function isConnected(matrix) {
    // matrix size (n x n)
    const n = matrix[0].length;
    // calculating laplacian matrix
    // initialising to negative of matrix
    const laplacian = matrix.map((row) => row.map((a) => -a));
    // adding degree diag
    for (let i = 0; i < n; ++i) {
      laplacian[i][i] = matrix[i].reduce((acc, a) => acc + a);
    }
    // converting to mathjs matrix
    const mathLaplacian = new mathjs.matrix(laplacian);
    // calculating eigen values
    const eigs = mathjs.eigs(mathLaplacian, 1e-2).values.valueOf();
    // getting the second smallest eigen value to 6dp
    const connected = parseFloat(Math.abs(eigs[1]).toFixed(6));
    return connected;
  }
  */

  // function convertMapToAdjacencyMatrix(map) {
  //   const mapping = {};
  //   for (let point of map) {
  //     mapping[point.key] = point.value.adjacent;
  //   }
  //   const ids = Object.keys(mapping).sort();
  //   const adjacencyMatrix = [];
  //   for (let idColumn of ids) {
  //     const row = [];
  //     for (let idRow of ids) {
  //       if (mapping[idColumn].includes(idRow)) {
  //         row.push(1);
  //       } else {
  //         row.push(0);
  //       }
  //     }
  //     adjacencyMatrix.push(row);
  //   }
  //   return adjacencyMatrix;
  // }

  function saveJson() {
    setSubmitting(true);
    console.log(pathFile)
    const mapToArray = mapToArrayObj(pathFile);
    //const adjacencyMatrix = convertMapToAdjacencyMatrix(mapToArray);
    //console.log(adjacencyMatrix);
    // if (!isConnected(adjacencyMatrix)) {
    //   alert("Unable to save; Path is not connected");
    //   return;
    // }
    const jsonStr = JSON.stringify({ map: mapToArray });
    FOV.api
      .getMediaUrl(cemetery, "cemetery", "unused", "pathfile", "PathFile.json")
      .then((mediaUrlResult) => {
        const uploadUrl = mediaUrlResult.data.url;
        FOV.api
          .uploadMedia(
            new Blob([jsonStr], { type: "text/json" }),
            "pathfile",
            uploadUrl
          )
          .then(() => {
            FOV.api
              .getCemetery(cemetery)
              .then((result) => {
                let newCemeteryItem = result.data.Item;
                newCemeteryItem.PathFile = uploadUrl.split("?")[0];

                FOV.api
                  .updateCemetery(cemetery, newCemeteryItem)
                  .then(() => {
                    setSubmitting(false);
                  })
                  .catch(console.error);
              })
              .catch(console.error);
          })
          .catch(console.error);
      })
      .catch(console.error);
  }

  function discard() {
    setLastNode();
    setPathFile(new Map(originalFile.current));
  }

  return (
    <>{submitting && <LoadingScreen />}
      <StyledLayout>
        <FixedContent>
          <AutoOverview id={cemetery} />
          <PageTitle
            css={`
            background-color: red;
            color: orange;
          `}
          >
            Pathing Editor
          </PageTitle>
          {containsCoords(state.overview) ? (
            <>
              <p
                style={{
                  width: "100%",
                  maxWidth: "720px",
                  color: "white",
                  backgroundColor: "#2C3B4A",
                  padding: "20px 0",
                  textAlign: "center",
                  textTransform: "uppercase",
                  margin: 0,
                }}
              >
                Click on the map to add a pathing point
              </p>
              <PathEditor
                containerStyle={{
                  width: "100%",
                  maxWidth: "720px",
                  height: "720px",
                }}
                pathFile={pathFile}
                setPathFile={setPathFile}
                lastNode={lastNode}
                setLastNode={setLastNode}
                map={pathFile}
                location={jump}
                imageCoord={imageCoords}
                imageUrl={state.overview.Map + randomQuery}
                imageOpacity={0.8}
              />
              <SaveOrDiscard save={saveJson} discard={discard} disabled={submitting} />
              <CenteredDiv>
                <TertiaryButton style={{ width: "220px" }}>
                  <Link to={`/${state.email}/${cemetery}/tours`}>
                    Go to Tours Overview
                  </Link>
                </TertiaryButton>
              </CenteredDiv>
              <CenteredDiv>
                <TertiaryButton style={{ width: "220px" }}>
                  <Link to={`/${state.email}/${cemetery}/edit`}>
                    Go to Site Editor
                  </Link>
                </TertiaryButton>
              </CenteredDiv>
            </>
          ) : (
            <WarningLink
              message={
                "Before you can create a path, you must provide map coordinates"
              }
              pathName={"Map Editor"}
              path={`/${state.email}/${cemetery}/map`}
            />
          )}
          <SiteLinks email={state.email} cemeteryID={cemetery} />
        </FixedContent>
      </StyledLayout>
    </>
  );
};

export default PathingEditor;
