import React from "react";

import { CenteredDiv } from "./CenteredDiv.js";
import { TertiaryButton } from "./TertiaryButton.js";

export const ToggleButton = (props) => {
  const [open, setOpen] = React.useState(false);
  const { buttonText, children } = props;
  return (
    <>
      <CenteredDiv>
        <TertiaryButton onClick={() => setOpen(!open)}>
          {buttonText}
        </TertiaryButton>
      </CenteredDiv>
      {open && children}
    </>
  );
};
