import React, { useState } from "react";
import styled from "styled-components";
import { Layout } from "antd";
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import AutoSignIn from "../../SDK/components/AutoSignIn";
import SignIn from "../../views/SignIn";
import SiteManager from "../../views/SiteManager";
import SiteEditor from "../../views/SiteEditor";
import Organizations from "../../views/Organizations";
import AllOrganizations from "../../views/AllOrganizations";
import OrganizationEditor from "../../views/OrganizationEditor";
import SiteMapEditor from "../../views/SiteMapEditor";
import PathingEditor from "../../views/PathingEditor";
import ToursOverview from "../../views/ToursOverview";
import SiteOverview from "../../views/SiteOverview";
import EditRecord from "../../views/EditRecord";
import BulkUpload from "../../views/BulkUpload";
import BulkUploadGuide from "../../views/BulkUploadGuide";
import ViewAllRecords from "../../views/ViewAllRecords";
import ViewAllCemeteries from "../../views/ViewAllCemeteries";
import ToursEditor from "../../views/ToursEditor";

import { StyledHeader } from "../AngelStyledComponents/StyledHeader.js";
import Hamburger from "../Hamburger";

const StyledLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  width: 100%;
`;

const Routes = () => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  function toggleHamburger() {
    setIsHamburgerOpen(!isHamburgerOpen);
  }

  return (
    <BrowserRouter>
      <Layout>
        <Hamburger isOpen={isHamburgerOpen} />
        <Layout className="site-layout">
          <StyledHeader
            toggleHamburger={toggleHamburger}
            isHamburgerOpen={isHamburgerOpen}
          />
          <AutoSignIn />
          <StyledLayout>
            <Switch>
              <Route path="/" exact>
                {<SignIn />}
              </Route>
              <PrivateRoute
                path="/:email/:cemetery/all-organizations"
                component={withRouter(AllOrganizations)}
              />
              <PrivateRoute
                path="/:email/:cemetery/organizations"
                component={withRouter(Organizations)}
              />
              <PrivateRoute
                path="/:email/organization/:organization"
                component={withRouter(OrganizationEditor)}
              />
              <PrivateRoute
                path="/:email/organization"
                component={withRouter(OrganizationEditor)}
              />
              <PrivateRoute
                path="/:email/add-cemetery"
                component={withRouter(SiteEditor)}
              />
              <PrivateRoute
                path="/:email/view-all"
                component={withRouter(ViewAllCemeteries)}
              />
              <PrivateRoute
                path="/:email/:cemetery/tours/:id"
                component={withRouter(ToursEditor)}
              />
              <PrivateRoute
                path="/:email/:cemetery/tours"
                component={withRouter(ToursOverview)}
              />
              <PrivateRoute
                path="/:email/:cemetery/map"
                component={withRouter(SiteMapEditor)}
              />
              <PrivateRoute
                path="/:email/:cemetery/pathing"
                component={withRouter(PathingEditor)}
              />
              <PrivateRoute
                path="/:email/:cemetery/tours"
                component={withRouter(ToursOverview)}
              />
              <PrivateRoute
                path="/:email/:cemetery/edit"
                component={withRouter(SiteEditor)}
              />
              <PrivateRoute
                path="/:email/:cemetery/record/:recordType/:id"
                component={withRouter(EditRecord)}
              />
              <PrivateRoute
                path="/:email/:cemetery/record"
                component={withRouter(EditRecord)}
              />
              <PrivateRoute
                path="/:email/:cemetery/upload/guide"
                component={withRouter(BulkUploadGuide)}
              />
              <PrivateRoute
                path="/:email/:cemetery/upload"
                component={withRouter(BulkUpload)}
              />
              <PrivateRoute
                path="/:email/:cemetery/view-all/:recordType"
                component={withRouter(ViewAllRecords)}
              />
              <PrivateRoute
                path="/:email/:cemetery/view-all"
                component={withRouter(ViewAllRecords)}
              />
              <PrivateRoute
                path="/:email/:cemetery"
                component={withRouter(SiteOverview)}
              />

              <PrivateRoute
                path="/:email"
                component={withRouter(SiteManager)}
              />
            </Switch>
          </StyledLayout>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
};

export default Routes;
