import React from "react";

import { TertiaryButton } from "./TertiaryButton.js";
import { Upload } from "antd";
import { ConditionalWrapper } from "./ConditionalWrapper.js";
import { Form } from "antd";

import "./ImageUpload.css";

export const ImageUpload = (props) => {
  const { fileName, src, onClick, onChange, setRandomQuery, formProps } = props;

  const input = React.useRef(null);

  return (
    <div
      style={{
        display: "flex",
        height: "160px",
        padding: "20px",
        width: "720px",
        maxWidth: "100%",
        margin: "auto",
      }}
    >
      <div
        style={{
          flexShrink: 0,
          marginRight: "20px",
          overflow: "hidden",
          height: "120px",
          width: "auto",
        }}
        className="imageUpload__thumbnailContainer"
      >
        <img
          className="imageUpload__thumbnail"
          onClick={onClick}
          {...{ src }}
          style={{
            border: "none",
            minWidth: "auto",
            width: "auto",
            height: "120px",
            justifySelf: "center",
          }}
          alt="placeholder"
        />
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          width: "100%",
          minWidth: 0,
          flexDirection: "column",
        }}
      >
        <p
          style={{
            margin: "0",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            color: "#fff",
            padding: "4px 11px",
            height: "60%",
            overflow: "hidden",
            // whiteSpace: "nowrap",
            wordWrap: "break-word",
            textOverflow: "ellipsis",
            minWidth: "190px",
          }}
        >
          {fileName ? fileName.split("?")[0] : ""}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            padding: "10px",
            height: "40%",
            minWidth: "190px",
          }}
        >
          <ConditionalWrapper
            style={{ width: "100%" }}
            condition={formProps}
            wrapper={(children) => (
              <Form.Item {...formProps} getValueFromEvent={(e) => e.file}>
                {children}
              </Form.Item>
            )}
          >
            <input
              style={{ display: "none" }}
              type="file"
              onChange={onChange}
              ref={input}
            />
            <Upload>
              <TertiaryButton
                style={{
                  width: "auto",
                  marginRight: "5px",
                }}
                onClick={(e) => {
                  input.current.click();
                  e.stopPropagation();
                }}
              >
                Upload New
              </TertiaryButton>
            </Upload>
          </ConditionalWrapper>

          <TertiaryButton
            style={{
              width: "auto",
              marginLeft: "5px",
            }}
            onClick={() => {
              setRandomQuery(Math.random());
            }}
          >
            Reload
          </TertiaryButton>
        </div>
      </div>
    </div>
  );
};
