import {
  filetoDataURL,
  dataURLtoFile,
  compressAccurately,
} from "image-conversion";

function convertAndCompress(file, size) {
  return new Promise((resolve, reject) => {
    // if (file.type.split("/")[0] !== "image") {

    //   reject("file is not an image");
    // } else {
    filetoDataURL(file)
      .then((dataUrl) => {
        dataURLtoFile(dataUrl, "image/jpeg")
          .then((file) => {
            compressAccurately(file, {
              size, //The compressed image size is 10mb
              accuracy: 0.9, //the accuracy of image compression size,range 0.8-0.99,default 0.95;
              //this means if the picture size is set to 1000Kb and the
              //accuracy is 0.9, the image with the compression result
              //of 900Kb-1100Kb is considered acceptable;
              type: "image/jpeg",
            })
              .then(resolve)
              .catch(reject);
          })
          .catch(reject);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
    // }
  });
}

export { convertAndCompress };
