import { Link } from "react-router-dom";
import { WideTertiaryButton } from "./WideTertiaryButton.js";

export const CemeteryOptions = ({ site, email }) => {
  return (
    <div
      style={{
        display: "grid",
        gridAutoFlow: "row",
      }}
    >
      <WideTertiaryButton>
        {/* <Link to={`${email}/${site}/edit`}>Site details</Link> */}
        <Link onClick={() => {
          window.location.replace(`${email}/${site}/edit`)
        }}>Site details</Link>

      </WideTertiaryButton>
      <WideTertiaryButton>
        {/* <Link to={`${email}/${site}/map`}>Site map editor</Link> */}
        <Link onClick={() => {
          window.location.replace(`${email}/${site}/map`)
        }}>Site map editor</Link>

      </WideTertiaryButton>
      <WideTertiaryButton>
        {/* <Link to={`${email}/${site}/pathing`}>Site pathing editor</Link> */}
        <Link onClick={() => {
          window.location.replace(`${email}/${site}/pathing`)
        }}>Site pathing editor</Link>
      </WideTertiaryButton>
    </div>
  );
};
