import React from "react";
// import { RiMapPinAddFill, RiEditBoxFill } from "react-icons/ri";
import { FormOutlined, EnvironmentOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Mode } from "./Mode";
import "./Table.css";

export const PathingControls = ({ mode, onModeClick }) => {
  return (
    <Controls>
      <AddButton
        selected={mode === Mode.Add}
        onClick={() => onModeClick(Mode.Add)}
      />
      <EditButton
        selected={mode === Mode.Edit}
        onClick={() => onModeClick(Mode.Edit)}
      />
    </Controls>
  );
};

export const ControlsAgenda = (props) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <Controls
      className="mapControls"
      style={{
        left: "50%",
        transform: "translate(-50%)",
        bottom: "0%",
        boxShadow: "none",
        border: "none",
        marginBottom: "10px",
      }}
    >
      <ControlButton
        onClick={() => setVisible(!visible)}
        style={{ height: "50px", margin: "auto" }}
        selected={visible}
      >
        {visible ? "Close Map Controls" : "Show Map Controls"}
      </ControlButton>
      <table
        style={{
          display: visible ? "table" : "none",
          color: "white",
          margin: "20px 0",
        }}
      >
        <tbody>
          <tr>
            <th>ACTION</th>
            <th>TABLE/MOBILE</th>
            <th>DESKTOP</th>
          </tr>
          <tr>
            <td>Move map</td>
            <td>Hold and Move</td>
            <td>Click and drag</td>
          </tr>
          <tr>
            <td>Zoom</td>
            <td>Two finger pinch</td>
            <td>Scroll</td>
          </tr>
          <tr>
            <td>Rotate</td>
            <td>Two finger drag</td>
            <td>Right click and drag</td>
          </tr>
        </tbody>
      </table>
    </Controls>
  );
};

const Controls = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const ControlButton = styled.button`
  background-color: ${(props) => (props.selected ? "#c96015" : "#787878")};
  color: white;
  opacity: 0.95;
  transition: background-color 0.16s ease-out;
  cursor: pointer;
  border: 0;
  height: 32px;
  width: 132px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
`;

const AddButton = (props) => {
  return (
    <ControlButton {...props}>
      {/* <RiMapPinAddFill style={{ width: 22, height: 22 }} /> */}
      <EnvironmentOutlined
        style={{ margin: "0px 12px 0px 3px", fontSize: 22 }}
      />
      Add point
    </ControlButton>
  );
};

const EditButton = (props) => {
  return (
    <ControlButton {...props}>
      {/* <RiEditBoxFill style={{ width: 22, height: 22 }} /> */}
      <FormOutlined style={{ margin: "0px 12px 0px 3px", fontSize: 22 }} />
      EDIT POINT
    </ControlButton>
  );
};
