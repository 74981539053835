import React from "react";
import { StyledDiv } from "./StyledDiv.js";
import { TertiaryButton } from "./TertiaryButton.js";

import "./CemeteryButton.css";

export const CemeteryButton = (props) => {
  const { src, title, onClick, children } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <StyledDiv
        style={{
          gridTemplateColumns: "9fr 1fr",
          padding: "0 22px !important",
        }}
      >
        <button
          className="cemeteryButton__overview"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            // justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "#0000",
            border: "none",
            textAlign: "center !important",
          }}
          onClick={onClick}
          type="button"
        >
          <div
            style={{
              minWidth: "120px",
              maxWidth: "120px",
              overflow: "hidden",
            }}
          >
            <img
              src={src}
              style={{
                height: "64px",
                width: "auto",
              }}
              alt="placholder"
            />
          </div>
          <h2
            style={{
              textTransform: "uppercase",
              color: "#fff",
              margin: "auto 20px ",
            }}
          >
            {title}
          </h2>
        </button>
        <TertiaryButton
          style={{
            width: "63px",
            justifySelf: "end",
          }}
          onClick={() => setOpen(!open)}
        >
          Edit
        </TertiaryButton>
      </StyledDiv>
      {open && children}
    </>
  );
};
