import Request from "../utility/Request.js";

class DeleteRecord extends Request {
  constructor() {
    super("delete", ``);
  }
  sendRequest() {
    const args = arguments[0];
    const cemetery = args[0];
    const type = args[1];
    const id = args[2];

    this.url = `record/${cemetery}/${type}/${id}`;
    return this.sendRequestPromise();
  }
}

export default DeleteRecord;
