import Request from "../utility/Request.js";

class GetOrganization extends Request {
  constructor() {
    super("get", ``);
  }
  sendRequest(organization) {
    this.url = `organization/${organization}`;
    return this.sendRequestPromise({});
  }
}

export default GetOrganization;
