import React, { useState, useContext, useEffect } from "react";
import { Layout, Menu } from "antd";
import { Context } from "../../SDK/context";
import FOV from "../../SDK/src/main";
import { NavLink } from "react-router-dom";
import { PushpinOutlined, HomeOutlined } from "@ant-design/icons";

// cemeteryLink creates a route with email and cemetery ID

// Hamburger is the exported Hamburger sidebar menu, using Antd's <Sider>
function Hamburger({ isOpen }) {
  const { state } = useContext(Context);
  const [cemeteryItems, setCemeteryItems] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (state.cemeteries.length) {
        await FOV.api
          .bulkGetCemeteries(state.cemeteries.slice(0, 100))
          .then((result) => {
            setCemeteryItems(
              result.data.Responses[Object.keys(result.data.Responses)[0]].sort(
                (a, b) => a.SK.localeCompare(b.SK)
              )
            );
          })
          .catch(console.error);
      }
    }
    fetchData();
  }, [state.cemeteries]);

  function cemeteryLink(cemID, path) {
    return `/${state.email}/${cemID}/${path}`;
  }
  return (
    <Layout.Sider
      collapsible
      trigger={null}
      collapsedWidth={0}
      collapsed={!isOpen}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      <Menu theme="dark" mode="inline">
        <Menu.ItemGroup
          icon={<HomeOutlined />}
          title="Memory Anchor"
          key={"submenu-1"}
        >
          <Menu.Item key="home-1">
            <NavLink to={`/${state.email}`}>Site Manager</NavLink>
          </Menu.Item>
          <Menu.Item key="home-2">
            <NavLink to={`/${state.email}/view-all`}>View All Sites</NavLink>
          </Menu.Item>
        </Menu.ItemGroup>
        {cemeteryItems.map((c) => (
          <Menu.SubMenu
            icon={<PushpinOutlined />}
            title={c.Name}
            key={`${c.SK}-submenu`}
          >
            <Menu.Item key={`${c.SK}-1`}>
              <NavLink to={cemeteryLink(c.SK, "")}>Records Overview</NavLink>
            </Menu.Item>
            <Menu.Item key={`${c.SK}-2`}>
              <NavLink to={cemeteryLink(c.SK, "edit")}>Edit Site</NavLink>
            </Menu.Item>
            <Menu.Item key={`${c.SK}-3`}>
              <NavLink to={cemeteryLink(c.SK, "tours")}>Tours Overview</NavLink>
            </Menu.Item>
            <Menu.Item key={`${c.SK}-4`}>
              <NavLink to={cemeteryLink(c.SK, "pathing")}>Path Editor</NavLink>
            </Menu.Item>
            <Menu.Item key={`${c.SK}-5`}>
              <NavLink to={cemeteryLink(c.SK, "map")}>Map Editor</NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        ))}
      </Menu>
    </Layout.Sider>
  );
}

export default Hamburger;
