import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../SDK/context";
import { useHistory, useParams } from "react-router-dom";
import AutoOverview from "../../SDK/components/AutoOverview";

import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout.js";

import { PageTitle } from "../../components/AngelStyledComponents/PageTitle.js";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent.js";

import NewForm from "../../components/NewForm";
import { StyledDiv } from "../../components/AngelStyledComponents/StyledDiv";
import { SecondaryButton } from "../../components/AngelStyledComponents/SecondaryButton";

const fieldMapping = [
  {
    name: "Map",
    label: "Site Map",
    type: "image",
  },
  { name: "MapALng", label: "MapALng", type: "coord", required: true },
  { name: "MapALat", label: "MapALat", type: "coord", required: true },
  { name: "MapBLng", label: "MapBLng", type: "coord", required: true },
  { name: "MapBLat", label: "MapBLat", type: "coord", required: true },
  { name: "MapCLng", label: "MapCLng", type: "coord", required: true },
  { name: "MapCLat", label: "MapCLat", type: "coord", required: true },
  { name: "MapDLng", label: "MapDLng", type: "coord", required: true },
  { name: "MapDLat", label: "MapDLat", type: "coord", required: true },
];

const pictureFields = ["Map"];

export const SiteLinks = ({ email, cemeteryID }) => {
  const history = useHistory();
  function navigateToSiteEditor() {
    history.push(`/${email}/${cemeteryID}/edit`);
  }
  function navigateToPathingEditor() {
    history.push(`/${email}/${cemeteryID}/pathing`);
  }
  return (
    <StyledDiv
      style={{
        gridTemplateColumns: "1fr 1fr",
        justifyItems: "center",
        backgroundColor: "#475f75",
      }}
    >
      <SecondaryButton
        style={{ height: "56px" }}
        onClick={navigateToSiteEditor}
      >
        Site Editor
      </SecondaryButton>
      <SecondaryButton
        style={{ height: "56px" }}
        onClick={navigateToPathingEditor}
      >
        Pathing Editor
      </SecondaryButton>
    </StyledDiv>
  );
};

const SiteEditor = () => {
  const { cemetery } = useParams();
  const { state } = useContext(Context);
  const [originalItem, setOriginalItem] = useState({});

  useEffect(() => {
    if (state.overview.SK === cemetery) {
      let newForm = JSON.parse(JSON.stringify(state.overview));
      setOriginalItem(newForm);
    }
  }, [state.overview]);

  return state.cemeteries.includes(cemetery) ? (
    <div className="siteEditor">
      <StyledLayout>
        <FixedContent>
          <PageTitle>Map Editor</PageTitle>
          <AutoOverview id={cemetery} />
          <NewForm
            originalItem={originalItem}
            fieldMapping={fieldMapping}
            pictureFields={pictureFields}
            type="cemetery"
            includeMedia={false}
          />
          <SiteLinks email={state.email} cemeteryID={cemetery} />
        </FixedContent>
      </StyledLayout>
    </div>
  ) : (
    <div>You are not authorised for this cemetery {cemetery}</div>
  );
};

export default SiteEditor;
