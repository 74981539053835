import Controller from "../Controller.js";
import Request from "../utility/Request.js";
// import jwtDecode from "jwt-decode";

class Refresh extends Request {
  constructor() {
    super("get", `refresh`);
  }
  sendRequest() {
    return this.sendRequestPromise();
  }
  resolveRequest(result, resolve) {
    Controller.idToken = result.data.AuthenticationResult.IdToken;
    // const decoded = jwtDecode(Controller.idToken)
    // if (decoded['email']) {
    //   Controller.email = decoded['email']
    // }
    resolve(result);
  }
}

export default Refresh;
