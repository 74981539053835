import React, { useState } from "react";
import { Input } from "antd";
import { useParams } from "react-router-dom";
import FOV from "../../SDK/src/main.js";

// Material UI
import { AutoComplete } from "antd";
// import Autocomplete from "@material-ui/lab/Autocomplete";

const Search = ({
  type,
  queryBy,
  filterBy,
  setResult,
  setAllResults,
  listResults = true,
}) => {
  const { cemetery } = useParams();
  const [lastValue, setLastValue] = useState("");
  const [results, setResults] = useState([]);
  const [value, setValue] = useState(null);

  // function performSearch() {
  //   if (valueRef.current.value !== lastValue) {
  //     setLastValue(valueRef.current.value);
  //     FOV.api
  //       .search(cemetery, `${type}s`, queryBy, filterBy, valueRef.current.value)
  //       .then((searchResults) => {
  //         let newResults = [];
  //         if (searchResults.hits.length) {
  //           for (let hit of searchResults.hits) {
  //             newResults.push({
  //               title: queryBy
  //                 .split(",")
  //                 .map((a) => hit.document[a])
  //                 .join(" "),
  //               id: hit.document.id,
  //             });
  //           }
  //         }

  //         setResults(newResults);
  //         if (setAllResults) {
  //           setAllResults(newResults);
  //         }
  //       });
  //   }
  // }
  function performSearch(val) {
    if (val !== lastValue) {
      setLastValue(val);
      FOV.api
        .search(cemetery, `${type}s`, queryBy, filterBy, val)
        .then((result) => {
          let searchResults = result.data;

          let newResults = [];
          if (searchResults.hits.length) {
            for (let hit of searchResults.hits) {
              newResults.push({
                value: queryBy
                  .split(",")
                  .map((a) => hit.document[a])
                  .join(" "),
                key: `${hit.document.id}`,
                id: `${hit.document.id}`,
              });
            }
          }
          setResults(newResults);
          if (setAllResults) {
            setAllResults(newResults);
          }
        });
    }
  }

  // const keyup = () => {
  //   if (valueRef.current.value.trim()) {
  //     performSearch(value);
  //   } else {
  //     setLastValue("");
  //     setResults([]);
  //     if (setAllResults) {
  //       setAllResults([]);
  //     }
  //   }
  // };
  const keyup = (val) => {
    if (val.trim()) {
      performSearch(val);
    } else {
      setLastValue("");
      setResults([]);
      if (setAllResults) {
        setAllResults([]);
      }
    }
  };

  function onChange(data) {
    setValue(data);
    keyup(data);
  }
  function onSelect(newValue, option) {
    setValue(newValue);

    if (option) {
      const ID = option.id.split("_")[1];
      FOV.api
        .getRecord(cemetery, type, ID)
        .then((result) => {
          result.data.Item.Id = result.data.Item.SK.split("_")[1];
          if (setResult) {
            setResult(result.data.Item);
          }
        })
        .catch(console.error);
    } else {
      setResult({});
    }
  }

  if (listResults) {
    return (
      <AutoComplete
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        options={results}
        style={{ width: "100%" }}
      />
    );
  } else {
    return (
      <Input
        label={`Search ${type}`}
        value={value}
        margin="normal"
        variant="outlined"
        type="text"
        onChange={(e) => {
          onChange(e.target.value);
        }}
        // onKeyUp={keyup}
        // inputRef={valueRef}
      />
    );
  }
};

export default Search;
