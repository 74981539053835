import React from "react";
import styled from "styled-components";
import {
  UpSquareOutlined,
  DownSquareOutlined,
  CloseSquareOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

export const TourList = styled.table`
  width: 100%;
  max-width: 720px;
  background-color: #2c3b4a;
  padding: 40px;
  color: white;
  border: none;
  margin: auto;
  font-size: 18px;
`;
const TourRow = styled.tr`
  background-color: #0000;
  border: none;
  width: 100%;
  max-width: 720px;
`;
const TourData = styled.td`
  background-color: #0000;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
`;
const IconStyle = { padding: "0 5px" };
const Styleless = styled.div`
  background: none;
  border: none;
  color: ${(props) => props.disabled && "#777"};
`;
export const TourItem = (props) => {
  const {
    point,
    index,
    disabled,
    onMoveUp,
    onMoveDown,
    onNameClick,
    onRemove,
  } = props;
  const [up, down] = disabled;
  return (
    <TourRow>
      <TourData>
        <MinusCircleOutlined style={IconStyle} />
      </TourData>
      <TourData>{index}</TourData>
      <TourData>
        <Styleless
          as="button"
          onClick={() => onNameClick([point.lng, point.lat])}
        >
          {nameFormat(point)}
        </Styleless>
      </TourData>
      <TourData>{point.lng.toFixed(5)}</TourData>
      <TourData>{point.lat.toFixed(5)}</TourData>
      <TourData width="5px">
        <Styleless as="button" disabled={up} onClick={onMoveUp}>
          <UpSquareOutlined style={IconStyle} />
        </Styleless>
        <Styleless as="button" disabled={down} onClick={onMoveDown}>
          <DownSquareOutlined style={IconStyle} />
        </Styleless>
        <Styleless as="button" onClick={onRemove}>
          <CloseSquareOutlined style={{ padding: "0 10px" }} />
        </Styleless>
      </TourData>
    </TourRow>
  );
};
const nameFormat = (point) => {
  return point.type === "burial"
    ? `${point.forename} ${point.surname}`
    : `${point.name}`;
};
