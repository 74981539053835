import styled, { css } from "styled-components";
import { Button } from "antd";

const buttonStyle = css`
  font-weight: 600;
  text-transform: uppercase;
  box-shadow: 0 3px 6px rgb(0, 0, 0, 0.16);
`;

export const PrimaryButton = styled(Button).attrs((props) => ({
  type: "primary",
}))`
  width: 160px;
  ${buttonStyle}
`;
