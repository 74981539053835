import Request from "../utility/Request.js";

class GetRecord extends Request {
  constructor() {
    super("get", ``);
  }
  sendRequest(cemetery, type, id) {
    this.url = `record/${cemetery}/${type}/${id}`;
    return this.sendRequestPromise({});
  }
}

export default GetRecord;
