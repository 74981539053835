import React, { createContext, useReducer } from "react";

import FOV from "../src/main.js";

const signIn = (dispatch) => (params) => {
  return new Promise((resolve, reject) => {
    FOV.api
      .authenticate(params.email, params.password)
      .then(() => {
        dispatch({ type: "SET_SIGNED_IN", payload: true });

        FOV.api
          .profile()
          .then((result) => {
            if (result.data.Item.Cemeteries) {
              dispatch({
                type: "SET_CEMETERIES",
                payload: result.data.Item.Cemeteries,
              });
              dispatch({ type: "SET_EMAIL", payload: result.data.Item.SK });
              resolve(result.data.Item.SK);
            }
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      })
      .catch((err) => {
        dispatch({ type: "SET_SIGNED_IN", payload: false });
        dispatch({ type: "SET_WAITING", payload: false });
        reject(err);
      });
  });
};
const refresh = (dispatch) => (params) => {
  FOV.api
    .refresh()
    .then(() => {
      FOV.api
        .profile()
        .then((result) => {
          if (result.data.Item.Cemeteries) {
            dispatch({
              type: "SET_CEMETERIES",
              payload: result.data.Item.Cemeteries,
            });
          }
          dispatch({ type: "SET_EMAIL", payload: result.data.Item.SK });
          dispatch({ type: "SET_WAITING", payload: false });
        })
        .catch(console.error);
    })
    .catch(() => {
      dispatch({ type: "SET_SIGNED_IN", payload: false });
      dispatch({ type: "SET_WAITING", payload: false });
    });
};
const logout = (dispatch) => (params) => {
  FOV.api
    .logout()
    .then(() => {
      dispatch({ type: "SET_SIGNED_IN", payload: false });
      dispatch({ type: "SET_WAITING", payload: false });
    })
    .catch(console.error);
};
const setSignedIn = (dispatch) => (state) => {
  dispatch({ type: "SET_SIGNED_IN", payload: state });
};
const setCemeteries = (dispatch) => (cemeteries) => {
  dispatch({ type: "SET_CEMETERIES", payload: cemeteries });
};
const getOverview = (dispatch) => (id) => {
  FOV.api
    .getCemetery(id)
    .then((result) => {
      dispatch({ type: "SET_OVERVIEW", payload: result.data.Item });
    })
    .catch(console.error);
};

const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_WAITING":
      return {
        ...state,
        waiting: action.payload,
      };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload,
      };
    case "SET_SIGNED_IN":
      return {
        ...state,
        signedIn: action.payload,
      };
    case "SET_CEMETERIES":
      return {
        ...state,
        cemeteries: action.payload,
      };
    case "SET_OVERVIEW":
      return {
        ...state,
        overview: action.payload,
      };
    default:
      return state;
  }
};

// Utility
const createAppContext = (reducer, actions, initialState) => {
  const Context = createContext();

  const Provider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const boundActions = {};

    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }
    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {props.children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
};

export const { Provider, Context } = createAppContext(
  Reducer,
  {
    signIn,
    setSignedIn,
    setCemeteries,
    getOverview,
    refresh,
    logout,
  },
  {
    signedIn: true,
    cemeteries: [],
    overview: {},
    email: undefined,
    waiting: true,
  }
);
