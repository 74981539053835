import React from "react";
import { MapContext } from "react-mapbox-gl";

const JumpTo = ({ location, zoom = 14 }) => {
  const map = React.useContext(MapContext);

  React.useEffect(() => {
    // Update coordinates using the map context api
    location && map.jumpTo({ center: location, zoom });
  }, [location, zoom]);

  return null;
};

export default JumpTo;
