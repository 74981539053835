import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";
import { Button, Row } from "antd";
import NewForm from "../../components/NewForm";
import FOV from "../../SDK/src/main";

import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout";
import ToursPreview from "../../components/catmaps/ToursPreview";

import { PageTitle } from "../../components/AngelStyledComponents/PageTitle";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent";

import Search from "../../components/Search";

import { TourList, TourItem } from "./TourList";
import { Context } from "../../SDK/context";
import AutoOverview from "../../SDK/components/AutoOverview";

import WarningLink from "../../components/WarningLink/warning-link";

const fieldMapping = [
  { name: "Id", label: "Id", type: "string", required: true, hidden: true },
  { name: "Name", label: "Name", type: "string", required: true },
  {
    name: "Logo",
    label: "Site Logo",
    type: "image",
  },
  {
    name: "Media",
    label: "Tour Gallery",
    type: "media",
  },
  { name: "Description", label: "Description", type: "string" },
  { name: "DateStart", label: "Date Start", type: "date" },
  { name: "DateEnd", label: "Date End", type: "date" },
  { name: "ExpectedDuration", label: "Expected Duration", type: "string" },
  { name: "Active", label: "Active", type: "boolean" },
  { name: "TourFile", label: "TourFile", type: "string", hidden: true },
];
const pictureFields = ["Logo"];

const poiFieldMapping = [
  // { name: "Id", label: "Id", type: "string", required: true },
  { name: "Name", label: "Name", type: "string", required: true },
  {
    name: "Photo",
    label: "Point of Interest Photo",
    type: "image",
  },
  {
    name: "Media",
    label: "Point of Interest Gallery",
    type: "media",
  },
  { name: "Description", label: "Message", type: "string" },
  { name: "Lat", label: "Lat", type: "coord", required: true },
  { name: "Lng", label: "Lng", type: "coord", required: true },
];
const poiPictureFields = ["Photo"];

const ToursEditor = () => {
  const unmounted = useRef(false);
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const { cemetery, id } = useParams();
  const { state } = React.useContext(Context);
  const [tour, setTour] = useState({});
  const poi = {};
  const [pathFile, setPathFile] = useState({
    map: [],
  });
  const [tourFile, setTourFile] = useState([]);
  const [points, setPoints] = useState([]);
  const [tourFileUrl, setTourFileUrl] = useState();
  const [jumpLocation, setJumpLocation] = useState([0, 51.5]);
  const [showAddPOI, setShowAddPOI] = useState(false);

  const [pathIsConnected, setPathIsConnected] = useState(false);

  const [randomQuery, setRandomQuery] = useState("?" + Math.random());



  const swapElements = (index) => {
    if (index >= 0 && index < points.length) {
      const newPoints = [...points];
      const elements = newPoints.splice(index, 2);

      newPoints.splice(index, 0, ...elements.reverse());
      setPoints(newPoints);
    }
  };

  const removeElement = (index) => {
    const newPoints = [...points];
    newPoints.splice(index, 1);
    setPoints(newPoints);
  };

  useEffect(() => {
    async function getJsonFile(fileUrl, type) {
      let obj;

      try {
        obj = await (await fetch(fileUrl + randomQuery)).json();
      } catch (err) {
        console.log(err);
        return;
      }

      if (type === "pathfile") {
        if (!unmounted.current) {
          setPathFile(convertPaths(obj.map));
        }
      } else {
        // console.log(obj.map((a) => ({ ...a, lat: a.lng, lng: a.lat })));
        // console.log(obj);
        if (!unmounted.current) {
          setTourFile(obj);
          // setTourFile(obj.map((a) => ({ ...a, lat: a.lng, lng: a.lat })));
        }

        const ids = obj.filter((node) => node.id).map((node) => node.id);
        const poiIds = ids
          .filter((id) => id.split("_")[0] === "poi")
          .map((id) => id.split("_")[1]);
        const monumentIds = ids
          .filter((id) => id.split("_")[0] === "monument")
          .map((id) => id.split("_")[1]);
        const burialIds = ids
          .filter((id) => id.split("_")[0] === "burial")
          .map((id) => id.split("_")[1]);

        let bulkPois = [];
        let bulkMonuments = [];
        let bulkBurials = [];

        let promises = [];
        if (poiIds.length) {
          promises.push(
            new Promise((resolve, reject) => {
              FOV.api
                .bulkGet(cemetery, "poi", poiIds)
                .then((result) => {
                  bulkPois =
                    result.data.Responses[
                    Object.keys(result.data.Responses)[0]
                    ];
                  resolve();
                })
                .catch(reject);
            })
          );
        }
        if (monumentIds.length) {
          promises.push(
            new Promise((resolve, reject) => {
              FOV.api
                .bulkGet(cemetery, "monument", monumentIds)
                .then((result) => {
                  bulkMonuments =
                    result.data.Responses[
                    Object.keys(result.data.Responses)[0]
                    ];
                  resolve();
                })
                .catch(reject);
            })
          );
        }
        if (burialIds.length) {
          promises.push(
            new Promise((resolve, reject) => {
              FOV.api
                .bulkGet(cemetery, "burial", burialIds)
                .then((result) => {
                  bulkBurials =
                    result.data.Responses[
                    Object.keys(result.data.Responses)[0]
                    ];
                  resolve();
                })
                .catch(reject);
            })
          );
        }

        Promise.all(promises)
          .then((values) => {
            let newPoints = [];
            for (let node of obj) {
              if (node.id) {
                let point = {};
                switch (node.id.split("_")[0]) {
                  case "burial":
                    point = bulkBurials.filter(
                      (burial) => burial.SK === node.id
                    )[0];

                    break;
                  case "monument":
                    point = bulkMonuments.filter(
                      (monument) => monument.SK === node.id
                    )[0];
                    break;
                  case "poi":
                    point = bulkPois.filter((poi) => poi.SK === node.id)[0];
                    break;
                  default:
                    break;
                }
                newPoints.push({
                  // lng: point.Lat,
                  lng: point.Lng,
                  // lat: point.Lng,
                  lat: point.Lat,
                  name: point.Name,
                  forename: point.Forename,
                  surname: point.Surname,
                  type: point.SK.split("_")[0],
                  id: point.SK,
                });
              }
            }
            if (!unmounted.current) {
              setPoints(newPoints);
            }
          })
          .catch(console.log);

        // setPoints(newPoints);
      }
    }
    if (id) {
      FOV.api
        .getRecord(cemetery, "tour", id)
        .then((result) => {
          result.data.Item.Id = result.data.Item.SK.split("_")[1];
          if (!unmounted.current) {
            setTour(result.data.Item);
          }

          if (result.data.Item.TourFile) {
            getJsonFile(
              result.data.Item.TourFile,
              "tourfile"
            );
          } else {
            // console.log(result.data.Item);
          }
        })
        .catch(console.error);
    } else {

    }
    FOV.api
      .getCemetery(cemetery)
      .then((result) => {
        if (!unmounted.current) {
          const newCemeteryItem = result.data.Item;

          if (newCemeteryItem.PathFile) {
            getJsonFile(newCemeteryItem.PathFile, "pathfile");
          }
        }
      })
      .catch(console.error);
    // }, [cemetery, id, randomQuery]);
  }, [cemetery]);

  useEffect(() => {
    if (points.length && pathFile.adjacencyList) {
      console.log(pathFile.adjacencyList)
      const pgg = new FOV.ProjectedGraphGenerator(
        pathFile.adjacencyList,
        points
      );
      const tour = pgg.calcTour();
      if (tour) {
        if (!unmounted.current) {

          setTourFile(tour);
        }
        setPathIsConnected(true);
      }
    } else if (pathFile.adjacencyList) {
      const al = pathFile.adjacencyList;
      const point = { lat: al[0].lat, lng: al[0].lng };
      const pgg = new FOV.ProjectedGraphGenerator(
        pathFile.adjacencyList,
        [point, point]
      );
      const tour = pgg.calcTour();
      if (tour) {
        setPathIsConnected(true);
      }
    }
  }, [points, pathFile]);

  function convertPaths(paths) {
    let newPaths = {
      adjacencyList: [],
    };
    let mapping = {};

    for (let i = 0; i < paths.length; ++i) {
      mapping[paths[i].key] = i;
    }

    for (let i = 0; i < paths.length; ++i) {
      let newNode = {
        index: i,
        lat: paths[i].value.coordinates[1],
        lng: paths[i].value.coordinates[0],
        neighbours: [],
      };
      for (let adjacent of paths[i].value.adjacent) {
        newNode.neighbours.push(mapping[adjacent]);
      }
      newPaths.adjacencyList.push(newNode);
    }
    return newPaths;
  }

  function addPoint(record) {
    if (Object.keys(record).length) {
      if (!unmounted.current) {
        setPoints([
          ...points,
          {
            lng: record.Lng,
            lat: record.Lat,
            name: record.Name,
            forename: record.Forename,
            surname: record.Surname,
            type: record.type,
            id: record.SK,
          },
        ]);
        console.log({
          lng: record.Lng,
          lat: record.Lat,
          name: record.Name,
          forename: record.Forename,
          surname: record.Surname,
          type: record.type,
          id: record.SK,
        })
      }
    }
  }

  function saveTour() {
    return new Promise((resolve, reject) => {
      if (tourFile) {
        FOV.api
          .getMediaUrl(
            cemetery,
            "tour",
            id,
            "tourfile",
            "thisdoesntmatter.json"
          )
          .then((mediaUrlResult) => {
            // console.log(mediaUrlResult);
            const uploadUrl = mediaUrlResult.data.url;
            FOV.api
              .uploadMedia(
                new Blob([JSON.stringify(tourFile)], { type: "text/json" }),
                "tourfile",
                uploadUrl
              )
              .then(() => {
                if (!unmounted.current) {
                  setTourFileUrl(uploadUrl.split("?")[0]);
                }
                resolve({ TourFile: uploadUrl.split("?")[0] });
              })
              .catch(reject);
          })
          .catch(reject);
      } else {
        console.log("no tourfile", tourFile);
        resolve();
      }
    });
  }

  function toggleShowAddPOI() {
    setShowAddPOI(!showAddPOI);
  }

  return (
    <StyledLayout>
      <FixedContent>
        <PageTitle>Tours Editor</PageTitle>
        {pathFile ? (
          pathIsConnected ? (
            <>
              <AutoOverview id={cemetery} />
              {state.overview.PathFile ? (
                <>
                  <NewForm
                    originalItem={tour}
                    fieldMapping={fieldMapping}
                    pictureFields={pictureFields}
                    type="tour"
                    // forcedFields={{ TourFile: tourFileUrl }}
                    beforeSubmit={() => {
                      return saveTour();
                    }}
                  />
                  <ToursPreview
                    // tour={points}
                    tour={tourFile}
                    containerStyle={{
                      width: "100%",
                      maxWidth: "720px",
                      height: "720px",
                    }}
                    location={[
                      (state.overview.MapALng + state.overview.MapCLng) / 2,
                      (state.overview.MapALat + state.overview.MapCLat) / 2,
                    ]}
                    imageCoord={[
                      [state.overview.MapALng, state.overview.MapALat],
                      [state.overview.MapBLng, state.overview.MapBLat],
                      [state.overview.MapCLng, state.overview.MapCLat],
                      [state.overview.MapDLng, state.overview.MapDLat],
                    ]}
                    imageUrl={state.overview.Map + "?" + Math.random()}
                    imageOpacity={0.5}
                  />
                  <div style={{ width: "20rem", margin: "1rem" }}>
                    <h2 style={{ margin: 0 }}>SEARCH FOR BURIAL</h2>
                    <h4 style={{ color: "white" }}>
                      (Search by Forename, Surname).
                    </h4>
                    <Search
                      type="burial"
                      queryBy="forename,surname"
                      setResult={(result) => {
                        result.type = "burial";

                        addPoint(result);
                      }}
                    />
                  </div>
                  <div style={{ width: "20rem", margin: "1rem" }}>
                    <h2 style={{ margin: 0 }}>SEARCH FOR RECORD</h2>
                    <h4 style={{ color: "white" }}>
                      (Search by Monument Name).
                    </h4>
                    <Search
                      type="monument"
                      queryBy="name"
                      setResult={(result) => {
                        result.type = "monument";
                        addPoint(result);
                      }}
                    />
                  </div>
                  <div style={{ width: "20rem", margin: "1rem" }}>
                    <h2 style={{ margin: 0 }}>SEARCH FOR Point of Interest</h2>
                    <h4 style={{ color: "white" }}>
                      (Search by point of interest name).
                    </h4>
                    <Search
                      type="poi"
                      queryBy="name"
                      setResult={(result) => {
                        result.type = "poi";
                        addPoint(result);
                      }}
                    />
                  </div>
                  <TourList>
                    <tbody>
                      {points.map((value, index) => (
                        <TourItem
                          key={value.id}
                          point={value}
                          index={index + 1}
                          disabled={[index === 0, index === points.length - 1]}
                          onNameClick={(location) => setJumpLocation(location)}
                          onMoveUp={() => swapElements(index - 1)}
                          onMoveDown={() => swapElements(index)}
                          onRemove={() => removeElement(index)}
                        />
                      ))}
                    </tbody>
                  </TourList>
                  <Row
                    style={{ width: "720px" }}
                    justify="space-around"
                    align="middle"
                  >
                    <h2 style={{ color: "white" }}>
                      Manually enter a Point of Interest
                    </h2>
                    <Button onClick={toggleShowAddPOI}>
                      {showAddPOI ? "HIDE" : "ADD"}
                    </Button>
                  </Row>
                  {showAddPOI && (
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "rgba(244,239,220,1)",
                      }}
                    >
                      <Row
                        justify="center"
                        align="middle"
                        style={{
                          width: "720px",
                          margin: "25px auto",
                        }}
                      >
                        <h2 style={{ margin: 0, color: "black" }}>
                          Add a Point of Interest
                        </h2>
                        <NewForm
                          originalItem={poi}
                          fieldMapping={poiFieldMapping}
                          pictureFields={poiPictureFields}
                          type="poi"
                          afterSubmit={(poi) => {
                            console.log(poi);
                            addPoint({ type: "poi", ...poi });
                          }}
                        />
                      </Row>
                    </div>
                  )}
                </>
              ) : (
                <WarningLink
                  message={
                    "Before you can edit a tour, you must generate a path file"
                  }
                  pathName={"Path Editor"}
                  path={`/${state.email}/${cemetery}/pathing`}
                />
              )}
            </>
          ) : (
            <WarningLink
              message={"Your path is not connected"}
              pathName={"Path Editor"}
              path={`/${state.email}/${cemetery}/pathing`}
            />
          )
        ) : null}
      </FixedContent>
    </StyledLayout>
  );
};

export default ToursEditor;
