import styled from "styled-components";

import { DatePicker } from "antd";

export const StyledDatePicker = styled(DatePicker)`
  .ant-picker-input > input::placeholder {
    color: #fff9;
  }

  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
  max-width: 510px;
  box-shadow: inset -15px 0 12px rgba(0, 0, 0, 0.2);
  .ant-picker-input > input {
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
  }
  .ant-picker-clear {
    background-color: #384b5d;
    color: #fff;
  }
  .anticon-calendar {
    color: #fff;
  }
`;
