import Controller from "../Controller.js";
import Request from "../utility/Request.js";

class Logout extends Request {
  constructor() {
    super("get", `logout`);
  }
  resolveRequest(result, resolve) {
    Controller.idToken = null;
    resolve(result);
  }
}

export default Logout;
