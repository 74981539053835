import styled, { css } from "styled-components";
import { Button } from "antd";

const buttonStyle = css`
  font-weight: 600;
  text-transform: uppercase;
  box-shadow: 0 3px 6px rgb(0, 0, 0, 0.16);
`;

export const TertiaryButton = styled(Button).attrs((props) => ({
  type: "tertiary",
}))`
  background-color: #6c7f91;
  border-color: #6c7f91;
  color: #fff;
  width: 160px;

  ${buttonStyle}

  &:hover,
    &:focus {
    background-color: #758a9e;
    color: #ede6db;
    border-color: #ede6db;
  }
`;
