import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import ViewAll from "../../components/ViewAll";
import { Context } from "../../SDK/context";
import { useParams, useHistory } from "react-router-dom";

import { PageTitle } from "../../components/AngelStyledComponents/PageTitle.js";
import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout.js";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent.js";

import { FormToggleButton } from "../../components/AngelStyledComponents/FormToggleButton.js";
import { StyledDiv } from "../../components/AngelStyledComponents/StyledDiv.js";

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ViewAllRecords = () => {
  const history = useHistory();
  const { cemetery, recordType } = useParams();
  const [type, setType] = useState(recordType || "burial");
  const { state } = useContext(Context);
  useEffect(() => {
    if (recordType) {
      setType(recordType === "burial" ? "burial" : "monument");
    }
  }, []);

  return (
    <StyledLayout>
      <FixedContent style={{ width: "100%" }}>
        <HeaderDiv>
          <PageTitle fullSize={true}>View All Records</PageTitle>
          <StyledDiv
            fullSize={true}
            style={{
              height: "4rem",
              gridTemplateColumns: "1fr 1fr",
              justifyItems: "stretch",
              alignContent: "stretch",
              alignItems: "stretch",
              padding: "0",
            }}
          >
            <FormToggleButton
              className={type === "burial" && "inset-shadow"}
              onClick={() => {
                setType("burial");
                history.push(
                  `/${state.email}/${cemetery}/view-all/${"burial"}`
                );
              }}
            >
              All Burials
            </FormToggleButton>
            <FormToggleButton
              className={type === "monument" && "inset-shadow"}
              onClick={() => {
                setType("monument");
                history.push(
                  `/${state.email}/${cemetery}/view-all/${"monument"}`
                );
              }}
            >
              All Records
            </FormToggleButton>
          </StyledDiv>
        </HeaderDiv>

        <ViewAll recordType={type} />
      </FixedContent>
    </StyledLayout>
  );
};

export default ViewAllRecords;
