import React, { useState, useEffect } from "react";

import { InputLabel } from "../../components/AngelStyledComponents/InputLabel.js";
import { CheckboxLabel } from "../../components/AngelStyledComponents/CheckboxLabel.js";
import { EditHeader } from "../../components/AngelStyledComponents/EditHeader.js";
import { MultipleInputs } from "../../components/AngelStyledComponents/MultipleInputs.js";
import { DateLabel } from "../../components/AngelStyledComponents/DateLabel.js";

import { SiteActive } from "../../components/AngelStyledComponents/SiteActive.js";

import { Gallery } from "../../components/AngelStyledComponents/Gallery.js";
import Thumbnail from "../../components/Thumbnail";
import { Form } from "antd";

const InputField = ({
  field,
  form,
  loaded,
  hidden,
  mediaUploads,
  randomQuery,
  setRandomQuery,
  setMediaUploads,
  addMediaUpload,
  type,
}) => {
  const [checked, setChecked] = useState(form.getFieldsValue()[field.name]);

  useEffect(() => {
    if (loaded) {
      setChecked(form.getFieldsValue()[field.name]);
    }
  }, [loaded, form, setChecked, field]);

  function renderInput() {
    const ruleMap = {
      float: {
        pattern: new RegExp(/^[+-]?\d+(\.\d+)?$/),
        message: "The input is not a floating point number",
      },
      coord: {
        pattern: new RegExp(/^[+-]?\d+(\.\d+)?$/),
        message: "The input is not a floating point number",
      },
      int: {
        pattern: new RegExp(/^\d+$/),
        message: "The input is not an integer",
      },
      string: {},
    };

    const rules = [ruleMap[field.type], { required: field.required }];

    switch (field.type) {
      case "string":
      case "float":
      case "int":
      case "coord":
        return field.hidden ? (
          <div style={{ display: "none" }}>
            <InputLabel
              label={field.label}
              required={field.required}
              formProps={{
                name: field.name,
                rules,
              }}
            />
          </div>
        ) : (
          <InputLabel
            label={field.label}
            required={field.required}
            formProps={{
              name: field.name,
              rules,
            }}
          />
        );
      case "boolean":
        if (field.name !== "Active") {
          return (
            <CheckboxLabel
              label={field.label}
              formProps={{
                name: field.name,
              }}
              value={checked}
              onChange={(e) => {
                setChecked(e.target.checked);

                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  [field.name]: e.target.checked,
                });
              }}
            />
          );
        } else {
          return (
            <SiteActive
              label={field.label}
              formProps={{
                name: field.name,
              }}
              value={checked}
              onChange={(e) => {
                setChecked(e.target.checked);

                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  [field.name]: e.target.checked,
                });
              }}
            />
          );
        }
      case "multiple-inputs":
        return (
          <EditHeader label={field.label}>
            <MultipleInputs
              fields={field.inputs.map((input) => {
                return {
                  label: input.label,
                  formProps: {
                    name: input.name,
                    rules,
                  },
                };
              })}
            />
          </EditHeader>
        );
      case "date":
        return (
          <>
            <Form.Item
              key={Math.random()}
              style={{ display: "none" }}
              name={field.name}
            ></Form.Item>
            <DateLabel
              loaded={loaded}
              name={field.name}
              form={form}
              label={field.label}
              formProps={{ name: field.name }}
              onChange={(date, dateString) => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  [field.name]: dateString,
                });
              }}
            />
          </>
        );
      case "image":
        return (
          <>
            <Form.Item
              key={Math.random()}
              style={{ display: "none" }}
              name={field.name}
            ></Form.Item>
            <Thumbnail
              image={form.getFieldsValue()[field]}
              form={form}
              field={field}
              mediaUploads={mediaUploads}
              randomQuery={randomQuery}
              setRandomQuery={setRandomQuery}
              setMediaUploads={setMediaUploads}
              addMediaUpload={addMediaUpload}
              isMedia={false}
              type={type}
              loaded={loaded}
            />
          </>
        );
      case "media":
        return (
          <>
            <Form.Item
              key={Math.random()}
              style={{ display: "none" }}
              name={field.name}
            ></Form.Item>
            <EditHeader label={field.label}>
              <Gallery
                form={form}
                mediaUploads={mediaUploads}
                randomQuery={randomQuery}
                setMediaUploads={setMediaUploads}
                addMediaUpload={addMediaUpload}
                type={type}
                loaded={loaded}
              />
            </EditHeader>
          </>
        );
      default:
        return null;
    }
  }
  return renderInput();
};

export default InputField;
