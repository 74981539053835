import { useEffect, useContext, useRef } from "react";

import { Context } from "../../context";

const AutoSignin = () => {
  const context = useRef(useContext(Context));
  useEffect(() => {
    context.current.refresh();
  }, []);

  return null;
};

export default AutoSignin;
