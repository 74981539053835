import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #3f5569;
    font-family: "Libre Franklin";
  }
  h2 {
    color: #fff;
    margin: 20px 0;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
  }
  .ant-picker-panel-container {
  }
  .ant-form-item {
    margin: 0;
  }
  
`;
