import Request from "../utility/Request.js";

class AssignOrganizations extends Request {
  constructor() {
    super("put", ``);
  }
  sendRequest() {
    const args = arguments[0];
    const cemetery = args[0];
    const organizations = args[1];

    this.url = `assign-organizations/${cemetery}`;
    return this.sendRequestPromise({ organizations });
  }
}

export default AssignOrganizations;
