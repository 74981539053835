import React from "react";
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import JumpTo from "./JumpTo";
import { ImageLayer } from "./MapWithImage";

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
  logoPosition: "bottom-right",
});

const ToursPreview = (props) => {
  const {
    containerStyle,
    tour,
    location,
    imageCoord,
    imageUrl,
    imageOpacity,
  } = props;
  const zoom = React.useRef();

  return (
    <Mapbox
      //eslint-disable-next-line
      style="mapbox://styles/mapbox/dark-v10"
      containerStyle={containerStyle}
      onZoomEnd={(val) => (zoom.current = val.getZoom())}
    >
      <ToursMap tour={tour} />
      <JumpTo zoom={zoom.current} location={location} />

      <ImageLayer
        coordinates={imageCoord}
        url={imageUrl}
        //url={url}
        opacity={imageOpacity}
      />
    </Mapbox>
  );
};

const circleStyles = {
  "circle-color": "#FFFFFF",
  "circle-radius": ["interpolate", ["linear"], ["zoom"], 14, 2, 18, 8],
  "circle-stroke-width": ["interpolate", ["linear"], ["zoom"], 14, 1, 18, 3],
  "circle-stroke-color": [
    "case",
    ["boolean", ["get", "selected"], true],
    "#FF7F1D",
    "#FFFFFF",
  ],
};

const textStyles = {
  "text-color": "#FFFFFF",
};
const textLayout = {
  "text-anchor": "bottom",
  "text-offset": [0, -1],
  "text-field": ["get", "label"],
};

const lineLayout = {
  "line-cap": "round",
  "line-join": "round",
};

const linePaint = {
  "line-color": "#FF7F1D",
  "line-width": 3,
};

export const parseMapObj = (source) => {
  return new Map(source.map((entry) => [entry.key, entry.value]));
};

export const stringifyMapObj = (map) => {
  return JSON.stringify(mapToArrayObj(map));
};
export const mapToArrayObj = (map) => {
  return [...map.entries()].map((entry) => ({
    key: entry[0],
    value: entry[1],
  }));
};

export const parseMap = (source) => {
  return new Map(JSON.parse(source));
};

export const stringifyMap = (map) => {
  return JSON.stringify([...map.entries()]);
};

const ToursMap = ({ tour, selectedNode }) => {
  // Recalculate only when graph or lastNode changes
  const renderNodes = React.useCallback(() => {
    return tour.map((point, index) => (
      <Feature
        coordinates={[point.lng, point.lat]}
        // coordinates={[point.lat, point.lng]}
        key={point.id}
        properties={{ selected: point.id === selectedNode }}
      />
    ));
  }, [tour, selectedNode]);

  const renderText = React.useCallback(() => {
    return tour.map((point, index) => (
      <Feature
        coordinates={[point.lng, point.lat]}
        // coordinates={[point.lat, point.lng]}
        key={point.id}
        properties={{ label: index + 1 }}
      />
    ));
  }, [tour]);

  // useCallback ensure this is cached so that it doesn't render
  // unless graph or selected node/line has changed
  const renderLines = React.useCallback(() => {
    let lines = tour
      // Generate line coordinates and
      .map(
        (point, index) =>
          index !== 0 && [
            [tour[index - 1].lng, tour[index - 1].lat],
            // [tour[index - 1].lat, tour[index - 1].lng],
            [point.lng, point.lat],
            // [point.lat, point.lng],
          ]
      );

    return (
      <>
        <Layer id="lines" type="line" layout={lineLayout} paint={linePaint}>
          {lines.map((line) => (
            <Feature key={line.toString()} coordinates={line} />
          ))}
        </Layer>
      </>
    );
  }, [tour]);

  return (
    <>
      {renderLines()}
      <Layer id="nodes" type="circle" paint={circleStyles}>
        {renderNodes()}
      </Layer>
      <Layer id="text" type="symbol" paint={textStyles} layout={textLayout}>
        {renderText()}
      </Layer>
    </>
  );
};

export default ToursPreview;
