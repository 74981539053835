import Refresher from "./Refresher.js";

function wrap(Cls) {
  let clsInstance = new Cls();
  return clsInstance.sendRequest.bind(clsInstance);
}
function wrapRefresh(Cls) {
  let clsInstance = new Cls();
  let clsInstanceRefresher = new Refresher(clsInstance.sendRequest.bind(clsInstance));
  return clsInstanceRefresher.handleRefresh.bind(clsInstanceRefresher);
}

export { wrap, wrapRefresh };
