import styled from "styled-components";

export const StyledDiv = styled.div.attrs((props) => ({
  shadow: props.shadow === undefined || props.shadow,
  fullSize: props.fullSize,
  noMargin: props.noMargin
}))`
  z-index: 1;
  background-color: #475f75;
  color: #fff;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 3fr;
  justify-items: start;
  align-items: center;
  align-content: center;

  max-width: ${(props) => (props.fullSize ? "100%" : "720px")};
  width: 100%;
  height: 96px;
  margin: ${(props) => (props.noMargin ? "0" : "auto")};
  padding: 0 20px;

  font-weight: bold;
  text-transform: uppercase;
  text-align: center;

  box-shadow: 0 3px 6px rgb(0, 0, 0, 0.16);

  & + & {
    box-shadow: ${(props) =>
        props.shadow === true && "inset 0 15px 10px -10px rgb(0, 0, 0, 0.16),"}
      0 3px 6px rgb(0, 0, 0, 0.16);
  }
`;
