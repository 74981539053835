import Request from "../utility/Request.js";

class GetMediaUrl extends Request {
  constructor() {
    super("get", ``);
  }
  sendRequest() {
    const args = arguments[0];
    const cemetery = args[0];
    const type = args[1];
    const id = args[2];
    const mediaType = args[3];
    let name = args[4];

    name = name.split("/").pop();

    this.url = `get-presigned-url/${cemetery}/${type}/${id}/${mediaType}/${name}`;

    return this.sendRequestPromise({});
  }
  resolveRequest(result, resolve) {
    result.data = { url: result.data.url };
    resolve(result, resolve);
  }
}

export default GetMediaUrl;
