import React from "react";
import { useHistory } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import { TertiaryButton } from "../../components/AngelStyledComponents/TertiaryButton";
const WarningLink = ({ message, pathName, path }) => {
  const history = useHistory();
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "720px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#3F5569",
        padding: "10px",
        boxShadow: "inset 0 0 10px rgba(0,0,0,0.25)",
      }}
    >
      <WarningOutlined style={{ fontSize: "40px", color: "white" }} />

      <h2 style={{ margin: 0 }}>{message}</h2>
      <TertiaryButton
        style={{ margin: "10px" }}
        onClick={() => {
          history.push(path);
        }}
      >
        {pathName}
      </TertiaryButton>
    </div>
  );
};

export default WarningLink;
