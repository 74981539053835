import Controller from '../Controller.js'

import Refresh from '../routes/Refresh.js'
let refresh = new Refresh()

class Refresher {
  constructor(request) {
    this.request = request
  }

  handleRefresh() {
    const refreshAndRequest = (resolve, reject) => {
      refresh
        .sendRequest()
        .then((result) => {
          Controller.idToken = result.data.AuthenticationResult.IdToken

          this.request(arguments).then(resolve).catch(reject)
        })
        .catch((err) => {
          console.log(err)
          //reject({ invalidRefresh: true });
          reject(err)
        })
    }
    return new Promise((resolve, reject) => {
      if (Controller.idToken !== null) {
        this.request(arguments)
          .then((result) => {
            resolve(result)
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              refreshAndRequest(resolve, reject)
            } else {
              console.log(err)
              reject(err)
            }
          })
      } else {
        refreshAndRequest(resolve, reject)
      }
    })
  }
}

export default Refresher
