import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FOV from "../../SDK/src/main.js";
import { Context } from "../../SDK/context";
import placeholderMonumentTour from "../../assets/images/placeholder-monument-tour.png";
import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout";
import { PageTitle } from "../../components/AngelStyledComponents/PageTitle";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent";
import { TourButton } from "../../components/AngelStyledComponents/TourButton.js";
import { CenteredDiv } from "../../components/AngelStyledComponents/CenteredDiv";
import { TertiaryButton } from "../../components/AngelStyledComponents/TertiaryButton";
import { useParams } from "react-router-dom";

function OrganizationsList({
  assignedOrganizations,
  organizations,
  editOrganization,
  assignOrganization,
  email,
}) {
  return (
    <>
      {organizations.map((o) => {
        return (
          <>
            <TourButton
              assignable={!assignedOrganizations.includes(o.SK)}
              editable={o.Owner.toLowerCase() === email?.toLowerCase()}
              src={o.Logo || placeholderMonumentTour}
              key={o.SK}
              title={o.Name}
              onClick={() => editOrganization(o.SK)}
              onAssign={() => assignOrganization(o.SK)}
            />
          </>
        );
      })}
    </>
  );
}

function OrganizationsView() {
  const { cemetery } = useParams();
  const { state } = useContext(Context);
  const history = useHistory();
  const [orgs, setOrgs] = useState([]);
  const [assignedOrganizations, setAssignedOrganizations] = useState([]);

  useEffect(() => {
    const fetchOrgs = async () => {
      const result = await FOV.api.getOrganizations();
      setOrgs(result.data?.Items);
    };
    fetchOrgs();
    const getCemetery = async () => {
      const result = await FOV.api.getCemetery(cemetery);
      setAssignedOrganizations(result.data.Item?.Organizations || []);
    
    };
    getCemetery();
  }, []);

  const editOrganization = (id) => {
    history.push(`/${state.email}/organization/${id}`);
  };

  const assignOrganization = (id) => {
    FOV.api.assignOrganizations(cemetery, [id]);
  };

  return (
    <StyledLayout>
      <FixedContent>
        <PageTitle>View All Organizations</PageTitle>
        <OrganizationsList
          assignedOrganizations={assignedOrganizations}
          organizations={orgs}
          editOrganization={editOrganization}
          assignOrganization={assignOrganization}
          email={state.email}
        />
        <CenteredDiv>
          <TertiaryButton style={{ width: "20em" }}>
            <Link to={`/${state.email}/organization/`}>
              Add New Organization
            </Link>
          </TertiaryButton>
        </CenteredDiv>
      </FixedContent>
    </StyledLayout>
  );
}

export default OrganizationsView;
