import Request from "../utility/Request.js";

class PostOrganization extends Request {
  constructor() {
    super("post", ``);
  }
  sendRequest() {
    const args = arguments[0];
    const Item = args[0];

    this.url = `organization`;
    return this.sendRequestPromise(Item);
  }
}

export default PostOrganization;
