import styled from "styled-components";

export const LightDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  flex: 1;
  background-color: #f3efda;
  z-index: 1;

  max-width: 720px;
  width: 100%;
  margin: auto;

  font-weight: bold;
  text-transform: uppercase;
  text-align: center;

  & + & {
    padding-top: 21px;
    box-shadow: inset 0 11px 0px 0px rgb(0, 0, 0, 0.16);
  }
`;
