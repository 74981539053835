import React from "react";
import { StyledDiv } from "./StyledDiv.js";
import { TertiaryButton } from "./TertiaryButton.js";

export const TourButton = (props) => {
  const { src, title, onClick, onAssign, editable, assignable } = props;
  return (
    <>
      <StyledDiv
        style={{
          gridTemplateColumns: "9fr 1fr",
          padding: "0 22px !important",
        }}
      >
        <button
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: "#0000",
            border: "none",
          }}
          type="button"
        >
          <img
            src={src}
            style={{
              height: "64px",
              width: "64px",
            }}
            alt="placholder"
          />
          <h2
            style={{
              textTransform: "uppercase",
              color: "#fff",
              margin: "auto 20px ",
            }}
          >
            {title}
          </h2>
        </button>
        {assignable && (
          <TertiaryButton
            onClick={onAssign}
            style={{
              width: "auto",
              marginRight: "20px",
              justifySelf: "end",
            }}
          >
            Assign
          </TertiaryButton>
        )}

        {(editable || typeof editable === "undefined") && (
          <TertiaryButton
            onClick={onClick}
            style={{
              width: "63px",
              justifySelf: "end",
            }}
          >
            Edit
          </TertiaryButton>
        )}
      </StyledDiv>
    </>
  );
};
