import { useParams, useHistory } from "react-router-dom";

import { FormToggleButton } from "../../components/AngelStyledComponents/FormToggleButton.js";
import { StyledDiv } from "../../components/AngelStyledComponents/StyledDiv.js";
import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout.js";

import { PageTitle } from "../../components/AngelStyledComponents/PageTitle.js";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent.js";

import { Context } from "../../SDK/context";

import FOV from "../../SDK/src/main";

import React, { useContext, useEffect, useState } from "react";

import NewForm from "../../components/NewForm/index.js";

const burialFieldMapping = [
  { name: "Active", label: "Active", type: "boolean", default: true }, //
  { name: "Id", label: "Id", type: "string", required: true }, //
  {
    name: "BurialTypeVeteran", //
    label: "Burial Type (Veteran)", //
    type: "boolean", //
  },
  { name: "Notable", label: "Notable Person", type: "boolean", default: false }, //
  { name: "Forename", label: "Forename", type: "string", required: true }, //
  { name: "MiddleNames", label: "Middle Names", type: "string" }, //
  { name: "Surname", label: "Surname", type: "string", required: true }, //
  { name: "Initials", label: "Initials", type: "string" }, //
  { name: "Aliases", label: "Aliases", type: "string" }, //
  { name: "Nationality", label: "Nationality", type: "string" }, //
  { name: "Regiment", label: "Regiment", type: "string" }, //
  { name: "SecondaryRegiment", label: "2nd Regiment", type: "string" }, //
  { name: "Country", label: "Country", type: "string" }, //
  { name: "EducationalHonours", label: "Education Honours", type: "string" }, //
  {
    name: "CountryCommemorations", //
    label: "Country Commemorations", //
    type: "string", //
  },
  {
    name: "MonumentCommemorations", //
    label: "Monument Commemorations", //
    type: "string", //
  },
  { name: "CountryOfService", label: "Country of Service", type: "string" }, //
  { name: "ServiceNumber", label: "Service Number", type: "string" }, //
  { name: "Rank", label: "Rank", type: "string" }, //
  { name: "Unit", label: "Unit", type: "string" }, //
  {
    name: "ForceServiceBranch", //
    label: "Force / Service / Branch", //
    type: "string", //
  },
  { name: "Trade", label: "Trade", type: "string" }, //
  { name: "DoB", label: "Date of Birth", type: "date" }, //
  { name: "DoD", label: "Date of Death", type: "date" }, //
  { name: "DoD2", label: "Date of Death 2", type: "date" }, //
  { name: "AgeAtDeath", label: "Age at Death", type: "int" }, //
  { name: "HonoursAwards", label: "Honours / Awards", type: "string" }, //
  { name: "Description", label: "Description", type: "string" }, //
  { name: "Conflicts", label: "Conflict(s)", type: "string" }, //
  { name: "FamilyInfo", label: "Family info", type: "string" }, //
  { name: "GraveRefLot", label: "Grave Ref Lot", type: "string" }, //
  { name: "GraveRefBlock", label: "Grave Ref Block", type: "string" }, //
  { name: "GraveRefSection", label: "Grave Ref Section", type: "string" }, // 
  { name: "PlotOrMonument", label: "Plot or Monument", type: "string" }, //
  { name: "Status", label: "Status", type: "string" }, //
  { name: "DoI", label: "DoI", type: "date" }, //
  { name: "EstateDetails", label: "Estate Details", type: "string" }, //
  { name: "Weblinks", label: "Weblinks", type: "string" }, //
  { name: "Aux", label: "Aux", type: "string" }, //
  {
    name: "Photo", //
    label: "Photo of Burial", //
    type: "image", //
  },
  {
    name: "Media", //
    label: "Gallery", //
    type: "media", //
  },
  { name: "Lat", label: "Latitude", type: "coord", required: true }, //
  { name: "Lng", label: "Longitude", type: "coord", required: true }, //
  // {
  //   name: "Name",
  //   label: "Name",
  //   type: "multiple-inputs",
  //   inputs: [
  //     { name: "Forename", label: "Forename", type: "string", required: true },
  //     { name: "MiddleNames", label: "MiddleNames", type: "string" },
  //     { name: "Surname", label: "Surname", type: "string", required: true },
  //   ],
  // },
];
const monumentFieldMapping = [
  { name: "Active", label: "Active", type: "boolean", default: true }, //
  { name: "Id", label: "Monument Id", type: "string", required: true }, //
  { name: "Name", label: "Monument Name", type: "string", required: true }, //
  { name: "Country", label: "Country", type: "string" }, //
  { name: "Locality", label: "Locality", type: "string" }, //
  {
    name: "MonumentRefLocation", //
    label: "Monument Ref Location", //
    type: "string", //
  },
  { name: "StateProvince", label: "State / Province", type: "string" }, //
  { name: "Accessibility", label: "Accessibility", type: "string" }, //
  { name: "Owner", label: "Monument Owner", type: "string" }, //
  { name: "DedicatedBy", label: "Dedicated By", type: "string" }, //
  { name: "DedicatedDate", label: "Dedicated Date", type: "date" }, //
  { name: "Conflicts", label: "Monument Conflicts", type: "string" }, //
  { name: "Casualties", label: "Casualties", type: "int" }, //
  { name: "Artist", label: "Artist / Sculptor", type: "string" }, //
  { name: "Architect", label: "Architect", type: "string" }, //
  { name: "Description", label: "Monument Description", type: "string" }, //
  { name: "Weblinks", label: "Weblinks", type: "string" }, //
  { name: "Labels", label: "Labels", type: "string" }, //
  { name: "Aux", label: "Aux", type: "string" }, //
  {
    name: "Photo", //
    label: "Monument Photo", //
    type: "image", //
  },
  {
    name: "Media", //
    label: "Gallery", //
    type: "media", //
  },
  // {
  //   name: "Location",
  //   label: "Location",
  //   type: "multiple-inputs",
  //   inputs: [
  //     { name: "Country", label: "Country", type: "string" },
  //     { name: "City", label: "City", type: "string" },
  //     { name: "StateProvince", label: "State / Province", type: "string" },
  //   ],
  // },
  { name: "Lat", label: "Lat", type: "coord", required: true }, //
  { name: "Lng", label: "Lng", type: "coord", required: true }, //
];
const pictureFields = ["Photo"];

const NewRecord = (props) => {
  const { state } = useContext(Context);
  const history = useHistory();
  const { cemetery, recordType, id } = useParams();
  const [record, setRecord] = useState({});

  useEffect(() => {
    if (recordType && id) {
      if (recordType.toLowerCase() === "monument") {
        setNewRecord(true);
      }
      FOV.api
        .getRecord(cemetery, recordType, id)
        .then((result) => {
          result.data.Item.Id = result.data.Item.SK.split("_")[1];
          setRecord(result.data.Item);
        })
        .catch(console.error);
    }
  }, [cemetery, id, recordType]);

  const [newRecord, setNewRecord] = React.useState(false);

  return state.cemeteries.includes(cemetery) ? (
    <StyledLayout>
      <FixedContent>
        <PageTitle>Edit Record</PageTitle>
        <StyledDiv
          style={{
            gridTemplateColumns: "1fr 1fr",
            justifyItems: "stretch",
            alignContent: "stretch",
            alignItems: "stretch",
            padding: "0",
          }}
        >
          <FormToggleButton
            className={newRecord && "inset-shadow"}
            onClick={() => {
              setNewRecord(false);
              history.push(`/${state.email}/${cemetery}/record/${"burial"}`);
            }}
          >
            New Burial
          </FormToggleButton>
          <FormToggleButton
            className={!newRecord && "inset-shadow"}
            onClick={() => {
              setNewRecord(true);
              history.push(`/${state.email}/${cemetery}/record/${"monument"}`);
            }}
          >
            New Record
          </FormToggleButton>
        </StyledDiv>
        <NewForm
          originalItem={record}
          fieldMapping={!newRecord ? burialFieldMapping : monumentFieldMapping}
          pictureFields={pictureFields}
          type={!newRecord ? "burial" : "monument"}
        />
      </FixedContent>
    </StyledLayout>
  ) : null;
};

export default NewRecord;
