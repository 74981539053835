import { useEffect, useContext } from "react";

import { Context } from "../../context";

const AutoOverview = ({ id }) => {
  const { getOverview, state } = useContext(Context);

  useEffect(() => {
    if (state.cemeteries.includes(id)) {
      if (!Object.keys(state.overview).length || state.overview.SK !== id) {
        getOverview(id);
      }
    }
  }, [id, state.cemeteries, state.overview, getOverview]);

  return null;
};

export default AutoOverview;
