import React, { useState } from "react";

import { CenteredDiv } from "./CenteredDiv.js";
import { TertiaryButton } from "./TertiaryButton.js";
import { UploadOutlined } from "@ant-design/icons";
import FOV from "../../SDK/src/main";
import {
  Progress,
  Table,
  Button,
} from "antd";
import "./BulkUploadButton.css";

// const dataSource = [
//   {
//     key: "1",
//     name: "Mike",
//     age: 32,
//     address: "10 Downing Street",
//   },
//   {
//     key: "2",
//     name: "John",
//     age: 42,
//     address: "10 Downing Street",
//   },
// ];

export const BulkUploadButton = (props) => {
  const [open, setOpen] = React.useState(false);
  const { buttonText, cemetery, type } = props;
  const [progress, setProgress] = useState();

  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [lastBulkUploader, setLastBulkUploader] = useState();
  const [uploading, setUploading] = useState(false);

  function progressCallback(values) {
    setProgress(values);
  }
  async function bulkUploadFn(type, file) {

    setProgress([]);
    setDataSource([]);
    setUploading(false);
    const result = await FOV.api.bulkUpload(
      cemetery,
      type,
      file,
      progressCallback
    );

    if (result) {
      setColumns(
        result.preview.columns.map((col) => ({
          title: col,
          dataIndex: col,
          key: col,
          //render: (text, record) => <a>{record.toString()}</a>,
          render: (text, record, index) => {
            return record[col] ? (
              <div className="table-cell">
                <div className="new-value">{record[col].new + ""}</div>
                {(typeof record[col].old !== "undefined") && record[col].new !== record[col].old ? (
                  <div className="old-value">{record[col].old + ""}</div>
                ) : null}
              </div>
            ) : null;
          },
        }))
      );

      setDataSource(
        result.preview.comparisons.map((comp, index) => ({
          ...comp,
          key: index,
        }))
      );

      setLastBulkUploader(result.bulkUploader);
    }
  }

  async function upload() {
    setUploading(true);
    const uploadResult = await lastBulkUploader.upload(progressCallback);

  }

  return (
    <div className="bulkUploadPage" style={{ width: "100%" }}>
      <CenteredDiv
        style={{
          width: "100%",
          minWidth: "100%",
          height: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "250px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {buttonText}
          <TertiaryButton
            onClick={() => setOpen(!open)}
            style={{
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UploadOutlined />
          </TertiaryButton>
        </div>
      </CenteredDiv>
      <div
        style={{
          display: open ? "flex" : "none",
          flexDirection: "column",
          alignItems: "center",
          background: "rgba(0,0,0,0.25)",
        }}
      >
        <div className="progress-box">
          <input
            className="upload-button"
            type="file"
            onChange={(event) => {
              const file = event.target.files[0];
              if (file) {
                bulkUploadFn(type, file);
              }
            }}
          />
          {progress && (
            <>
              <h2 className="progress-title">{progress.process}</h2>
              <Progress
                style={{ color: "white" }}
                percent={progress.progress * 100}
                status={progress.state === "failed" ? "exception" : ""}
              />
              <div className="progress-message">
                {progress.message
                  ? progress.message.map((line) => <p>{line}</p>)
                  : null}
              </div>
            </>
          )}
        </div>
        {dataSource.length > 0 && (
          <>
            <Button onClick={upload} disabled={uploading}>
              UPLOAD
            </Button>
            <h2 style={{ fontSize: "12px" }}>
              ZIP UPLOAD PREVIEW:
              <br /> Please check the CSV has populated the database correctly
              before uploading.
            </h2>

            <Table
              scroll={{ x: 240 }}
              className="preview-table"
              dataSource={dataSource}
              columns={columns}
            />
          </>
        )}
      </div>
    </div>
  );
};
