import styled from "styled-components";

import { Layout } from "antd";
const { Header } = Layout;

export const FixedHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
  width: 100%;
  background-color: #c96015;
  padding: 0 20px;
  line-height: 0px;
  max-width: ${(props) =>
    props.isHamburgerOpen ? "calc(100vw - 200px)" : "100vw"};
`;
