import Router from "./components/Routes";
import { Provider } from "./SDK/context";
import "./App.css";

function App() {
  return (
    <Provider>
      <Router />
    </Provider>
  );
}

export default App;
