import Controller from "../Controller.js";
import Request from "../utility/Request.js";
// import jwtDecode from "jwt-decode";

class Authenticate extends Request {
  constructor() {
    super("post", `authenticate`);
  }
  sendRequest(username, password) {
    return this.sendRequestPromise({ username, password });
  }
  resolveRequest(result, resolve) {
    Controller.idToken = result.data.AuthenticationResult.IdToken;
    // const decoded = jwtDecode(Controller.idToken)
    // if (decoded['email']) {
    //   Controller.email = decoded['email']
    // }
    resolve(result);
  }
}

export default Authenticate;
