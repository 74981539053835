import React, { useState, useEffect } from "react";
import { Table, Input, InputNumber, Button, Checkbox } from "antd";

import { useParams } from "react-router-dom";
import FOV from "../../SDK/src/main";
import Search from "../Search";

import "./ViewAll.css";

const fields = {
  burial: [
    { name: "Id", label: "Id", type: "string", editable: false },
    { name: "Forename", label: "Forename", type: "string", editable: true },
    { name: "Surname", label: "Surname", type: "string", editable: true },
    { name: "GraveId", label: "GraveId", type: "string", editable: true },
    { name: "Country", label: "Country", type: "string", editable: true },
    { name: "Lat", label: "Lat", type: "number", editable: true },
    { name: "Lng", label: "Lng", type: "number", editable: true },
    { name: "Lot", label: "Lot", type: "string", editable: true },
    { name: "Plot", label: "Plot", type: "string", editable: true },
    { name: "Monument", label: "Monument", type: "string", editable: true },
    { name: "Type", label: "Type", type: "string", editable: true },
    { name: "Notable", label: "Notable", type: "boolean", editable: true },
    {
      name: "MiddleNames",
      label: "MiddleNames",
      type: "string",
      editable: true,
    },
    { name: "DoB", label: "DoB", type: "string", editable: true },
    { name: "DoD", label: "DoD", type: "string", editable: true },
    {
      name: "Country",
      label: "CountryOfService",
      type: "string",
      editable: true,
    },
    {
      name: "Nationality",
      label: "Nationality",
      type: "string",
      editable: true,
    },
    {
      name: "ForceServiceBranch",
      label: "Force / Service / Branch",
      type: "string",
      editable: true,
    },
    { name: "Regiment", label: "Regiment", type: "string", editable: true },
    {
      name: "MedalsAwards",
      label: "MedalsAwards",
      type: "string",
      editable: true,
    },
    { name: "Trade", label: "Trade", type: "string", editable: true },
    {
      name: "Description",
      label: "Description",
      type: "string",
      editable: true,
    },
    { name: "Weblinks", label: "Weblinks", type: "string", editable: true },
    { name: "Active", label: "Active", type: "boolean", editable: true },
  ],
  monument: [
    { name: "Id", label: "Id", type: "string", editable: false },
    { name: "Name", label: "Name", type: "string", editable: true },
    { name: "Country", label: "Country", type: "string", editable: true },
    { name: "Lat", label: "Lat", type: "number", editable: true },
    { name: "Lng", label: "Lng", type: "number", editable: true },
    {
      name: "Description",
      label: "Description",
      type: "string",
      editable: true,
    },
    { name: "Owner", label: "Owner", type: "string", editable: true },
    { name: "Artist", label: "Artist", type: "string", editable: true },
    { name: "Architect", label: "Architect", type: "string", editable: true },
    { name: "Labels", label: "Labels", type: "string", editable: true },
    { name: "Weblinks", label: "Weblinks", type: "string", editable: true },
    { name: "Active", label: "Active", type: "boolean", editable: true },
  ],
};

function chunkArrayInGroups(arr, size) {
  var myArray = [];
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

const ViewAll = ({ recordType }) => {
  const [saving, setSaving] = useState(false);
  const [items, setItems] = useState({ monument: [], burial: [] });
  const [editedItems, setEditedItems] = useState({ monument: [], burial: [] });
  const { cemetery } = useParams();
  const [batchSize, setBatchSize] = useState(25);
  const [visibleStartIndex, setVisibleStartIndex] = useState({
    monument: 0,
    burial: 0,
  });
  const [visibleEndIndex, setVisibleEndIndex] = useState({
    monument: batchSize,
    burial: batchSize,
  });
  const [type, setType] = useState(recordType);
  const [startKey, setStartKey] = useState({
    monument: undefined,
    burial: undefined,
  });

  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsItems, setSearchResultsItems] = useState([]);
  const [editedSearchResultsItems, setEditedSearchResultsItems] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let newSearchResultsItems = [];
      let itemsToSearch = [];
      for (let result of searchResults) {
        let existing = false;
        for (let item of editedItems[type].concat(searchResultsItems)) {
          if (item.SK === result.id) {
            newSearchResultsItems.push(item);
            existing = true;
            break;
          }
        }
        if (!existing) {
          itemsToSearch.push(result.id.split("_").pop());
        }
      }
      if (itemsToSearch.length) {
        await FOV.api
          .bulkGet(cemetery, type, itemsToSearch)
          .then((result) => {
            const bulkItems =
              result.data.Responses[Object.keys(result.data.Responses)[0]];

            newSearchResultsItems.push(...bulkItems);
          })
          .catch(console.error);
      }
      setSearchResultsItems(newSearchResultsItems);
      setEditedSearchResultsItems(newSearchResultsItems);
    }
    fetchData();
  }, [searchResults]);

  useEffect(() => {
    setType(recordType);
    setSearchResultsItems([]);
    setEditedSearchResultsItems([]);
    if (!items[recordType].length) {
      setVisibleEndIndex({ ...visibleEndIndex, [recordType]: batchSize });
      getBatch(recordType);
    }
  }, [recordType]);

  function getBatch(forceType) {
    if (typeof startKey[type] !== "undefined" && !startKey[type]) {
      return;
    }

    if (!forceType) {
      forceType = type;
    }

    FOV.api
      .getRecords(cemetery, forceType, startKey[forceType], batchSize)
      .then((result) => {
        setItems({
          ...items,
          [forceType]: [...items[forceType], ...result.data.Items],
        });
        setEditedItems({
          ...items,
          [forceType]: [...items[forceType], ...result.data.Items],
        });
        if (result.data.LastEvaluatedKey) {
          setStartKey({
            ...startKey,
            [forceType]: result.data.LastEvaluatedKey.SK,
          });
        } else {
          setStartKey({ ...startKey, [forceType]: false });
        }
      })
      .catch(console.error);
  }

  const columnsCheckboxOnChange = (e, index, field) => {
    if (searchResultsItems.length) {
      setEditedSearchResultsItems(
        editedSearchResultsItems.map((item, itemIndex) =>
          itemIndex === index
            ? {
                ...item,
                [field.name]: e.target.checked,
                changed: true,
              }
            : item
        )
      );
    } else {
      const rowIndex = index + visibleStartIndex[type];
      setEditedItems({
        ...items,
        [type]: editedItems[type].map((item, itemIndex) =>
          itemIndex === rowIndex
            ? {
                ...item,
                [field.name]: e.target.checked,
                changed: true,
              }
            : item
        ),
      });
    }
  };

  const columnsCheckboxOnClick = (index) => {
    if (searchResultsItems.length) {
      setEditedSearchResultsItems(
        editedSearchResultsItems.map((item, itemIndex) =>
          itemIndex === index
            ? {
                ...item,
                editting: true,
              }
            : item
        )
      );
    } else {
      const rowIndex = index + visibleStartIndex[type];
      setEditedItems({
        ...items,
        [type]: editedItems[type].map((item, itemIndex) =>
          itemIndex === rowIndex
            ? {
                ...item,
                editting: true,
              }
            : item
        ),
      });
    }
  };

  const columnsOnChange = (e, index, field) => {
    if (searchResultsItems.length) {
      setEditedSearchResultsItems(
        editedSearchResultsItems.map((item, itemIndex) =>
          itemIndex === index
            ? {
                ...item,
                [field.name]:
                  field.type === "number"
                    ? parseFloat(e.target.value)
                    : field.type !== "boolean"
                    ? e.target.value
                    : e.target.checked,
                // e.target.value,
                changed: true,
              }
            : item
        )
      );
    } else {
      const rowIndex = index + visibleStartIndex[type];
      setEditedItems({
        ...items,
        [type]: editedItems[type].map((item, itemIndex) =>
          itemIndex === rowIndex
            ? {
                ...item,
                [field.name]:
                  field.type === "number"
                    ? parseFloat(e.target.value)
                    : field.type !== "boolean"
                    ? e.target.value
                    : e.target.checked,
                // e.target.value,
                changed: true,
              }
            : item
        ),
      });
    }
  };

  const columnsOnClick = (index) => {
    if (searchResultsItems.length) {
      setEditedSearchResultsItems(
        editedSearchResultsItems.map((item, itemIndex) =>
          itemIndex === index
            ? {
                ...item,
                editting: true,
              }
            : item
        )
      );
    } else {
      const rowIndex = index + visibleStartIndex[type];
      setEditedItems({
        ...items,
        [type]: editedItems[type].map((item, itemIndex) =>
          itemIndex === rowIndex
            ? {
                ...item,
                editting: true,
              }
            : item
        ),
      });
    }
  };

  const columns = fields[type].map((field) => ({
    key: field.name,
    title: field.label,
    dataIndex: field.name,
    render: (text, record, index) =>
      field.editable ? (
        field.type === "boolean" ? (
          <Checkbox
            checked={text}
            onChange={(e) => {
              columnsCheckboxOnChange(e, index, field);
            }}
            onClick={() => {
              columnsCheckboxOnClick(index);
            }}
          />
        ) : searchResultsItems.length === 0 &&
          !editedItems[type][index + visibleStartIndex[type]].editting ? (
          <div
            style={{
              display: "flex",
              height: "2rem",
              lineHeight: "1rem",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              columnsOnClick(index);
            }}
          >
            {text}
          </div>
        ) : (
          <Input
            style={{ textAlign: "center" }}
            value={text}
            onChange={(e) => {
              columnsOnChange(e, index, field);
            }}
          />
        )
      ) : (
        <div style={{ textAlign: "center" }}>{text}</div>
      ),
  }));

  function save() {
    return new Promise((resolve, reject) => {
      setSaving(true);
      let changes = editedItems[type]
        .concat(editedSearchResultsItems)
        .filter((a) => a.changed);
      if (editedSearchResultsItems.length) {
        setItems({
          ...items,
          [type]: editedItems[type].map((item) => {
            for (let editedSearchItem of editedSearchResultsItems) {
              if (item.SK === editedSearchItem.SK) {
                return { ...editedSearchItem, changed: false };
              }
            }
            return { ...item, changed: false };
          }),
        });
        setEditedItems({
          ...items,
          [type]: editedItems[type].map((item) => {
            item.changed = false;
            for (let editedSearchItem of editedSearchResultsItems) {
              if (item.SK === editedSearchItem.SK) {
                return { ...editedSearchItem, changed: false };
              }
            }
            return { ...item, changed: false };
          }),
        });
        setSearchResultsItems(
          editedSearchResultsItems.map((x) => ({ ...x, changed: false }))
        );
        setEditedSearchResultsItems(
          editedSearchResultsItems.map((x) => ({ ...x, changed: false }))
        );
      } else {
        setItems({
          ...items,
          [type]: editedItems[type].map((x) => ({ ...x, changed: false })),
        });
        setEditedItems({
          ...items,
          [type]: editedItems[type].map((x) => ({ ...x, changed: false })),
        });
      }

      let chunkItems = [];
      for (let change of changes) {
        let exists = false;
        for (let item of chunkItems) {
          if (item.SK === change.SK) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          chunkItems.push(change);
        }
      }
      for (let item of chunkItems) {
        item.Id = item.SK.split("_").pop();
      }
      let promises = [];
      for (let chunk of chunkArrayInGroups(chunkItems, 25)) {
        promises.push(FOV.api.chunkUpload(cemetery, type, chunk));
      }
      Promise.all(promises)
        .then(() => {
          setSaving(false);
          resolve();
        })
        .catch((err) => {
          reject(err);
          console.error(err);
        });
      //setItems(editedItems);
    });
  }

  function saveAndGoBack() {
    save()
      .then(() => {
        const newVisibleStartIndex = Math.max(
          visibleStartIndex[type] - batchSize,
          0
        );
        setVisibleStartIndex({
          ...visibleStartIndex,
          [type]: newVisibleStartIndex,
        });
        setVisibleEndIndex({
          ...visibleEndIndex,
          [type]: newVisibleStartIndex + batchSize,
        });
      })
      .catch(console.error);
  }

  function saveAndContinue() {
    save()
      .then(() => {
        if (
          !(
            typeof startKey[type] === "boolean" &&
            items[type].length < visibleEndIndex[type]
          ) &&
          !editedSearchResultsItems.length
        ) {
          const newVisibleStartIndex = visibleEndIndex[type];

          if (items[type].length) {
            setVisibleStartIndex({
              ...visibleStartIndex,
              [type]: newVisibleStartIndex,
            });
          }
          setVisibleEndIndex({
            ...visibleEndIndex,
            [type]: newVisibleStartIndex + batchSize,
          });

          if (items[type].length < visibleStartIndex[type] + 2 * batchSize) {
            getBatch();
          }
        }
      })
      .catch(console.error);
  }

  return (
    <div className="viewAll">
      <div style={{ width: "20rem", margin: "1rem", textAlign: "left" }}>
        <h2 style={{ margin: 0 }}>SEARCH</h2>
        <h4 style={{ color: "white" }}>
          (Search by {type === "burial" ? "Forename, Surname" : "Name"}).
        </h4>
        <Search
          type={type}
          queryBy={type === "burial" ? "forename,surname" : "name"}
          setAllResults={(results) => {
            setSearchResults(results);
            //setEditedSearchResultsItems(results);
          }}
          listResults={false}
        />
      </div>

      <Table
        rowKey="Id"
        scroll={{ x: 240 }}
        style={{ width: "80rem", maxWidth: "100%" }}
        columns={columns}
        dataSource={(editedItems[type] && !editedSearchResultsItems.length
          ? editedItems[type]
          : editedSearchResultsItems.length
          ? editedSearchResultsItems
          : []
        )
          .slice(
            editedSearchResultsItems.length ? 0 : visibleStartIndex[type],
            editedSearchResultsItems.length ? undefined : visibleEndIndex[type]
          )
          .map((item) => ({
            key: item.SK,
            Id: item.SK.split("_")[1],
            ...item,
          }))}
        pagination={false}
      />
      <div style={{ margin: "1rem" }}>
        <Button
          disabled={
            visibleStartIndex[type] === 0 ||
            saving ||
            editedSearchResultsItems.length
          }
          onClick={saveAndGoBack}
        >
          Save and Go Back
        </Button>
        <InputNumber
          type="number"
          value={batchSize}
          onChange={e => setBatchSize(e)}
        />
        <Button disabled={saving} onClick={saveAndContinue}>
          Save and Continue
        </Button>
      </div>
      <Button
        disabled={saving || editedSearchResultsItems.length}
        onClick={() => {
          setEditedItems(items);
          setEditedSearchResultsItems(searchResultsItems);
        }}
      >
        Discard
      </Button>
    </div>
  );
};

export default ViewAll;
