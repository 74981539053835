import styled from "styled-components";

import { Button } from "antd";

export const EditCloseButton = styled(Button).attrs((props) => ({
  type: "edit-close",
}))`
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;

  &.edit-button {
    background-color: #6c7f91;
    border-color: #6c7f91;
  }
  &.close-button {
    background-color: #0000;
    border-color: #0000;
  }

  &.close-button:focus,
  &.close-button:hover {
    color: #fff;
    border-color: #ede6db;
  }

  &.edit-button:focus,
  &.edit-button:hover {
    background-color: #758a9e;
    color: #ede6db;
    border-color: #ede6db;
  }
`;
