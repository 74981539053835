import React from "react";

import { StyledDiv } from "./StyledDiv.js";
import { EditCloseButton } from "./EditCloseButton.js";

export const EditHeader = (props) => {
  const { label, children } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <StyledDiv
        style={{
          height: "76px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h2>{label}</h2>
        <EditCloseButton
          style={{
            width: "80px",
          }}
          className={open ? "close-button" : "edit-button"}
          onClick={() => setOpen(!open)}
        >
          {open ? "Close" : "Edit"}
        </EditCloseButton>
      </StyledDiv>
      {React.Children.map(children, (child) => (
        <div style={{ display: open ? "block" : "none" }}>{child}</div>
      ))}
    </>
  );
};
