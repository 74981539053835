import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import { BulkUploadButton } from "../../components/AngelStyledComponents/BulkUploadButton";
import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout.js";

import { PageTitle } from "../../components/AngelStyledComponents/PageTitle.js";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent.js";

import { CenteredDiv } from "../../components/AngelStyledComponents/CenteredDiv.js";
import { TertiaryButton } from "../../components/AngelStyledComponents/TertiaryButton.js";
import { Link } from "react-router-dom";
import { Context } from "../../SDK/context";

import "./BulkUploadGuide.css";
const BulkUploadGuide = () => {
  const { state } = useContext(Context);
  const { cemetery } = useParams();
  return (
    <StyledLayout>
      <FixedContent>
        <PageTitle fullSize>upload guide</PageTitle>
        <div className="upload-guide">
          <h2>Example of CSV layout.</h2>
          <h3>Mandatory fields are highlighted.</h3>
          <br />
          <h2>RECORDS</h2>
          <div className="csv-layout-container">
            <table className="csv-layout">
              <tr>
                <th className="csv-required">Id</th>
                <th className="csv-required">Name</th>
                <th className="csv-required">Lat</th>
                <th className="csv-required">Lng</th>
                <th>DedicatedBy</th>
                <th>DedicatedDate</th>
                <th>Conflicts</th>
                <th>Casualties</th>
                <th>Country</th>
                <th>Locality</th>
                <th>MonumentRefLocation</th>
                <th>StateProvince</th>
                <th>Accessibility</th>
                <th>Description</th>
                <th>Owner</th>
                <th>Artist</th>
                <th>Architect</th>
                <th>Labels</th>
                <th>Photo</th>
                <th>Media</th>
                <th>Weblinks</th>
                <th>Active</th>
                <th>Aux</th>
              </tr>
              <tr>
                <td>012345</td>
                <td>Example Monument</td>
                <td>53.7658</td>
                <td>-2.6596</td>
                <td>Edward Smith</td>
                <td>26/12/1930</td>
                <td>conflict a</td>
                <td>54</td>
                <td>USA</td>
                <td>Austin</td>
                <td>Centre of Site</td>
                <td>Texas</td>
                <td>Ramps</td>
                <td>An example monument</td>
                <td>John Smith</td>
                <td>Dave Johnson</td>
                <td>Dave Johnson</td>
                <td>label-a;label-b</td>
                <td>images/photo.png</td>
                <td>images/a.png;b.jpg</td>
                <td>http://a.com;http://b.com;c.com</td>
                <td>false</td>
                <td>Extra info</td>
              </tr>
            </table>
          </div>
          <h2>BURIALS</h2>
          <div className="csv-layout-container">
            <table className="csv-layout">
              <tr>
                <th className="csv-required">Id</th>
                <th className="csv-required">Forename</th>
                <th className="csv-required">Surname</th>
                <th className="csv-required">Lat</th>
                <th className="csv-required">Lng</th>
                <th>MiddleNames</th>
                <th>Notable</th>
                <th>BurialTypeVeteran</th>
                <th>Aliases</th>
                <th>Initials</th>
                <th>EducationalHonours</th>
                <th>DoB</th>
                <th>DoD</th>
                <th>DoD2</th>
                <th>AgeAtDeath</th>
                <th>Country</th>
                <th>CountryCommemorations</th>
                <th>MonumentCommemorations</th>
                <th>CountryOfService</th>
                <th>ServiceNumber</th>
                <th>Nationality</th>
                <th>Rank</th>
                <th>ForceServiceBranch</th>
                <th>Regiment</th>
                <th>SecondaryRegiment</th>
                <th>Unit</th>
                <th>HonoursAwards</th>
                <th>Trade</th>
                <th>Description</th>
                <th>FamilyInfo</th>
                <th>Conflicts</th>
                <th>GraveRefLot</th>
                <th>GraveRefBlock</th>
                <th>GraveRefSection</th>
                <th>PlotOrMonument</th>
                <th>Photo</th>
                <th>Media</th>
                <th>Weblinks</th>
                <th>Status</th>
                <th>DoI</th>
                <th>EstateDetails</th>
                <th>Active</th>
                <th>Aux</th>
              </tr>
              <tr>
                <td>012345</td>
                <td>Benjamin</td>
                <td>Scott</td>
                <td>53.7684</td>
                <td>-2.6652</td>
                <td>Alexander</td>
                <td>true</td>
                <td>true</td>
                <td>Bravo</td>
                <td>B.S.</td>
                <td>None</td>
                <td>4/6/1900</td>
                <td>3/1/1936</td>
                <td>8/1/1936</td>
                <td>36</td>
                <td>Spain</td>
                <td>None</td>
                <td>None</td>
                <td>England</td>
                <td>5382</td>
                <td>Finnish</td>
                <td>CSM</td>
                <td>CAVALRY</td>
                <td>Light Cavalry</td>
                <td>Armoured Reconnaissance</td>
                <td>CORPS</td>
                <td>The George Cross</td>
                <td>HR</td>
                <td>A strong leader.</td>
                <td>No family.</td>
                <td>None</td>
                <td>Lot A</td>
                <td>Block C</td>
                <td>Section 2</td>
                <td>Plot</td>
                <td>images/abc.png</td>
                <td>images/a.png;b.jpg</td>
                <td>http://a.com;http://b.com</td>
                <td>Occupied</td>
                <td>8/1/1930</td>
                <td>None</td>
                <td>true</td>
                <td>Extra info</td>
              </tr>
            </table>
          </div>
          <ol>
            <li>
              Check your csv headings match up with the ones in the example. And
              all mandatory fields are filled.
            </li>
            <li>
              Make sure to export the csv with all fields enclosed in "speech
              marks".
            </li>
            <li>Zip up the folder with the csv and images before uploading.</li>
            <li>
              Make sure any rich media/asset fields have the correct path to
              their respective media.
            </li>
          </ol>
          {/* <TertiaryButton style={{ marginLeft: "5px", width: "auto" }}>
            FULL UPLOAD GUIDE
          </TertiaryButton> */}
        </div>

        <CenteredDiv fullSize>
          <TertiaryButton style={{ width: "auto" }}>
            <Link to={`/${state.email}/${cemetery}/upload`}>
              BACK TO BULK UPLOAD
            </Link>
          </TertiaryButton>
        </CenteredDiv>
      </FixedContent>
    </StyledLayout>
  );
};

export default BulkUploadGuide;
