import styled from "styled-components";
import { Input } from "antd";

export const StyledInputDark = styled(Input)`
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-color: rgba(0, 0, 0, 0);
  color: #fff !important;
  max-width: 700px !important;
  box-shadow: inset -15px 0 12px rgba(0, 0, 0, 0.2) !important;
  & > .ant-input {
    background-color: rgba(0, 0, 0, 0) !important;
    color: #fff !important;
  }
  .ant-input-clear-icon {
    color: #fff;
  }
  .ant-form-item-has-error :not(.ant-input-disabled).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
  .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
`;
