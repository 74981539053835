import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const noticeType = (type) => {
  switch (type) {
    case "error":
      return (
        <CloseCircleOutlined
          style={{
            color: "#f5222d",
            fontSize: "20px",
            marginRight: "5px",
          }}
        />
      );
    case "warning":
      return (
        <ExclamationCircleOutlined
          style={{
            color: "#faad14",
            fontSize: "20px",
            marginRight: "5px",
          }}
        />
      );
    default:
  }
};

export const ContextualNotice = (props) => {
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "536px",
        padding: "10px 20px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {noticeType(props.type)}
      {props.children}
    </div>
  );
};
