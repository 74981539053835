import { Link } from "react-router-dom";
import { TertiaryButton } from "./TertiaryButton.js";

export const RecordOptions = (props) => {
  const { email, cemetery } = props;
  return (
    <div
      style={{
        margin: "20px",
        display: "grid",
      }}
    >
      <h4
        style={{
          backgroundClor: "#6c7f91",
          borderColor: "#6c7f91",
          color: "#fff",
          width: "374px",
          height: "62px",
          fontWeight: "600",
          margin: "0",
          textTransform: "uppercase",
          boxShadow: "0 3px 6px rgb(0, 0, 0, 0.16)",
          textAlign: "center",
          lineHeight: "62px",
        }}
      >
        {props.children}
      </h4>
      <div
        style={{
          backgroundColor: "#cddeed",
          height: "100px",
          width: "100%",
          maxWidth: "374px",
          display: "grid",
          gridAutoFlow: "column",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <TertiaryButton>
          <Link to={`/${email}/${cemetery}/record`}>Single</Link>
        </TertiaryButton>
        <TertiaryButton>
          <Link to={`/${email}/${cemetery}/upload`}>Bulk</Link>
        </TertiaryButton>
      </div>
    </div>
  );
};
