import Request from "../utility/Request.js";

class ForgotPassword extends Request {
  constructor() {
    super("post", `forgot-password`);
  }
  sendRequest(email) {
    return this.sendRequestPromise({ email });
  }
}

export default ForgotPassword;
