import Request from "../utility/Request.js";

class BulkGetCemeteries extends Request {
  constructor() {
    super("post", ``);
  }
  sendRequest(ids) {
    this.url = `bulk-get-cemeteries`;
    return this.sendRequestPromise({ ids });
  }
}

export default BulkGetCemeteries;
