import Request from "../utility/Request.js";

class BulkGet extends Request {
  constructor() {
    super("post", ``);
  }
  sendRequest() {
    const args = arguments[0];
    const cemetery = args[0];
    const type = args[1];
    const ids = [...new Set(args[2])];

    this.url = `bulk-get/${cemetery}/${type}`;
    //return new Promise((resolve) => resolve());
    return this.sendRequestPromise({ ids });
  }
}

export default BulkGet;
