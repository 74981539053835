import styled from "styled-components";
import { Input } from "antd";

export const StyledInputLight = styled(Input)`
  background-color: #cfe0ef;
  border-color: rgba(0, 0, 0, 0);
  max-width: 510px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  .ant-input {
    background-color: rgba(0, 0, 0, 0);
    color: #000;
  }
`;
