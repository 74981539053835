import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../SDK/context";
import FOV from "../../SDK/src/main";

import { CenteredDiv } from "../../components/AngelStyledComponents/CenteredDiv.js";
import { CemeteryOptions } from "../../components/AngelStyledComponents/CemeteryOptions.js";
import { PageTitle } from "../../components/AngelStyledComponents/PageTitle.js";
import { CemeteryButton } from "../../components/AngelStyledComponents/CemeteryButton.js";

import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout.js";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent.js";
import { TertiaryButton } from "../../components/AngelStyledComponents/TertiaryButton.js";

import placeholderCemeteryBurial from "../../assets/images/placeholder-cemetery-burial.png";

import LoadingScreen from "../../components/LoadingScreen";

const SiteManager = () => {
  const history = useHistory();
  const randomQuery = Math.random();
  let [cemeteryItems, setCemeteryItems] = useState([]);
  let [creatingCemetery, setCreatingCemetery] = useState(false);
  const { state, refresh, logout } = useContext(Context);

  useEffect(() => {
    async function fetchData() {
      if (state.cemeteries.length) {
        await FOV.api
          .bulkGetCemeteries(state.cemeteries.slice(0, 100))
          .then((result) => {
            setCemeteryItems(
              result.data.Responses[Object.keys(result.data.Responses)[0]].sort(
                (a, b) => a.SK.localeCompare(b.SK)
              )
            );
          })
          .catch(console.error);
      }
    }
    fetchData();
  }, [state.cemeteries]);

  return (
    <>{creatingCemetery && <LoadingScreen />}
      <StyledLayout>
        <FixedContent>
          <PageTitle>Site Manager</PageTitle>
          <CenteredDiv>
            <TertiaryButton
              onClick={() => {
                history.push(`/${state.email}/view-all`);
                //navigate(`${node.siteId}`
              }}
            >
              View all sites
            </TertiaryButton>
          </CenteredDiv>

          {cemeteryItems.map((cemetery) => (
            <CemeteryButton
              key={cemetery.SK}
              onClick={() => {
                history.push(`/${state.email}/${cemetery.SK}`);
                //navigate(`${node.siteId}`
              }}
              title={cemetery.Name}
              src={
                cemetery.Logo
                  ? cemetery.Logo + "?" + randomQuery
                  : placeholderCemeteryBurial
              }
            >
              <CemeteryOptions email={state.email} site={cemetery.SK} />
            </CemeteryButton>
          ))}

          <CenteredDiv>
            <TertiaryButton
              disabled={creatingCemetery}
              onClick={() => {
                setCreatingCemetery(true);
                FOV.api
                  .createCemetery("New Cemetery")
                  .then((result) => {
                    refresh();
                    history.push(`/${state.email}/${result.data.id}/edit`);
                  })
                  .catch(console.error);
              }}
            >
              Add new site
            </TertiaryButton>
          </CenteredDiv>
          <CenteredDiv>
            <TertiaryButton
              onClick={() => {
                logout();
              }}
            >
              Logout
            </TertiaryButton>
          </CenteredDiv>
        </FixedContent>
      </StyledLayout></>
  );
};

export default SiteManager;
