// import styled from "styled-components";
import { GlobalStyle } from "./GlobalStyle.js";
import { Layout } from "antd";

export const StyledLayout = ({ children, backgroundColor }) => {
  return (
    <>
      <GlobalStyle />
      <Layout style={{ background: backgroundColor || "#3F5569", paddingBottom: "80px" }}>{children}</Layout>
    </>
  );
};
