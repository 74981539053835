import React from "react";

import { TertiaryButton } from "./TertiaryButton.js";
import { PrimaryButton } from "./PrimaryButton.js";

import { RightOutlined, LeftOutlined } from "@ant-design/icons";

import "./Gallery.css";

export const Gallery = (props) => {
  const {
    form,
    mediaUploads,
    setMediaUploads,
    addMediaUpload,
    type,
    loaded,
  } = props;
  const [images, setImages] = React.useState([]);
  const [selected, setSelected] = React.useState(0);

  const input = React.useRef(null);

  function onChange(event) {
    const file = event.target.files[0];
    if (file) {
      addMediaUpload(file, type, "media", file.name);
    }
  }

  const decrement = () => {
    if (selected > 0) {
      setSelected(selected - 1);
    }
  };
  const increment = () => {
    if (selected < images.length - 1) {
      setSelected(selected + 1);
    }
  };

  function updateImages() {
    const media = form.getFieldsValue().Media;

    if (media) {
      let tempImages = media.split(";");

      setImages(
        tempImages
          .map((image) => {
            if (mediaUploads[image] && mediaUploads[image].tempSrc) {
              return { image, tempSrc: mediaUploads[image].tempSrc };
            } else {
              return { image };
            }
          })
          .reverse()
      );
      setSelected(0);
    } else {
      setImages([]);
    }
    // let newSrc = `${newImage}?${randomQuery}`;
    // if (mediaUploads[newImage] && mediaUploads[newImage].tempSrc) {
    //   newSrc = mediaUploads[newImage].tempSrc;
    // }
    // setSrc(newSrc);
  }

  React.useEffect(() => {
    updateImages();
  }, [loaded, mediaUploads, form]);

  function onClick() {
    const image = images[selected].image;

    const mapping = mediaUploads[image];

    if (mapping) {
      setMediaUploads({
        ...mediaUploads,
        [image]: undefined,
      });
    }
    let values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      Media: values.Media
        ? values.Media.split(";")
            .filter((a) => a !== image)
            .join(";")
        : "",
    });
    updateImages();
  }

  return (
    <div
      style={{
        // height: "390px",
        marginBottom: "10px",
        width: "100%",
        maxWidth: "720px",
      }}
    >
      <input
        style={{ display: "none" }}
        type="file"
        onChange={onChange}
        ref={input}
      />
      <div
        style={{
          padding: "0 20px",
          // height: "242px",
          height: "242px",
        }}
      >
        <div
          style={{
            margin: "10px 0 0 0",
            height: "166px",
            display: "flex",
          }}
        >
          <div
            style={{
              flexShrink: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <TertiaryButton
              style={{
                width: "100px",
                margin: "5px 10px",
              }}
              onClick={() => {
                input.current.click();
              }}
            >
              Upload
            </TertiaryButton>
            <PrimaryButton
              style={{
                width: "100px",
                margin: "5px 10px",
              }}
              onClick={onClick}
            >
              Delete
            </PrimaryButton>
          </div>
          <div
            style={{
              flex: "1",
              backgroundColor: "rgb(44, 59, 74)",
            }}
          >
            {images.length > 0 && (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      margin: "auto",
                    }}
                    src={
                      images[selected].tempSrc
                        ? images[selected].tempSrc
                        : images[selected].image
                    }
                    alt="placeholder-cemetery-burial.png"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            height: "75px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TertiaryButton
            onClick={() => decrement()}
            style={{
              margin: "10px",
              width: "32px",
              padding: "2px 0 0 0",
            }}
          >
            <LeftOutlined
              style={{
                fontSize: "20px",
              }}
            />
          </TertiaryButton>
          <TertiaryButton
            onClick={() => increment()}
            style={{
              margin: "10px",
              width: "32px",
              padding: "2px 0 0 0",
            }}
          >
            <RightOutlined
              style={{
                fontSize: "20px",
              }}
            />
          </TertiaryButton>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#2c3b4a",
          padding: "20px 20px",
          // height: "162px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div className="gallery__div">
          {images.map((value, index) => (
            <img
              src={value.tempSrc ? value.tempSrc : value.image}
              onClick={() => setSelected(index)}
              key={index}
              style={{
                margin: "0 5px",
                width: "auto",
                height: "74px",
                border: `${index === selected ? "3px solid white" : ""}`,
              }}
              alt="placholder"
            />
          ))}
        </div>
        {/* <TertiaryButton
          style={{
            height: "54px",
            width: "120px",
          }}
        >
          View Full
          <br /> Gallery
        </TertiaryButton> */}
      </div>
    </div>
  );
};
