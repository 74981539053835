import Request from "../utility/Request.js";

class GetOrganizations extends Request {
  constructor() {
    super("get", ``);
  }
  sendRequest(startKey, count) {
    let queries = [];
    if (startKey) {
      queries.push("startKey=" + startKey);
    }
    if (count) {
      queries.push("count=" + count);
    }
    this.url = `get-all-organizations${
      queries.length ? "?" + queries.join("&") : ""
    }`;
    return this.sendRequestPromise({});
  }
}

export default GetOrganizations;
