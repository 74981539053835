import Request from "../utility/Request.js";

class DeleteCemetery extends Request {
  constructor() {
    super("delete", ``);
  }
  sendRequest() {
    const args = arguments[0];
    const cemetery = args[0];
    this.url = `empty-cemetery/${cemetery}`;
    return this.sendRequestPromise();
  }
}

export default DeleteCemetery;
