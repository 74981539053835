import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
import "./LoadingScreen.css"

const LoadingScreen = () => {

    return (
        <div className="loading"> <LoadingOutlined style={{ fontSize: 50 }} spin /></div>
    );
};

export default LoadingScreen;
