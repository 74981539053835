import Request from "../utility/Request.js";

class Search extends Request {
  constructor() {
    super("post", `search`);
  }
  sendRequest(cemetery, type, query_by, filter_by, input) {
    return this.sendRequestPromise({
      cemetery,
      type,
      query_by,
      filter_by,
      input,
    });
  }
}

export default Search;
