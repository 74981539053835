import React, { useContext, useEffect, useState } from "react";

import { Context } from "../../SDK/context";
import { useParams } from "react-router-dom";
import AutoOverview from "../../SDK/components/AutoOverview";
import NewForm from "../../components/NewForm";

import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout";

import { PageTitle } from "../../components/AngelStyledComponents/PageTitle";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent";

import moment from "moment";

import FOV from "../../SDK/src/main.js";

const fieldMapping = [
  { name: "Name", label: "Site Name", type: "string", required: true },
  {
    name: "Logo",
    label: "Organization Logo",
    type: "image",
  },
  {
    name: "Weblinks",
    label: "Organization Website",
    type: "string",
  },
  {
    name: "Media",
    label: "Organization Gallery",
    type: "media",
  },
];

const pictureFields = fieldMapping
  .filter((field) => field.type === "image")
  .map((field) => field.name);

const SiteEditor = () => {
  const { organization } = useParams();
  const { state } = useContext(Context);
  const [originalItem, setOriginalItem] = useState({});

  useEffect(() => {
    //getOverview(cemetery);
    if (organization) {
      FOV.api
        .getOrganization(organization)
        .then((result) => {
          if (result.data.Item) {
            let newForm = JSON.parse(JSON.stringify(result.data.Item));
            setOriginalItem(newForm);
          }
        })
        .catch(console.error);
    }
  }, []);

  return (
    <StyledLayout>
      <FixedContent>
        <PageTitle>Organization Editor</PageTitle>

        <NewForm
          style={{ maxWidth: "720px" }}
          originalItem={originalItem}
          fieldMapping={fieldMapping}
          pictureFields={pictureFields}
          type="organization"
        />
      </FixedContent>
    </StyledLayout>
  );
};

export default SiteEditor;
