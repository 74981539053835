import Request from "../utility/Request.js";

class TrimMediaFolder extends Request {
  constructor() {
    super("post", ``);
  }
  sendRequest() {
    const args = arguments[0];
    const cemetery = args[0];
    const type = args[1];
    const id = args[2];
    const mapping = args[3];

    this.url = `trim-media-folder/${cemetery}/${type}/${id}`;
    //return new Promise((resolve) => resolve());
    return this.sendRequestPromise({ mapping });
  }
}

export default TrimMediaFolder;
