import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form } from "antd";
import { Context } from "../../SDK/context";
import InputField from "../../components/InputField";
import submit from "../../SDK/src/utility/submit.js";

import { randomBytes } from "crypto";

import { SaveOrDiscard } from "../../components/AngelStyledComponents/SaveOrDiscard";

import LoadingScreen from "../../components/LoadingScreen";

const NewForm = ({
  originalItem,
  fieldMapping,
  pictureFields,
  type,
  afterSubmit,
  beforeSubmit,
  organizationId,
}) => {
  const { getOverview } = useContext(Context);
  const { cemetery, organization } = useParams();
  let cemeteryOrOrganization = organizationId || organization;
  if (!cemeteryOrOrganization) {
    cemeteryOrOrganization = cemetery;
  }

  const [submitting, setSubmitting] = useState(false);
  const [mediaUploads, setMediaUploads] = useState({});
  const [randomQuery, setRandomQuery] = useState(Math.random());
  const [loaded, setLoaded] = useState(false);

  const [antdForm] = Form.useForm();
  useEffect(() => {

    if (Object.keys(originalItem).length) {
      originalItem.Id = originalItem.SK.split("_").pop();

      antdForm.setFieldsValue(originalItem);

      setLoaded(true);
    }
  }, [originalItem, antdForm]);

  function addMediaUpload(file, type, mediaType, name) {
    if (file.type.split("/")[0] !== "image") {
      console.log("file is not an image");
    } else {
      let uid = randomBytes(16).toString("hex");
      let values = antdForm.getFieldsValue();
      if (mediaType === "media") {
        antdForm.setFieldsValue({
          ...values,
          Media: values.Media
            ? [...values.Media.split(";"), uid].join(";")
            : uid,
        });
      } else {
        if (Object.keys(mediaUploads).includes(values[mediaType])) {
          uid = values[mediaType];
        } else {
          antdForm.setFieldsValue({ ...values, [mediaType]: uid });
        }
      }
      setMediaUploads({
        ...mediaUploads,
        [uid]: {
          id: uid,
          file,
          type,
          mediaType,
          name,
          tempSrc: URL.createObjectURL(file),
        },
      });
    }
  }

  function discard() {
    setMediaUploads({});
    if (Object.keys(originalItem).length) {
      antdForm.setFieldsValue(originalItem);
    } else {
      antdForm.resetFields();
    }
    setLoaded(!loaded);
  }

  function submitEdit(formToSubmit, afterSubmit) {
    setSubmitting(true);
    submit(
      cemeteryOrOrganization,
      formToSubmit,
      type,
      mediaUploads,
      pictureFields
    )
      .then((newOriginal) => {
        setRandomQuery(Math.random());
        setMediaUploads({});
        antdForm.setFieldsValue(newOriginal);
        setLoaded(!loaded);
        setSubmitting(false);
        if (afterSubmit) {
          afterSubmit(newOriginal);
        } else {
          // window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
        setSubmitting(false);
      });
  }

  return (
    <>
      {submitting && <LoadingScreen />}
      <Form
        style={{ maxWidth: "100%", width: "720px" }}
        form={antdForm}
        onFinish={async (values) => {
          let submittedForm = {};
          const latFields = ["MapALat", "MapBLat", "MapCLat", "MapDLat"];
          const lngFields = ["MapALng", "MapBLng", "MapCLng", "MapDLng"];
          function clamp(min, max, value) {
            return Math.min(Math.max(min, value), max);
          }
          for (let field of fieldMapping) {
            let fields = [];
            if (field.type === "multiple-inputs") {
              fields = field.inputs;
            } else {
              fields.push(field);
            }

            for (let individualField of fields) {
              let value = values[individualField.name];

              switch (individualField.type) {
                case "int":
                  value = parseInt(value);
                  if (isNaN(value)) continue;
                  break;
                case "float":
                case "coord":
                  value = parseFloat(value);
                  if (latFields.includes(field.name)) {
                    value = clamp(-89.99, 89.99, value);
                  } else if (lngFields.includes(field.name)) {
                    value = clamp(-179.99, 179.99, value);
                  }
                  if (isNaN(value)) continue;
                  break;
                default:
                  break;
              }

              submittedForm[individualField.name] = value;
            }
          }

          for (let field of pictureFields) {
            submittedForm[field] = values[field];
          }
          if (values.Media) {
            submittedForm.Media = values.Media;
          }

          for (let key of Object.keys(originalItem)) {
            if (
              typeof submittedForm[key] === "undefined" &&
              key !== "Media" &&
              !pictureFields.includes(key)
            ) {
              submittedForm[key] = originalItem[key];
            }
          }

          if (beforeSubmit) {
            try {
              const beforeSubmitResult = await beforeSubmit();

              submittedForm = { ...submittedForm, ...beforeSubmitResult };
            } catch (err) {
              console.log(err);
              return;
            }
          }

          submitEdit(submittedForm, afterSubmit);
          // if (afterSubmit) {
          //   afterSubmit(submittedForm);
          // }
        }}
      >
        {fieldMapping.map((field, index) => (
          <InputField
            key={index}
            field={field}
            form={antdForm}
            loaded={loaded}
            mediaUploads={mediaUploads}
            randomQuery={randomQuery}
            setRandomQuery={setRandomQuery}
            setMediaUploads={setMediaUploads}
            addMediaUpload={addMediaUpload}
            type={type}
          />
        ))}

        <SaveOrDiscard
          disabled={submitting}
          save={() => {
            antdForm.submit();
          }}
          discard={discard}
        />
      </Form>
    </>
  );
};

export default NewForm;
