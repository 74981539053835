import Request from "../utility/Request.js";

class Register extends Request {
  constructor() {
    super("post", `register`);
  }
  sendRequest(email, password) {
    return this.sendRequestPromise({ email, password });
  }
}

export default Register;
