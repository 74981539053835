import React, { useEffect } from "react";
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
  logoPosition: "bottom-right",
});

const MapWithMarker = ({ lat, lng }) => {
  useEffect(() => {
    console.log(lat, lng)
  }, [lat, lng])
  return (
    <>
      <Mapbox
        //eslint-disable-next-line
        style="mapbox://styles/mapbox/dark-v10"
        containerStyle={{
          height: "100%",
          width: "100%",
        }}
        center={[lng, lat]}
        // center={[lat, lng]}
        zoom={[16]}
      >
        <Layer
          type="symbol"
          id="marker"
          layout={{
            "icon-image": "monument-15",
            "icon-size": 3,
          }}
        >
          <Feature coordinates={[lng, lat]} />
          {/* <Feature coordinates={[lat, lng]} /> */}
        </Layer>
      </Mapbox>
    </>
  );
};

export default MapWithMarker;
