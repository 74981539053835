import React from "react";

import { StyledDiv } from "./StyledDiv.js";
import { EditCloseButton } from "./EditCloseButton.js";

import "./ImageLabel.css";

export const ImageLabel = (props) => {
  const { label, src, children, map } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <StyledDiv
        style={{
          height: "150px",
          gridTemplateColumns: "1fr 1fr 1fr",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            justifySelf: "start",
          }}
        >
          {label}
        </h2>
        {map ? (
          <div style={{ position: "relative", justifySelf: "center" }}>
            <p className="imageLabel__a">A</p>
            <p className="imageLabel__b">B</p>
            <p className="imageLabel__c">C</p>
            <p className="imageLabel__d">D</p>
            <img
              {...{ src }}
              style={{
                width: "auto",
                height: "100px",
                justifySelf: "center",
              }}
              alt="placeholder"
            />
          </div>
        ) : (
          <img
            {...{ src }}
            style={{
              width: "auto",
              height: "100px",
              justifySelf: "center",
            }}
            alt="placeholder"
          />
        )}

        <EditCloseButton
          style={{
            width: "80px",
            justifySelf: "end",
          }}
          className={open ? "close-button" : "edit-button"}
          onClick={() => setOpen(!open)}
        >
          {open ? "Close" : "Edit"}
        </EditCloseButton>
      </StyledDiv>
      {open && children}
    </>
  );
};
