import Request from "../utility/Request.js";

class ResetPassword extends Request {
  constructor() {
    super("post", `reset-password`);
  }
  sendRequest(email, confirmationCode, password) {
    return this.sendRequestPromise({ email, confirmationCode, password });
  }
}

export default ResetPassword;
