import FOV from "../main";

export default function submit(
  cemeteryOrOrganization,
  newItem,
  type,
  mediaUploads,
  pictureFields
) {
  return new Promise(async (resolve, reject) => {
    let originalItem = false;
    let getRecordResult;
    try {
      switch (type) {
        case "cemetery":
          getRecordResult = await FOV.api.getCemetery(cemeteryOrOrganization);
          break;
        case "organization":
          getRecordResult = await FOV.api.getOrganization(
            cemeteryOrOrganization
          );
          break;
        default:
          getRecordResult = await FOV.api.getRecord(
            cemeteryOrOrganization,
            type,
            newItem.Id
          );
          break;
      }

      if (getRecordResult.data.Item) {
        originalItem = getRecordResult.data.Item;
      }
    } catch (err) {
      reject(err);
    }
    const urlMappingPromises = [];
    for (let pictureField of pictureFields) {
      // If the picture field has changed
      if (
        newItem[pictureField] &&
        newItem[pictureField] !== originalItem[pictureField]
      ) {
        // ADD PRESIGNED URL PROMISE
        urlMappingPromises.push(
          new Promise((resolve, reject) => {
            FOV.api
              .getMediaUrl(
                cemeteryOrOrganization,
                type,
                cemeteryOrOrganization,
                pictureField,
                mediaUploads[newItem[pictureField]].name
              )
              .then((result) => {
                mediaUploads[newItem[pictureField]].url = result.data.url;
                newItem[pictureField] = result.data.url.split("?")[0];
                resolve();
              })
              .catch(reject);
          })
        );
      }
    }
    const originalMedia = originalItem.Media
      ? originalItem.Media.split(";")
      : [];
    const newMedia = newItem.Media ? newItem.Media.split(";") : [];
    for (let i = 0; i < newMedia.length; ++i) {
      const media = newMedia[i];
      // If there is a new media
      if (!originalMedia.includes(media)) {
        // ADD PRESIGNED URL PROMISE

        urlMappingPromises.push(
          new Promise((resolve, reject) => {
            FOV.api
              .getMediaUrl(
                cemeteryOrOrganization,
                type,
                cemeteryOrOrganization,
                "Media",
                mediaUploads[media].name
              )
              .then((result) => {
                mediaUploads[media].url = result.data.url;
                newMedia[i] = result.data.url.split("?")[0];
                resolve();
              })
              .catch((err) => {
                reject(err);
              });
          })
        );
      }
    }
    Promise.all(urlMappingPromises)
      .then(() => {
        newItem.Media = newMedia.join(";");
        const deletePromises = [];
        for (let media of originalMedia) {
          if (!newMedia.includes(media)) {
            // REMOVE ORIGINAL MEDIA

            deletePromises.push(
              FOV.api.removeMedia(
                cemeteryOrOrganization,
                type,
                cemeteryOrOrganization,
                "Media",
                media.split("/").pop()
              )
            );
          }
        }
        for (let pictureField of pictureFields) {
          // If the picture field has changed
          if (originalItem[pictureField] && !newItem[pictureField]) {
            // REMOVE ORIGINAL PICTURE
            deletePromises.push(
              FOV.api.removeMedia(
                cemeteryOrOrganization,
                type,
                cemeteryOrOrganization,
                pictureField,
                originalItem[pictureField].split("/").pop()
              )
            );
          }
        }
        Promise.all(deletePromises)
          .then(() => {
            let uploadPromises = [];
            for (let key of Object.keys(mediaUploads)) {
              const upload = mediaUploads[key];
              if (!upload) continue;
              uploadPromises.push(
                FOV.api.uploadMedia(upload.file, upload.type, upload.url)
              );
            }
            Promise.all(uploadPromises)
              .then(() => {
                if (type === "cemetery") {
                  FOV.api
                    .updateCemetery(cemeteryOrOrganization, newItem)
                    .then(resolve(newItem))
                    .catch(reject);
                } else if (type === "organization") {
                  FOV.api
                    .postOrganization(newItem)
                    .then(() => {
                      resolve(newItem);
                    })
                    .catch(reject);
                } else {
                  FOV.api
                    .postRecord(cemeteryOrOrganization, newItem, type)
                    .then((result) => {
                      newItem.SK = result.data.Item.SK
                      resolve(newItem);
                    })
                    .catch(reject);
                }
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
}
