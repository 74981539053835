import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../SDK/context";
import FOV from "../../SDK/src/main";
import Fuse from "fuse.js";
import "./ViewAllCemeteries.css";

import { Input } from "antd";
import { Table } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { PageTitle } from "../../components/AngelStyledComponents/PageTitle.js";
import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout.js";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent.js";

function chunkArrayInGroups(arr, size) {
  var myArray = [];
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

const ViewAllCemeteries = () => {
  const { state } = useContext(Context);
  const [results, setResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let cemeteryList = [];
      if (state.cemeteries.length) {
        let groups = chunkArrayInGroups(state.cemeteries, 100);
        for (let group of groups) {
          await FOV.api
            .bulkGetCemeteries(group)
            .then((result) => {
              const bulkItems =
                result.data.Responses[Object.keys(result.data.Responses)[0]];
              cemeteryList.push(...bulkItems);
            })
            .catch(console.error);
        }
      }
      setResults(cemeteryList);
    }
    fetchData();
  }, [state.cemeteries]);

  const columns = [
    {
      title: "ID",
      dataIndex: "Id",
      key: "Id",
      render: (text) => (
        <Link to={`/${state.email}/${text}/edit/${text}`}>{text}</Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      sorter: {
        compare: (a, b) => `${a.Name}`.localeCompare(`${b.Name}`),
      },
    },
    {
      title: "Active",
      dataIndex: "Active",
      key: "Active",
      align: "center",
      render: (text) => (text ? <CheckOutlined /> : <CloseOutlined />),
      sorter: {
        compare: (a, b) => {
          if (a.Active && !b.Active) {
            return 1;
          } else if (b.Active && !a.Active) {
            return -1;
          } else {
            return 0;
          }
        },
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Casualties",
      dataIndex: "Casualties",
      key: "Casualties",
      sorter: {
        compare: (a, b) => {
          if (
            typeof a.Casualties !== "undefined" &&
            typeof b.Casualties !== "undefined"
          ) {
            return a.Casualties > b.Casualties;
          } else {
            if (typeof a.Casualties !== "undefined") {
              return 1;
            }
            if (typeof b.Casualties !== "undefined") {
              return -1;
            }
            return 1;
          }
        },
      },
    },
    {
      title: "Country",
      dataIndex: "Country",
      key: "Country",
      sorter: {
        compare: (a, b) => `${a.Country}`.localeCompare(`${b.Country}`),
      },
    },
    {
      title: "City",
      dataIndex: "City",
      key: "City",
      sorter: {
        compare: (a, b) => `${a.City}`.localeCompare(`${b.City}`),
      },
    },
    {
      title: "State/Province",
      dataIndex: "StateProvince",
      key: "StateProvince",
      sorter: {
        compare: (a, b) =>
          `${a.StateProvince}`.localeCompare(`${b.StateProvince}`),
      },
    },
    {
      title: "Primary Account Holder",
      dataIndex: "PrimaryAccountHolder",
      key: "PrimaryAccountHolder",
      sorter: {
        compare: (a, b) =>
          `${a.PrimaryAccountHolder}`.localeCompare(
            `${b.PrimaryAccountHolder}`
          ),
      },
    },
  ];

  function fuzzySearch(input) {
    const options = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      keys: ["Name"],
      //keys: ["Regiment"],
    };
    const fuse = new Fuse(results, options);
    setSearchResults([...fuse.search(input).map((a) => a.item)]);
  }

  return (
    <StyledLayout className="viewAllCemeteries">
      <FixedContent style={{ width: "100%" }}>
        <PageTitle fullSize={true}>View All Sites</PageTitle>

        <div style={{ width: "20rem", margin: "1rem" }}>
          <h2 style={{ margin: 0 }}>SEARCH</h2>
          <h4 style={{ color: "white" }}>(Search by Site Name).</h4>
          <Input
            placeholder="Cemetery Name"
            // value={input}
            onChange={(e) => {
              //setInput(e.target.value);
              fuzzySearch(e.target.value);
            }}
          />
        </div>
        <Table
          align="center"
          // pagination={{ position: ["none"] }}
          scroll={{ x: 240 }}
          style={{ width: "80rem" }}
          columns={columns}
          dataSource={(searchResults.length ? searchResults : results)
            .map((item) => ({
              key: item.SK + Math.random(),
              Id: item.SK,
              ...item,
            }))
            .sort((a, b) => a.SK.localeCompare(b.SK))}
        />
        {/* {(searchResults.length ? searchResults : results).map((result) => (
        <Link
          to={`/${state.email}/${result.SK}`}
          className="viewAllCemeteries__cemetery"
          key={result.SK}
        >
          {/* {Object.values(result).map((a) => (
            <span
              className="viewAllCemeteries__attribute"
              key={`${result.SK}_${a}`}
            >
              {a}
            </span>
          ))} 
        </Link>
      ))} */}
      </FixedContent>
    </StyledLayout>
  );
};

export default ViewAllCemeteries;
