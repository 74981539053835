import axios from "axios";
import Controller from "../Controller.js";

const authAxios = axios.create({
  baseURL: Controller.baseUrl,
  withCredentials: true,
  credentials: "include",
});

class Request {
  constructor(method, url, withCredentials = true, headers, useBase = true) {
    this.method = method;
    this.url = url;
    this.withCredentials = withCredentials;
    this.headers = headers;
    this.useBase = useBase;
  }
  resolveRequest(result, resolve) {
    resolve(result);
  }
  rejectRequest(error, reject) {
    reject(error);
  }

  sendRequest() {
    return this.sendRequestPromise({});
  }

  sendRequestPromise(data) {
    return new Promise((resolve, reject) => {
      let config = {
        method: this.method,
        url: this.useBase ? Controller.baseUrl + "/" + this.url : this.url,
        data: this.method === "get" ? undefined : data,
        headers: this.headers
          ? this.headers
          : { Authorization: Controller.idToken },
        withCredentials: this.withCredentials,
        credentials: "include",
      };
      // if (this.isFormData) {
      //   config.headers[
      //     "content-type"
      //   ] = `multipart/form-data; boundary=${data._boundary}`;
      // }

      authAxios(config)
        .then((result) => {
          this.resolveRequest(result, resolve);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            switch (error.response.status) {
              case 400: // Bad request
                break;
              case 401: // Unauthenticated
                break;
              case 403: // Forbidden
                break;
              case 404: // Not found
                break;
              case 405: // Not allowed
                break;
              case 422: // Unprocessable
                break;
              case 500: // Internal server error
                break;
              default:
                break;
            }
          }

          this.rejectRequest(error, reject);
        });
    });
  }
}

export default Request;
