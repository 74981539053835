import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Context } from "../../SDK/context";
import FOV from "../../SDK/src/main";
import Fuse from "fuse.js";

import { Input } from "antd";

import { PageTitle } from "../../components/AngelStyledComponents/PageTitle.js";
import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout.js";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent.js";

import { TourButton } from "../../components/AngelStyledComponents/TourButton.js";

import placeholderMonumentTour from "../../assets/images/placeholder-monument-tour.png";
import { TertiaryButton } from "../../components/AngelStyledComponents/TertiaryButton.js";
import { CenteredDiv } from "../../components/AngelStyledComponents/CenteredDiv.js";

const ToursOverview = () => {
  const history = useHistory();
  const { state } = useContext(Context);
  const { cemetery } = useParams();
  const [tours, setTours] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const [creatingTour, setCreatingTour] = useState(false);

  useEffect(() => {
    getAllTours();
  }, []);

  async function getAllTours() {
    let gotAll = false;
    let startKey = undefined;
    let allTours = [];
    while (!gotAll) {
      try {
        const result = await FOV.api.getRecords(
          cemetery,
          "tour",
          startKey,
          100
        );
        allTours.push(...result.data.Items);
        if (result.data.LastEvaluatedKey) {
          startKey = result.data.LastEvaluatedKey;
        } else {
          gotAll = true;
        }
      } catch (err) {
        console.log(err);
        gotAll = true;
      }
    }
    setTours(allTours.sort((a, b) => a.SK.localeCompare(b.SK)));
  }

  function fuzzySearch(input) {
    const options = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      keys: ["Name"],
      //keys: ["Regiment"],
    };
    const fuse = new Fuse(tours, options);
    setSearchResults([
      ...fuse
        .search(input)

        .map((a) => a.item)
        .sort((a, b) => a.SK.localeCompare(b.SK)),
    ]);
  }

  return (
    <StyledLayout>
      <FixedContent>
        <PageTitle>Tours Overview</PageTitle>
        <div style={{ width: "20rem", margin: "1rem", textAlign: "left" }}>
          <h2 style={{ margin: 0 }}>SEARCH</h2>
          <h4 style={{ color: "white" }}>(Search by Tour Name).</h4>
          <Input
            type="text"
            onChange={(e) => {
              fuzzySearch(e.target.value);
            }}
          />
        </div>

        {(searchResults.length ? searchResults : tours)
          .slice(0, 5)
          .map((tour) => (
            <TourButton
              src={tour.Logo || placeholderMonumentTour}
              key={tour.SK}
              title={tour.Name}
              onClick={() => {
                history.push(
                  `/${state.email}/${cemetery}/tours/${tour.SK.split("_")[1]}`
                );
              }}
            />
          ))}
        <CenteredDiv>
          <TertiaryButton
            disabled={creatingTour}
            onClick={() => {
              setCreatingTour(true);
              FOV.api
                .postRecord(cemetery, { Name: "New Tour" }, "tour")
                .then((result) => {
                  history.push(
                    `/${state.email}/${cemetery}/tours/${
                      result.data.Item.SK.split("_")[1]
                    }`
                  );
                })
                .catch(console.error);
            }}
          >
            Add new Tour
          </TertiaryButton>
        </CenteredDiv>
      </FixedContent>
    </StyledLayout>
  );
};

export default ToursOverview;
