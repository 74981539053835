import { StyledDiv } from "./StyledDiv.js";

export const PageTitle = ({ children, fullSize = false }) => {
  return (
    <StyledDiv
      fullSize={fullSize}
      as="h1"
      style={{
        fontSize: "32px",
        fontWeight: "bold",
        gridTemplateColumns: "1fr",
        justifyItems: "center",
      }}
    >
      {children}
    </StyledDiv>
  );
};
