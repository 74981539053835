import React from "react";
import Icon, {
  CloseOutlined,
  RollbackOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

export const RollbackIcon = (props) => (
  <StyledIcon as={RollbackOutlined} {...props} />
);
export const RotatedMenuIcon = (props) => (
  <RotatedIcon as={MenuOutlined} {...props} />
);
export const MenuIcon = (props) => <StyledIcon as={MenuOutlined} {...props} />;
export const CloseIcon = (props) => (
  <StyledIcon as={CloseOutlined} {...props} />
);
export const MemoryAnchor = (props) => (
  <StyledIcon as={Icon} component={MemoryAnchorSvg} {...props} />
);
export const LogoIcon = (props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      paddingTop: "10px",
    }}
  >
    <StyledIcon as={Icon} component={MemoryAnchorSvg} {...props} />
    <StyledIcon
      as={Icon}
      component={MemoryAnchorTitleSvg}
      style={{
        marginTop: "7px",
      }}
      {...props}
    />
  </div>
);

export const StyledIcon = styled.div`
  color: #fff;
  font-size: 30px;
`;

export const RotatedIcon = styled.div`
  color: #fff;
  font-size: 30px;
  transform: rotate(90deg);
  animation-name: rotate;
  animation-timing-function: linear;
  animation-duration: 250ms;
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }
`;

const MemoryAnchorTitleSvg = () => (
  <svg
    width="276"
    height="13"
    viewBox="0 0 276 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0 28.0301V10.6801H3.25L3.62 12.1601L3.8 12.3601L3.98 12.1601C4.34 11.6301 4.92 11.1901 5.71 10.8501C6.5 10.5101 7.28 10.3301 8.05 10.3301C9.34 10.3301 10.45 10.5901 11.4 11.1101C12.35 11.6301 13.06 12.3601 13.55 13.2801L14.47 12.1601C14.93 11.6201 15.56 11.1801 16.36 10.8401C17.16 10.5001 17.94 10.3301 18.7 10.3301C20.66 10.3301 22.19 10.9001 23.28 12.0501C24.37 13.2001 24.92 14.7101 24.92 16.6001V28.0401H21.3V16.9701C21.3 15.8801 21.04 15.0501 20.52 14.4801C20 13.9201 19.27 13.6301 18.33 13.6301C17.25 13.6301 16.31 14.0101 15.51 14.7701C14.71 15.5301 14.3 16.4501 14.26 17.5401V28.0301H10.64V16.9701C10.64 15.8801 10.38 15.0501 9.86 14.4801C9.34 13.9201 8.61 13.6301 7.67 13.6301C6.59 13.6301 5.65 14.0101 4.85 14.7701C4.06 15.5301 3.64 16.4501 3.6 17.5401V28.0301H0Z"
        fill="white"
      />
      <path
        d="M30.78 19.3701C30.78 16.7301 31.59 14.5601 33.2 12.8701C34.81 11.1801 36.84 10.3301 39.26 10.3301C40.74 10.3301 42.09 10.6901 43.32 11.4101C44.55 12.1301 45.54 13.1701 46.28 14.5301C47.02 15.8901 47.39 17.4401 47.39 19.1801L47.03 20.3001H34.4C34.4 21.6401 34.85 22.7801 35.76 23.7101C36.67 24.6301 37.88 25.1001 39.39 25.1001C40.46 25.1001 41.37 24.8601 42.11 24.3901C42.85 23.9201 43.37 23.2901 43.66 22.5301L46.76 24.0101C45.98 25.3701 44.95 26.4401 43.66 27.2301C42.37 28.0201 40.89 28.4201 39.19 28.4201C37.65 28.4201 36.25 28.0401 34.97 27.2901C33.69 26.5401 32.68 25.4701 31.92 24.0801C31.16 22.6701 30.78 21.1101 30.78 19.3701ZM34.66 17.0401H43.52C43.42 16.0801 42.97 15.2701 42.15 14.6201C41.33 13.9701 40.37 13.6401 39.27 13.6401C38.04 13.6401 37.05 13.9501 36.27 14.5801C35.49 15.2001 34.96 16.0201 34.66 17.0401Z"
        fill="white"
      />
      <path
        d="M53.3 28.0301V10.6801H56.55L56.92 12.1601L57.1 12.3601L57.28 12.1601C57.64 11.6301 58.22 11.1901 59.01 10.8501C59.8 10.5101 60.58 10.3301 61.35 10.3301C62.64 10.3301 63.75 10.5901 64.7 11.1101C65.65 11.6301 66.36 12.3601 66.85 13.2801L67.77 12.1601C68.23 11.6201 68.86 11.1801 69.66 10.8401C70.46 10.5001 71.24 10.3301 72 10.3301C73.96 10.3301 75.49 10.9001 76.58 12.0501C77.67 13.2001 78.22 14.7101 78.22 16.6001V28.0401H74.6V16.9701C74.6 15.8801 74.34 15.0501 73.82 14.4801C73.3 13.9201 72.57 13.6301 71.63 13.6301C70.55 13.6301 69.61 14.0101 68.81 14.7701C68.01 15.5301 67.6 16.4501 67.56 17.5401V28.0301H63.94V16.9701C63.94 15.8801 63.68 15.0501 63.16 14.4801C62.64 13.9201 61.91 13.6301 60.97 13.6301C59.89 13.6301 58.95 14.0101 58.15 14.7701C57.36 15.5301 56.94 16.4501 56.9 17.5401V28.0301H53.3Z"
        fill="white"
      />
      <path
        d="M85.33 24.0501C84.5 22.6601 84.08 21.0901 84.08 19.3701C84.08 17.6501 84.5 16.0801 85.33 14.6801C86.16 13.2801 87.26 12.2101 88.62 11.4601C89.98 10.7101 91.46 10.3301 93.06 10.3301C94.66 10.3301 96.14 10.7101 97.5 11.4601C98.86 12.2101 99.96 13.2901 100.79 14.6801C101.62 16.0801 102.04 17.6401 102.04 19.3701C102.04 21.1001 101.62 22.6601 100.79 24.0501C99.96 25.4401 98.86 26.5101 97.5 27.2701C96.14 28.0201 94.66 28.4001 93.06 28.4001C91.46 28.4001 89.98 28.0201 88.62 27.2701C87.26 26.5201 86.16 25.4401 85.33 24.0501ZM89.11 15.2701C88.17 16.3601 87.7 17.7201 87.7 19.3701C87.7 21.0201 88.17 22.3801 89.11 23.4601C90.05 24.5401 91.37 25.0801 93.06 25.0801C94.75 25.0801 96.07 24.5401 97.01 23.4601C97.95 22.3801 98.42 21.0201 98.42 19.3701C98.42 17.7201 97.95 16.3601 97.01 15.2701C96.07 14.1801 94.75 13.6401 93.06 13.6401C91.37 13.6401 90.05 14.1801 89.11 15.2701Z"
        fill="white"
      />
      <path
        d="M108.25 28.0298V10.6798H111.57L111.88 12.5398L112.06 12.7198L112.24 12.5398C112.79 11.8198 113.41 11.2698 114.13 10.8898C114.84 10.5098 115.58 10.3198 116.34 10.3198C116.64 10.3198 116.87 10.3198 117.01 10.3298C117.16 10.3398 117.33 10.3698 117.54 10.4198C117.75 10.4698 117.97 10.5598 118.21 10.6798V14.0098H115.97C114.88 14.0098 113.93 14.3898 113.13 15.1498C112.33 15.9098 111.91 16.8298 111.87 17.9198V28.0298H108.25Z"
        fill="white"
      />
      <path
        d="M121.7 10.6802H125.43L129.27 21.0902L133.14 10.6802H136.83L127.61 35.4202H123.92L127.54 25.7502L121.7 10.6802Z"
        fill="white"
      />
      <path
        d="M152.67 19.3698C152.67 18.0598 152.91 16.8398 153.38 15.6998C153.85 14.5598 154.49 13.6098 155.29 12.8298C156.09 12.0498 157.03 11.4398 158.11 10.9898C159.19 10.5498 160.32 10.3198 161.5 10.3198C162.42 10.3198 163.3 10.4898 164.17 10.8198C165.04 11.1598 165.75 11.5998 166.3 12.1498L166.48 12.3498L166.68 12.1498L167.04 10.6698H170.35V28.0198H167.04L166.68 26.5398L166.48 26.3598L166.3 26.5398C165.75 27.0898 165.04 27.5398 164.17 27.8698C163.3 28.1998 162.41 28.3698 161.5 28.3698C159.91 28.3698 158.44 27.9898 157.1 27.2398C155.76 26.4898 154.68 25.4198 153.88 24.0298C153.08 22.6698 152.67 21.1098 152.67 19.3698ZM157.72 15.2698C156.77 16.3598 156.29 17.7198 156.29 19.3698C156.29 21.0198 156.76 22.3798 157.72 23.4598C158.67 24.5398 159.99 25.0798 161.69 25.0798C163.24 25.0798 164.52 24.5298 165.53 23.4198C166.54 22.3098 167.05 20.9598 167.05 19.3698C167.05 17.7798 166.54 16.4198 165.53 15.3098C164.52 14.1998 163.24 13.6398 161.69 13.6398C160 13.6398 158.67 14.1798 157.72 15.2698Z"
        fill="white"
      />
      <path
        d="M177.58 28.0301V10.6801H180.83L181.2 12.1601L181.38 12.3601L181.56 12.1601C181.92 11.6301 182.5 11.1901 183.28 10.8501C184.07 10.5001 184.85 10.3301 185.62 10.3301C187.77 10.3301 189.47 10.9801 190.72 12.2801C191.96 13.5801 192.59 15.2701 192.59 17.3301V28.0301H188.97V17.3301C188.86 14.8701 187.63 13.6401 185.27 13.6401C184.19 13.6401 183.25 14.0201 182.45 14.7801C181.66 15.5401 181.24 16.4601 181.2 17.5501V28.0401H177.58V28.0301Z"
        fill="white"
      />
      <path
        d="M199.66 24.0501C198.83 22.6601 198.41 21.0901 198.41 19.3701C198.41 17.6501 198.83 16.0801 199.66 14.6801C200.49 13.2801 201.58 12.2101 202.93 11.4601C204.27 10.7101 205.72 10.3301 207.27 10.3301C208.96 10.3301 210.45 10.7301 211.74 11.5301C213.03 12.3301 214.05 13.4101 214.8 14.7601L211.92 16.6001C211.6 15.8001 211.03 15.1001 210.23 14.5201C209.43 13.9401 208.5 13.6501 207.46 13.6501C205.77 13.6501 204.44 14.2001 203.48 15.2901C202.52 16.3801 202.04 17.7501 202.04 19.3801C202.04 21.0101 202.52 22.3701 203.48 23.4601C204.44 24.5501 205.77 25.0901 207.46 25.0901C208.5 25.0901 209.44 24.8001 210.25 24.2201C211.07 23.6401 211.64 22.9401 211.96 22.1401L214.84 24.0001C214.08 25.3401 213.06 26.4201 211.76 27.2201C210.46 28.0201 208.97 28.4201 207.27 28.4201C205.72 28.4201 204.27 28.0401 202.93 27.2901C201.58 26.5201 200.49 25.4401 199.66 24.0501Z"
        fill="white"
      />
      <path
        d="M220.36 28.03V0H223.98V12.16L224.16 12.36L224.34 12.16C224.7 11.63 225.28 11.19 226.06 10.85C226.85 10.5 227.63 10.33 228.4 10.33C230.55 10.33 232.25 10.98 233.5 12.28C234.74 13.58 235.37 15.27 235.37 17.33V28.03H231.75V17.33C231.64 14.87 230.41 13.64 228.05 13.64C226.97 13.64 226.03 14.02 225.23 14.78C224.43 15.54 224.02 16.46 223.98 17.55V28.04H220.36V28.03Z"
        fill="white"
      />
      <path
        d="M242.43 24.0501C241.6 22.6601 241.18 21.0901 241.18 19.3701C241.18 17.6501 241.6 16.0801 242.43 14.6801C243.26 13.2801 244.36 12.2101 245.72 11.4601C247.08 10.7101 248.56 10.3301 250.16 10.3301C251.76 10.3301 253.24 10.7101 254.6 11.4601C255.96 12.2101 257.06 13.2901 257.89 14.6801C258.72 16.0701 259.14 17.6401 259.14 19.3701C259.14 21.1001 258.72 22.6601 257.89 24.0501C257.06 25.4401 255.96 26.5101 254.6 27.2701C253.24 28.0201 251.76 28.4001 250.16 28.4001C248.56 28.4001 247.08 28.0201 245.72 27.2701C244.36 26.5201 243.26 25.4401 242.43 24.0501ZM246.21 15.2701C245.27 16.3601 244.8 17.7201 244.8 19.3701C244.8 21.0201 245.27 22.3801 246.21 23.4601C247.15 24.5401 248.47 25.0801 250.16 25.0801C251.85 25.0801 253.17 24.5401 254.11 23.4601C255.05 22.3801 255.52 21.0201 255.52 19.3701C255.52 17.7201 255.05 16.3601 254.11 15.2701C253.17 14.1801 251.85 13.6401 250.16 13.6401C248.47 13.6401 247.15 14.1801 246.21 15.2701Z"
        fill="white"
      />
      <path
        d="M265.35 28.0298V10.6798H268.67L268.98 12.5398L269.16 12.7198L269.34 12.5398C269.89 11.8198 270.51 11.2698 271.23 10.8898C271.94 10.5098 272.68 10.3198 273.44 10.3198C273.74 10.3198 273.97 10.3198 274.11 10.3298C274.26 10.3398 274.43 10.3698 274.64 10.4198C274.85 10.4698 275.07 10.5598 275.31 10.6798V14.0098H273.07C271.98 14.0098 271.03 14.3898 270.23 15.1498C269.43 15.9098 269.01 16.8298 268.97 17.9198V28.0298H265.35Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="275.31" height="35.42" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const MemoryAnchorSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48.933"
    height="28.713"
    viewBox="0 0 48.933 28.713"
    className="icon"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_72"
          data-name="Rectangle 72"
          width="48.933"
          height="28.713"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <g id="icon" data-name="Group 2" clipPath="url(#clip-path)">
      <path
        id="Path_4"
        data-name="Path 4"
        d="M0,1.373a11,11,0,0,1,6.463,10.02v17.32h9.848V10.993A10.992,10.992,0,0,0,0,1.373Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M26.5,1.373a11,11,0,0,1,6.463,10.02v17.32h9.848V10.993A10.992,10.992,0,0,0,26.5,1.373Z"
        transform="translate(-10.189 0)"
        fill="currentColor"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M53,1.373a11,11,0,0,1,6.463,10.02v17.32h9.848V10.993A10.992,10.992,0,0,0,53,1.373Z"
        transform="translate(-20.378 0)"
        fill="currentColor"
      />
    </g>
  </svg>
);
