import { wrap, wrapRefresh } from "./Wrappers.js";
import GetRecords from "../routes/GetRecords";
import EmptyCemetery from "../routes/EmptyCemetery";
import IndexDocuments from "../routes/IndexDocuments";

const getRecords = wrap(GetRecords);
const emptyCemetery = wrapRefresh(EmptyCemetery);
const indexDocuments = wrapRefresh(IndexDocuments);

function chunkArrayInGroups(arr, size) {
  var myArray = [];
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

function reIndex() {
  // Empty Cemetery Typesense Collections
  emptyCemetery()
    .then(async () => {
      // Get all indexable records
      let burials, pois, monuments;
      try {
        burials = await getAllRecords("burial");
        pois = await getAllRecords("poi");
        monuments = await getAllRecords("monument");
      } catch (err) {
        console.error(err);
      }

      let allPromises = [
        ...indexAllDocuments("burial", burials),
        ...indexAllDocuments("poi", pois),
        ...indexAllDocuments("monument", monuments),
      ];
      Promise.all(allPromises)
        .then(() => {
          console.log("indexed");
        })
        .catch(console.error);
    })
    .catch((err) => {
      console.log(err);
    });
}

function indexAllDocuments(type, records) {
  let promises = [];
  let chunks = chunkArrayInGroups(records, 1000);
  for (let chunk of chunks) {
    promises.push(indexDocuments(type, chunk));
  }
  return promises;
}

function delay(s) {
  return new Promise((resolve) => setTimeout(resolve, s * 1000));
}

async function getAllRecords(type) {
  let records = [];
  let scanning = true;
  let startKey = null;
  while (scanning) {
    let downloaded = false;
    let timeout = 5;
    while (!downloaded) {
      try {
        let result = await getRecords(type, startKey);
        downloaded = true;
        if (result.data.Items.length < 100) {
          scanning = false;
        } else {
          records.push(...result.data.Items);
          startKey = result.data.Items.pop().SK;
        }
      } catch (err) {
        console.error(err);
        console.log("failed, waiting", timeout, "seconds to retry");
        await delay(timeout);
        timeout = parseInt(timeout * 1.5);
      }

      // await getRecords(type, startKey)
      //   .then((result) => {
      //     downloaded = true;
      //     if (result.data.Items.length < 100) {
      //       scanning = false;
      //     } else {
      //       records.push(...result.data.Items);
      //       startKey = result.data.Items.pop().SK;
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     console.log(
      //       "chunk",
      //       chunksUploaded + 1,
      //       "failed, waiting",
      //       timeout,
      //       "seconds to retry"
      //     );
      //     await delay(timeout);
      //     timeout = parseInt(timeout * 1.5);
      //   });
    }
  }
  return records;
}

export { reIndex };
