class Controller {
  static baseUrl =
    // "https://rgmwnl02zd.execute-api.eu-west-2.amazonaws.com/Prod";
    "https://kum1l18mh1.execute-api.ca-central-1.amazonaws.com/Prod";
  static idToken = null;
  // static email = null;
  // static currentCemetery;

  // isLoggedIn() {
  //   return Controller.idToken !== null;
  // }
  // setCemetery(cemetery) {
  //   Controller.currentCemetery = cemetery;
  // }
}

export default Controller;
