import Request from "../utility/Request.js";

class PostRecord extends Request {
  constructor() {
    super("post", ``);
  }
  sendRequest() {
    const args = arguments[0];
    const cemetery = args[0];
    const Item = args[1];
    const type = args[2];

    this.url = `record/${cemetery}/${type}`;
    return this.sendRequestPromise(Item);
  }
}

export default PostRecord;
