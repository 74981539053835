import Request from "../utility/Request.js";

class UpdateCemetery extends Request {
  constructor() {
    super("put", ``);
  }
  sendRequest() {
    const args = arguments[0];
    const cemetery = args[0];
    const Item = args[1];

    this.url = `update-cemetery/${cemetery}`;
    return this.sendRequestPromise(Item);
  }
}

export default UpdateCemetery;
