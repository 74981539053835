import { StyledDiv } from "./StyledDiv.js";
import { SecondaryButton } from "./SecondaryButton.js";
import { PrimaryButton } from "./PrimaryButton.js";

export const SaveOrDiscard = ({ save, discard, disabled }) => {
  return (
    <StyledDiv
      style={{
        gridTemplateColumns: "1fr 1fr",
        justifyItems: "center",
        backgroundColor: "#3f5569",
      }}
    >
      <SecondaryButton
        style={{ height: "56px" }}
        disabled={disabled}
        onClick={save}
      >
        Save Changes
      </SecondaryButton>
      <PrimaryButton
        style={{ height: "56px" }}
        disabled={disabled}
        onClick={discard}
      >
        Discard
        <br />
        Changes
      </PrimaryButton>
    </StyledDiv>
  );
};
