import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../../SDK/context";
import { Link } from "react-router-dom";
import Search from "../../components/Search";
import FOV from "../../SDK/src/main";
import { Typography } from "antd";

import "./SiteOverview.css";

import { CenteredDiv } from "../../components/AngelStyledComponents/CenteredDiv.js";
import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout.js";
import { PageTitle } from "../../components/AngelStyledComponents/PageTitle.js";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent.js";
import { TertiaryButton } from "../../components/AngelStyledComponents/TertiaryButton.js";
import { RecordOptions } from "../../components/AngelStyledComponents/RecordOptions.js";
import { ToggleButton } from "../../components/AngelStyledComponents/ToggleButton.js";
import { PrimaryButton } from "../../components/AngelStyledComponents/PrimaryButton.js";
import { SecondaryButton } from "../../components/AngelStyledComponents/SecondaryButton.js";
import { EditCloseButton } from "../../components/AngelStyledComponents/EditCloseButton";

import placeholder from "../../assets/images/placeholder-cemetery-burial.png";
import MapWithMarker from "../../components/catmaps/MapWithMarker.js";

const SiteOverview = () => {
  const { cemetery } = useParams();
  const { state } = useContext(Context);
  const [overview, setOverview] = useState({});
  const [quickRecord, setQuickRecord] = useState({});

  useEffect(() => {
    FOV.api
      .getCemetery(cemetery)
      .then((result) => {
        setOverview(result.data.Item);
      })
      .catch(console.error);
  }, [cemetery]);

  const { Title } = Typography;

  // const profile = props.params.profile;
  return state.cemeteries.includes(cemetery) ? (
    <StyledLayout>
      <FixedContent>
        <PageTitle>Records Overview</PageTitle>
        <Title style={{ color: "white" }} level={2}>
          {overview.Name}
        </Title>
        {/* TODO: */}
        {/* Site Map */}
        {/* Burial Search */}
        {/* Record Search */}
        {/* Quick Look */}
        {/* Location */}
        {/* Map View */}
        {/* Record Data*/}
        {/* Edit Save */}
        <img
          alt=""
          src={overview.Map || placeholder}
          style={{ height: "10rem" }}
        />
        <h4 style={{ color: "white" }}>{`Long/Lat Location: (${(overview.MapALng +
          overview.MapBLng +
          overview.MapCLng +
          overview.MapDLng) /
          4
          },${(overview.MapALat +
            overview.MapBLat +
            overview.MapCLat +
            overview.MapDLat) /
          4
          })`}</h4>
        <Link
          to={`/${state.email}/${cemetery}/record/${quickRecord.SK ? quickRecord.SK.split("_")[0] : ""
            }/${quickRecord.Id}`}
        >
          {/* <Quicklook item={quickRecord} imageFields={["Photo"]} /> */}
        </Link>
        <div style={{ width: "20rem", margin: "1rem" }}>
          <h2 style={{ margin: 0 }}>SEARCH FOR BURIAL</h2>
          <h4 style={{ color: "white" }}>(Search by Forename, Surname).</h4>
          <Search
            type="burial"
            queryBy="forename,surname"
            setResult={setQuickRecord}
          />
        </div>
        <div style={{ width: "20rem", margin: "1rem" }}>
          <h2 style={{ margin: 0 }}>SEARCH FOR RECORD</h2>
          <h4 style={{ color: "white" }}>(Search by Monument Name).</h4>
          <Search type="monument" queryBy="name" setResult={setQuickRecord} />
        </div>
        {Object.entries(quickRecord).length > 0 && (
          <QuicklookBanana
            data={quickRecord}
            link={`/${state.email}/${cemetery}/record/${quickRecord.SK ? quickRecord.SK.split("_")[0] : ""
              }/${quickRecord.Id}`}
            close={() => setQuickRecord({})}
          />
        )}
        <ToggleButton buttonText="Add new record">
          <RecordOptions email={state.email} cemetery={cemetery}>
            Add single record or bulk upload
          </RecordOptions>
        </ToggleButton>
        <CenteredDiv>
          <TertiaryButton className="siteOverview__tertiaryButton">
            <Link to={`/${state.email}/${cemetery}/view-all`}>
              view all records
            </Link>
          </TertiaryButton>
        </CenteredDiv>
        <CenteredDiv>
          <TertiaryButton className="siteOverview__tertiaryButton">
            <Link to={`/${state.email}/${cemetery}/tours`}>Tours Overview</Link>
          </TertiaryButton>
        </CenteredDiv>
        <CenteredDiv>
          <TertiaryButton className="siteOverview__tertiaryButton">
            <Link to={`/${state.email}/${cemetery}/organizations`}>
              Organization Spotlight
            </Link>
          </TertiaryButton>
        </CenteredDiv>
        {/* <CenteredDiv>
          <TertiaryButton>
            <Link to={`/`}>Site Manager</Link>
          </TertiaryButton>
        </CenteredDiv> */}
      </FixedContent>
    </StyledLayout>
  ) : (
    <div>You are not authorised for this cemetery {cemetery}</div>
  );
};

const QuicklookBanana = (props) => {
  const { data, close, edit, link } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <div
      style={{
        maxWidth: "720px",
        width: "100%",
        backgroundColor: "#2C3B4A",
      }}
    >
      <h2
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          maxWidth: "720px",
          width: "100%",
          backgroundColor: "#475F75",
          height: "76px",
          textTransform: "uppercase",
          margin: 0,
          padding: "0 20px",
          boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.2)",
        }}
      >
        Quicklook
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "720px",
          width: "100%",
          height: "76px",
          textTransform: "uppercase",
          margin: 0,
          padding: "0 20px",
          backgroundColor: "#3F5569",
          boxShadow:
            "inset 0px 3px 6px rgba(0, 0, 0, 0.2), 3px 3px 6px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h2>Location</h2>
        <EditCloseButton
          className={open ? "close-button" : "edit-button"}
          onClick={() => setOpen(!open)}
        >
          {open ? "Close" : "Show on map"}
        </EditCloseButton>
      </div>
      {open && (
        <div
          style={{
            height: "250px",
            width: "90%",
            margin: "20px auto",
            color: "white",
            textAlign: "center",
          }}
        >
          <MapWithMarker lat={data.Lat} lng={data.Lng} />
        </div>
      )}
      <div style={{ height: "350px", width: "100%", color: "white", overflow: "scroll" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/* {data.Media && (
            <img
              alt=""
              style={{ height: "240px", minWidth: "40px", margin: "15px" }}
              src={data.Media}
            />
          )} */}
          {data.Photo && (
            <img
              alt=""
              style={{ height: "240px", minWidth: "auto", margin: "15px" }}
              src={data.Photo}
            />
          )}
          <div style={{ color: "white", minWidth: "350px", }}>
            <p style={{ padding: "15px", color: "white" }}>
              {Object.keys(data).filter(key => (
                ["Name", "Description", "Id", "Country", "Regiment", "Trade", "Nationality"].includes(key)
              )).map(key => {
                return (<><b>{key}</b> {data[key]}<br /></>)
              })}

              {data.Weblinks && (
                <>
                  <b>Web links:</b>{" "}
                  {
                    data.Weblinks.split(';').map(link => (
                      <><a target="_blank" rel="noreferrer" href={link}>
                        {link}
                      </a><br /></>
                    ))
                  }

                </>
              )}
            </p>
          </div>
        </div>
        <h3 style={{ color: "white", padding: "0 20px" }}>
          <b>
            {data.Rank} {data.Forename} {data.Surname}
          </b>
        </h3>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          height: "76px",
          backgroundColor: "#3F5569",
        }}
      >
        <Link to={link}>
          <SecondaryButton onClick={edit}>Edit</SecondaryButton>
        </Link>
        <PrimaryButton onClick={close}>Close</PrimaryButton>
      </div>
    </div>
  );
};
export default SiteOverview;
