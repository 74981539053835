import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "@fontsource/libre-franklin/300.css";
import "@fontsource/libre-franklin/400.css";
import "@fontsource/libre-franklin/500.css";
import "@fontsource/libre-franklin/600.css";
import "@fontsource/libre-franklin/700.css";
import "./index.css";
import "antd/dist/antd.css";
import "antd/dist/antd.less";
ReactDOM.render(
    <App />,
  document.getElementById("root")
);
