import styled from "styled-components";

export const FormToggleButton = styled.button`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5em;
  background-color: #0000;
  border: none;
  &.inset-shadow {
    box-shadow: inset 0px 15px 10px -10px rgba(0, 0, 0, 0.16);
    background-color: #3f5569;
  }
`;
