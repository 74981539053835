import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FOV from "../../SDK/src/main.js";
import { Context } from "../../SDK/context";
import placeholderMonumentTour from "../../assets/images/placeholder-monument-tour.png";
import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout";
import { PageTitle } from "../../components/AngelStyledComponents/PageTitle";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent";
import { TourButton } from "../../components/AngelStyledComponents/TourButton.js";
import { CenteredDiv } from "../../components/AngelStyledComponents/CenteredDiv";
import { TertiaryButton } from "../../components/AngelStyledComponents/TertiaryButton";
import { useParams } from "react-router-dom";

function OrganizationsList({
  assignedOrganizations,
  organizations,
  editOrganization,
  assignOrganization,
  email,
}) {
  return (
    <>
      {organizations.map((o) => {
        return (
          <>
            <TourButton
              assignable={false}
              editable={o.Owner.toLowerCase() === email?.toLowerCase()}
              src={o.Logo || placeholderMonumentTour}
              key={o.SK}
              title={o.Name}
              onClick={() => editOrganization(o.SK)}
              onAssign={() => assignOrganization(o.SK)}
            />
          </>
        );
      })}
    </>
  );
}

function OrganizationsView() {
  const { cemetery } = useParams();
  const { state } = useContext(Context);
  const history = useHistory();
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const getCemetery = async () => {
      const result = await FOV.api.getCemetery(cemetery);
      if (result.data.Item?.Organizations) {
        await FOV.api
          .bulkGetOrganizations(result.data.Item.Organizations.slice(0, 100))
          .then((result) => {
            setOrganizations(
              result.data.Responses[Object.keys(result.data.Responses)[0]].sort(
                (a, b) => a.SK.localeCompare(b.SK)
              )
            );
          })
          .catch(console.error);
      }
    };
    getCemetery();
  }, []);
  const editOrganization = (id) => {
    history.push(`/${state.email}/organization/${id}`);
  };
  return (
    <StyledLayout>
      <FixedContent>
        <PageTitle>Organization Spotlight</PageTitle>
        <OrganizationsList
          organizations={organizations}
          editOrganization={editOrganization}
          email={state.email}
        />
        <CenteredDiv>
          <TertiaryButton style={{ width: "20em" }}>
            <Link to={`/${state.email}/${cemetery}/all-organizations/`}>
              View All Organizations
            </Link>
          </TertiaryButton>
        </CenteredDiv>
      </FixedContent>
    </StyledLayout>
  );
}

export default OrganizationsView;
