import React, { useContext, useEffect, useState } from "react";

import { Context } from "../../SDK/context";
import { useHistory, useParams } from "react-router-dom";
import AutoOverview from "../../SDK/components/AutoOverview";
import NewForm from "../../components/NewForm";

import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout";

import { PageTitle } from "../../components/AngelStyledComponents/PageTitle";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent";

import moment from "moment";
import { StyledDiv } from "../../components/AngelStyledComponents/StyledDiv";
import { SecondaryButton } from "../../components/AngelStyledComponents/SecondaryButton";
import { PrimaryButton } from "../../components/AngelStyledComponents/PrimaryButton";

const fieldMapping = [
  { name: "Active", label: "Site Status", type: "boolean", default: true },
  { name: "Name", label: "Site Name", type: "string", required: true },

  { name: "Country", label: "Country", type: "string" },
  { name: "City", label: "City", type: "string" },
  { name: "StateProvince", label: "State / Province", type: "string" },
  { name: "Operator", label: "Operator", type: "string" },
  { name: "Website", label: "Website", type: "string" },
  {
    name: "PrimaryAccountHolder",
    label: "Primary Account Holder",
    type: "string",
  },
  { name: "Casualties", label: "Casualties", type: "int" },
  { name: "Description", label: "Site Description", type: "string" },
  { name: "Highlights", label: "Cemetery Highlights", type: "string" },

  {
    name: "Photo",
    label: "Site Photo",
    type: "image",
  },
  {
    name: "Logo",
    label: "Organization Logo",
    type: "image",
  },
  {
    name: "Map",
    label: "Map Image",
    type: "image",
  },
  {
    name: "Media",
    label: "Site Gallery",
    type: "media",
  },
  { name: "Accessibility", label: "Accessibility", type: "string" },
];
const pictureFields = fieldMapping
  .filter((field) => field.type === "image")
  .map((field) => field.name);

export const SiteLinks = ({ email, cemeteryID }) => {
  const history = useHistory();
  function navigateToMapEditor() {
    history.push(`/${email}/${cemeteryID}/map`);
  }
  function navigateToPathingEditor() {
    history.push(`/${email}/${cemeteryID}/pathing`);
  }
  return (
    <StyledDiv
      style={{
        gridTemplateColumns: "1fr 1fr",
        justifyItems: "center",
        backgroundColor: "#475f75",
      }}
    >
      <SecondaryButton style={{ height: "56px" }} onClick={navigateToMapEditor}>
        Map Editor
      </SecondaryButton>
      <SecondaryButton
        style={{ height: "56px" }}
        onClick={navigateToPathingEditor}
      >
        Pathing Editor
      </SecondaryButton>
    </StyledDiv>
  );
};

const SiteEditor = () => {
  const { cemetery } = useParams();
  const { state } = useContext(Context);
  const [originalItem, setOriginalItem] = useState({});
  const { getOverview } = useContext(Context);

  useEffect(() => {
    getOverview(cemetery);
  }, []);

  useEffect(() => {
    if (state.overview.SK === cemetery) {
      let newForm = JSON.parse(JSON.stringify(state.overview));
      newForm.DoB = moment(newForm.DoB);
      setOriginalItem(newForm);
    }
  }, [state.overview, cemetery]);

  return state.cemeteries.includes(cemetery) ? (
    <StyledLayout>
      <FixedContent>
        <PageTitle>Site Editor</PageTitle>
        <AutoOverview id={cemetery} />
        <NewForm
          style={{ maxWidth: "720px" }}
          originalItem={originalItem}
          fieldMapping={fieldMapping}
          pictureFields={pictureFields}
          type="cemetery"
        />
        <SiteLinks email={state.email} cemeteryID={cemetery} />
      </FixedContent>
    </StyledLayout>
  ) : (
    <StyledLayout>
      <FixedContent>
        <PageTitle>Site Editor</PageTitle>
      </FixedContent>
    </StyledLayout>
  );
};

export default SiteEditor;
