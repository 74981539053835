import { Form, Checkbox } from "antd";
import { StyledDiv } from "./StyledDiv.js";
import { ConditionalWrapper } from "./ConditionalWrapper.js";

export const SiteActive = (props) => {
  const { label, value, onChange, onClick, formProps } = props;
  return (
    <StyledDiv
      style={{
        padding: "0",
        gridTemplateColumns: "1fr 1fr",
        alignContent: "stretch",
      }}
    >
      <h2
        style={{
          color: "#fff",
          justifySelf: "start",
          margin: "0 20px",
          boxSizing: "border-box",
          textAlign: "start",
        }}
      >
        {label}
      </h2>
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: `${value ? "#3F5569" : "#2C3B4A"}`,
          boxShadow: "inset 3px 3px 6px rgba(0, 0, 0, 0.2)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ConditionalWrapper
          condition={formProps}
          wrapper={(children) => (
            <Form.Item valuePropName="checked" {...formProps}>
              {children}
            </Form.Item>
          )}
        >
          <Checkbox
            style={{
              color: "#fff",
              marginTop: "auto",
              marginBottom: "auto",
            }}
            {...{ onChange }}
            {...{ onClick }}
          >
            Active
          </Checkbox>
        </ConditionalWrapper>
      </div>
    </StyledDiv>
  );
};
