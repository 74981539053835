import Request from "../utility/Request.js";

class PostBurial extends Request {
  constructor() {
    super("post", `create-cemetery`);
  }
  sendRequest() {
    const args = arguments[0];
    const name = args[0];

    return this.sendRequestPromise({ name });
  }
}

export default PostBurial;
