import React from "react";

import { Form } from "antd";

import { LightDiv } from "./LightDiv.js";
import { InputLightLabel } from "./InputLightLabel.js";
import { ConditionalWrapper } from "./ConditionalWrapper.js";
import { StyledInputLight } from "./StyledInputLight.js";

export const MultipleInputs = (props) => {
  const { fields } = props;
  return (
    <LightDiv
      key={"multipleInputs"}
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 3fr",
        gridRowGap: "8px",
        alignItems: "center",
        alignContent: "center",
        boxShadow: "inset 0 15px 10px -10px rgb(0, 0, 0, 0.16)",
        height: "100%",
      }}
    >
      {Object.keys(fields).map((index) => (
        <React.Fragment key={index}>
          <InputLightLabel
            style={{
              margin: "0px 5px 0px 0px",
            }}
          >
            {fields[index].label}
          </InputLightLabel>

          <ConditionalWrapper
            style={{ width: "inherit" }}
            condition={fields[index].formProps}
            wrapper={(children) => (
              <Form.Item {...fields[index].formProps}>{children}</Form.Item>
            )}
          >
            <StyledInputLight onChange={fields[index].callback} allowClear />
          </ConditionalWrapper>
        </React.Fragment>
      ))}
    </LightDiv>
  );
};
