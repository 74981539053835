import { FixedHeader } from "./FixedHeader.js";
import { RotatedMenuIcon, RollbackIcon, LogoIcon, MenuIcon } from "./Icons.js";
import { useHistory } from "react-router-dom";

export const StyledHeader = ({ isHamburgerOpen, toggleHamburger }) => {
  const history = useHistory();
  return (
    <FixedHeader isHamburgerOpen={isHamburgerOpen}>
      {isHamburgerOpen ? (
        <RotatedMenuIcon onClick={toggleHamburger} />
      ) : (
        <MenuIcon onClick={toggleHamburger} />
      )}
      <LogoIcon
        onClick={() => {
          // history.push("/");
          window.location.replace(`/`)
        }}
      />
      <RollbackIcon
        onClick={() => {
          history.goBack();
        }}
      />
    </FixedHeader>
  );
};
