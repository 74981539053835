import React, { useState, useEffect } from "react";
import "./Thumbnail.css";

import { ImageUpload } from "../AngelStyledComponents/ImageUpload";
import { ImageLabel } from "../AngelStyledComponents/ImageLabel";

import placeholderCemeteryBurial from "../../assets/images/placeholder-cemetery-burial.png";

const Thumbnail = ({
  image,
  form,
  field,
  // setForm,
  mediaUploads,
  setMediaUploads,
  addMediaUpload,
  randomQuery,
  setRandomQuery,
  isMedia,
  type,
  loaded,
}) => {
  const [src, setSrc] = useState(placeholderCemeteryBurial);
  useEffect(() => {
    let newImage;
    if (isMedia) {
      newImage = image;
    } else {
      newImage = form.getFieldsValue()[field.name];
    }
    if (newImage) {
      let newSrc = `${newImage}?${randomQuery}`;
      if (mediaUploads[newImage] && mediaUploads[newImage].tempSrc) {
        newSrc = mediaUploads[newImage].tempSrc;
      }
      setSrc(newSrc);
    } else {
      setSrc(placeholderCemeteryBurial);
    }
  }, [loaded, mediaUploads, form, image, field.name, isMedia, randomQuery]);

  function onClick() {
    const mapping = mediaUploads[image];
    if (mapping) {
      if (isMedia) {
        setMediaUploads({
          ...mediaUploads,
          [image]: undefined,
        });
      } else {
        setMediaUploads({
          ...mediaUploads,
          [form[field.name]]: undefined,
        });
      }
    }
    let values = form.getFieldsValue();
    if (isMedia) {
      form.setFieldsValue({
        ...values,
        Media: values.Media
          ? values.Media.split(";")
              .filter((a) => a !== image)
              .join(";")
          : "",
      });
      // setForm({
      //   ...form,
      //   Media: form.Media
      //     ? form.Media.split(";")
      //         .filter((a) => a !== image)
      //         .join(";")
      //     : "",
      // });
    } else {
      // setForm({ ...form, [field.name]: undefined });
      form.setFieldsValue({
        ...values,
        [field.name]: undefined,
      });
    }

    setSrc();
  }

  function onChange(event) {
    const file = event.target.files[0];
    if (file) {
      addMediaUpload(file, type, field.name, file.name);
    }
  }

  return (
    <ImageLabel
      label={field.label}
      src={src || placeholderCemeteryBurial}
      style={{ width: "100%" }}
      map={field.name === "Map"}
    >
      <ImageUpload
        fileName={src !== placeholderCemeteryBurial ? src : ""}
        src={src || placeholderCemeteryBurial}
        onClick={onClick}
        onChange={onChange}
        setRandomQuery={setRandomQuery}
      />
    </ImageLabel>
  );
};

export default Thumbnail;
