import { Form, Checkbox } from "antd";
import { StyledDiv } from "./StyledDiv.js";
import { ConditionalWrapper } from "./ConditionalWrapper.js";

export const CheckboxLabel = (props) => {
  const { label, value, onChange, formProps } = props;
  return (
    <StyledDiv
      style={{
        gridTemplateColumns: "1fr 1fr",
      }}
    >
      <h2>{label}</h2>
      <ConditionalWrapper
        condition={formProps}
        wrapper={(children) => <Form.Item {...formProps}>{children}</Form.Item>}
      >
        <Checkbox
          style={{
            color: "#fff",
            marginTop: "auto",
            marginBottom: "auto",
          }}
          checked={value}
          {...{ onChange }}
        >
          Checkbox
        </Checkbox>
      </ConditionalWrapper>
    </StyledDiv>
  );
};
