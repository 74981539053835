import Request from "../utility/Request.js";
import { convertAndCompress } from "../utility/ImageConversion.js";

class UploadMedia extends Request {
  constructor() {
    super("put", ``, false, {}, false);
  }
  async sendRequest() {
    const args = arguments[0];
    let file = args[0];
    let type = args[1];
    const url = args[2];

    let size = 10000;
    if (type === "cemetery") {
      size = 50000;
    }
    try {
      file = await convertAndCompress(file, size);
    } catch (err) {
      console.log(err);
      return new Promise((resolve, reject) => {
        reject(err);
      });
    }

    this.url = url;
    return this.sendRequestPromise(file);
  }
}

export default UploadMedia;
