import { StyledDiv } from "./StyledDiv.js";
import { StyledDatePicker } from "./StyledDatePicker.js";
import moment from "moment";
import React from "react";

const dateFormat = "MM,DD,YYYY";

export const DateLabel = (props) => {
  const { label, name, onChange, loaded, form } = props;
  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    if (loaded) {
      const values = form.getFieldsValue();
      setValue(moment(values[name] || ""));
    }
  }, [loaded, form, name]);
  return (
    <StyledDiv>
      <div>
        <h2
          style={{
            minWidth: "150px",
            margin: "0",
          }}
        >
          {label}
        </h2>
        <h3
          style={{
            color: "white",
            textAlign: "left",
          }}
        >
          (MM,DD,YYYY)
        </h3>
      </div>
      <StyledDatePicker
        value={value}
        onChange={(date, dateString) => {
          onChange(date, dateString);
          setValue(date);
        }}
        defaultPickerValue={moment("01,01,2000", dateFormat)}
        format={dateFormat}
      />
    </StyledDiv>
  );
};
