import { StyledDiv } from "./StyledDiv.js";
import { ConditionalWrapper } from "./ConditionalWrapper.js";
import { StyledInputDark } from "./StyledInputDark.js";
import { Form } from "antd";

export const WideInput = (props) => {
  const { formProps, ...rest } = props;

  function setReadOnly(event) {
    event.target.removeAttribute("readonly");
  }

  return (
    <StyledDiv
      style={{
        gridTemplateColumns: "1fr",
      }}
    >
      <ConditionalWrapper
        condition={formProps}
        wrapper={(children) => (
          <Form.Item
            style={{
              width: "inherit",
            }}
            {...formProps}
          >
            {children}
          </Form.Item>
        )}
      >
        <StyledInputDark onFocus={setReadOnly} allowClear {...rest} />
      </ConditionalWrapper>
    </StyledDiv>
  );
};
