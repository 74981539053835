import React from "react";

import { Form } from "antd";

import { StyledDiv } from "./StyledDiv.js";
import { ConditionalWrapper } from "./ConditionalWrapper.js";
import { StyledInputDark } from "./StyledInputDark.js";

export const InputLabel = (props) => {
  const { label, value, cssContainer, formProps, ...rest } = props;
  return (
    <StyledDiv
      style={{
        display: "flex",
        // minHeight: "76px",
        ...cssContainer,
      }}
    >
      <h2
        style={{
          margin: 0,
          minWidth: "150px",
          maxWidth: "150px",
          wordWrap: "break-word",
        }}
      >
        {label}
      </h2>
      <ConditionalWrapper
        condition={formProps}
        wrapper={(children) => (
          <Form.Item
            style={{
              width: "inherit",
            }}
            {...formProps}
          >
            {children}
          </Form.Item>
        )}
      >
        <StyledInputDark value allowClear {...rest} />
      </ConditionalWrapper>
    </StyledDiv>
  );
};
