import styled from "styled-components";
import { Layout } from "antd";
const { Content } = Layout;

export const FixedContent = styled(Content)`
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 0;
  margin-top: 64px !important;
`;
