import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import { BulkUploadButton } from "../../components/AngelStyledComponents/BulkUploadButton";
import { StyledLayout } from "../../components/AngelStyledComponents/StyledLayout.js";

import { CenteredDiv } from "../../components/AngelStyledComponents/CenteredDiv.js";
import { TertiaryButton } from "../../components/AngelStyledComponents/TertiaryButton.js";
import { Link } from "react-router-dom";
import { PageTitle } from "../../components/AngelStyledComponents/PageTitle.js";
import { FixedContent } from "../../components/AngelStyledComponents/FixedContent.js";

import { Context } from "../../SDK/context";

import "./BulkUpload.css";
const BulkUpload = () => {
  const { state } = useContext(Context);
  const { cemetery } = useParams();
  return (
    <StyledLayout>
      <FixedContent>
        <PageTitle fullSize>bulk upload records</PageTitle>
        <BulkUploadButton
          cemetery={cemetery}
          buttonText="UPLOAD BURIALS ZIP"
          type="burial"
        ></BulkUploadButton>
        <BulkUploadButton
          cemetery={cemetery}
          buttonText="UPLOAD MONUMENTS ZIP"
          type="monument"
        ></BulkUploadButton>
      </FixedContent>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.25)",
          padding: "10px 0",
        }}
      >
        <p style={{ margin: "0px 10px 0px 10px", color: "white" }}>
          Having trouble? Follow our formatting and upload guide
        </p>
        <TertiaryButton style={{ marginLeft: "5px" }}>
          <Link to={`/${state.email}/${cemetery}/upload/guide`}>
            UPLOAD GUIDE
          </Link>
        </TertiaryButton>
      </div>
      <CenteredDiv fullSize>
        <TertiaryButton style={{ width: "auto" }}>
          <Link to={`/${state.email}/${cemetery}`}>BACK TO SITE OVERVIEW</Link>
        </TertiaryButton>
      </CenteredDiv>
    </StyledLayout>
  );
};

export default BulkUpload;
