import React from "react";
// import MapboxWorker from "mapbox-gl/dist/mapbox-gl-csp-worker"; // Load worker code separately with worker-loader
import ReactMapboxGl, {
  Layer,
  Feature,
  Popup,
  MapContext,
} from "react-mapbox-gl";
// import { RiDeleteBin6Fill } from "react-icons/ri";
import { DeleteOutlined } from "@ant-design/icons";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import { PathingControls, ControlsAgenda } from "./MapControls";
import { Mode } from "./Mode";
import JumpTo from "./JumpTo";
import { ImageLayer } from "./MapWithImage";

// MapboxGl.workerClass = MapboxWorker;

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
  logoPosition: "bottom-right",
});

const PathEditor = (props) => {
  const {
    containerStyle,
    map,
    onMapChange,
    location,
    imageCoord,
    imageUrl,
    imageOpacity,
    pathFile,
    setPathFile,
    lastNode,
    setLastNode,
  } = props;
  //const [originalMap, setOriginalMap] = React.useState(parseMapObj(map));

  // React.useEffect(() => {
  //   setOriginalMap(parseMapObj(map));
  // }, [map]);

  return (
    <Mapbox
      //eslint-disable-next-line
      style="mapbox://styles/mapbox/dark-v10"
      containerStyle={containerStyle}
    >
      {/* <PathMap {...{ onMapChange }} map={originalMap} discard={props.discard} /> */}
      <ImageLayer
        coordinates={imageCoord}
        url={imageUrl}
        opacity={imageOpacity}
      />
      <PathMap
        graph={pathFile}
        setGraph={setPathFile}
        lastNode={lastNode}
        setLastNode={setLastNode}
        {...{ onMapChange }}
        map={map}
      />
      <JumpTo {...{ location }} />
      <ControlsAgenda />
    </Mapbox>
  );
};

const circleStyles = {
  "circle-color": "#FFFFFF",
  "circle-radius": ["interpolate", ["linear"], ["zoom"], 14, 2, 18, 8],
  "circle-stroke-width": ["interpolate", ["linear"], ["zoom"], 14, 1, 18, 3],
  "circle-stroke-color": [
    "case",
    ["boolean", ["get", "selected"], true],
    "#FF7F1D",
    "#FFFFFF",
  ],
};

const lineLayout = {
  "line-cap": "round",
  "line-join": "round",
};

const linePaint = {
  "line-color": [
    "case",
    ["boolean", ["get", "selected"], true],
    "#FF2F0D",
    "#FF7F1D",
  ],
  "line-width": 3,
};

export const parseMapObj = (source) => {
  return new Map(source.map((entry) => [entry.key, entry.value]));
};

export const stringifyMapObj = (map) => {
  return JSON.stringify(mapToArrayObj(map));
};
export const mapToArrayObj = (map) => {
  return [...map.entries()].map((entry) => ({
    key: entry[0],
    value: entry[1],
  }));
};

export const parseMap = (source) => {
  return new Map(JSON.parse(source));
};

export const stringifyMap = (map) => {
  return JSON.stringify([...map.entries()]);
};

const PathMap = (props) => {
  const map = React.useContext(MapContext);

  const [mode, setMode] = React.useState(Mode.Default);
  //let graph = props.map;
  const { graph, setGraph, lastNode, setLastNode } = props;
  const [lastLine, setLastLine] = React.useState();

  // const [lastNode, setLastNode] = React.useState();
  const hover = React.useRef(false);
  const [dragging, setDragging] = React.useState(false);

  const addPoint = (coordinates) => {
    const newGraph = graph;
    const key = uuid();
    newGraph.set(key, {
      coordinates,
      adjacent: lastNode ? [lastNode] : [],
    });

    if (lastNode) {
      addToAdjacencyArray(newGraph, lastNode, key);
      //setLines([...lines, [lastNode, key]]);
    }

    setGraph(new Map(newGraph));
    console.log(newGraph)
    //graph = new Map(newGraph);
    setLastNode(key);
  };

  const connected = (key1, key2) => {
    return (
      key1 === key2 ||
      graph.get(key1).adjacent.includes(key2) ||
      graph.get(key2).adjacent.includes(key1)
    );
  };

  const deleteNode = (key) => {
    const newGraph = graph;
    newGraph.get(key).adjacent.forEach((value) => {
      let entryValue = newGraph.get(value);
      newGraph.set(value, {
        ...entryValue,
        // filter deleted key from adjacent keys
        adjacent: entryValue.adjacent.filter((adjKey) => adjKey !== key),
      });
    });
    newGraph.delete(key);
    setLastNode(null);
    setGraph(new Map(newGraph));
    console.log(newGraph);
    //graph = new Map(newGraph);

    hover.current = false;
  };

  const connectPoints = (key) => {
    const newGraph = graph;
    addToAdjacencyArray(newGraph, key, lastNode);
    addToAdjacencyArray(newGraph, lastNode, key);

    setGraph(new Map(newGraph));
    //graph = new Map(newGraph);
    setLastNode(key);
  };

  // Implement dragging
  const updateNodePosition = (key, coordinates) => {
    const newGraph = graph;
    const val = newGraph.get(key);
    newGraph.set(key, {
      ...val,
      coordinates,
    });

    setGraph(new Map(newGraph));
    //graph = new Map(newGraph);
  };

  // delete and adjacencies
  const deleteAdjacency = (key1, key2) => {
    const newGraph = graph;
    const val1 = newGraph.get(key1);
    const val2 = newGraph.get(key2);

    newGraph.set(key1, {
      ...val1,
      adjacent: val1.adjacent.filter((adjKey) => adjKey !== key2),
    });
    newGraph.set(key2, {
      ...val2,
      adjacent: val2.adjacent.filter((adjKey) => adjKey !== key1),
    });

    setLastLine(null);
    setGraph(new Map(newGraph));
    //graph = new Map(newGraph);
  };

  const addToAdjacencyArray = (graphRef, key, adjKey) => {
    const keyValue = graphRef.get(key);
    graphRef.set(key, {
      ...keyValue,
      adjacent: [...keyValue.adjacent, adjKey],
    });
  };

  // When you click on the map itself
  const onMapClick = (coordinates) => {
    switch (mode) {
      case Mode.Add:
        if (!hover.current) addPoint(coordinates);
        break;
      default:
    }
  };

  // When you click on  a features on the map
  const onFeatureClick = (key) => {
    switch (mode) {
      case Mode.Add:
        // If there is no last node
        // set selected as last node
        // if there is a last node connect
        // clicked node to the last node
        if (lastNode && !connected(lastNode, key)) {
          connectPoints(key);
        } else {
          setLastNode(key);
          setLastLine(null);
        }
        break;
      case Mode.Edit:
        setLastNode(key);
        setLastLine(null);
        break;
      default:
    }
  };

  // Ensure that the mode changing side effects are executed
  const changeMode = (targetMode) => {
    switch (targetMode) {
      case Mode.Add:
      case Mode.Edit:
        setLastNode(null);
        break;
      default:
    }

    setMode(targetMode);
  };

  // Recalculate only when graph or lastNode changes
  const renderNodes = React.useCallback(() => {
    return [...graph.keys()].map((key) => (
      <Feature
        coordinates={graph.get(key).coordinates}
        key={key}
        properties={{ selected: key === lastNode }}
        onClick={() => onFeatureClick(key)}
        onMouseEnter={() => {
          hover.current = true;
        }}
        onMouseLeave={() => {
          hover.current = false;
        }}
        draggable={key === lastNode}
        onDragStart={() => setDragging(true)}
        onDrag={(e) => updateNodePosition(key, [e.lngLat.lng, e.lngLat.lat])}
        onDragEnd={() => setDragging(false)}
      />
    ));
  }, [graph, lastNode]);

  // useCallback ensure this is cached so that it doesn't render
  // unless graph or selected node/line has changed
  const renderLines = React.useCallback(() => {
    let lines = [...graph.keys()]
      // Skip nodes with no adjacencies
      .filter((key) => graph.get(key).adjacent.length > 0)
      // Generate line coordinates and flatten one level
      .flatMap(
        (key) =>
          graph
            .get(key)
            // filter half of the adjacencies so no duplicates
            .adjacent.filter((adj) => adj < key)
            .map((adj) => [adj, key])
        // create lines coordinates
      );

    const lastLineStringified = JSON.stringify(lastLine);

    return (
      <>
        <Layer id="lines" type="line" layout={lineLayout} paint={linePaint}>
          {lines.map((line) => (
            <Feature
              key={line.toString()}
              properties={{
                selected:
                  JSON.stringify(line) === lastLineStringified ||
                  (lastNode !== null &&
                    mode === Mode.Edit &&
                    line.includes(lastNode)),
              }}
              coordinates={[
                graph.get(line[0]).coordinates,
                graph.get(line[1]).coordinates,
              ]}
            />
          ))}
        </Layer>
        <Layer
          id="linesClick"
          type="line"
          layout={lineLayout}
          paint={{
            "line-color": "#000000",
            "line-width": 12,
            "line-opacity": 0,
          }}
        >
          {lines.map((line) => (
            <Feature
              key={line.toString()}
              coordinates={[
                graph.get(line[0]).coordinates,
                graph.get(line[1]).coordinates,
              ]}
              onClick={() => {
                if (mode === Mode.Edit) {
                  setLastLine(line);
                  setLastNode(null);
                }
              }}
            />
          ))}
        </Layer>
      </>
    );
  }, [graph, lastNode, lastLine, mode, onFeatureClick, updateNodePosition, setLastNode, setLastLine]);

  const onMapClickRef = React.useRef(onMapClick);
  onMapClickRef.current = onMapClick;

  React.useEffect(() => {
    map.on("click", (evt) => {
      onMapClickRef.current([evt.lngLat.lng, evt.lngLat.lat]);
    });
    map.on("mousemove", (targetMap, _) => {
      if (hover.current) map.getCanvas().style.cursor = "pointer";
      else if (dragging.current) map.getCanvas().style.cursor = "grabbing";
      else map.getCanvas().style.cursor = "default";
    });
  }, []);

  return (
    <>
      <PathingControls mode={mode} onModeClick={changeMode} />
      {renderLines()}
      <Layer id="nodes" type="circle" paint={circleStyles}>
        {renderNodes()}
      </Layer>
      {/* Show popup only in edit more */}
      {mode === Mode.Edit &&
        /* Show delete popup on selection*/
        (lastNode ? (
          <DeletePopup
            coordinates={graph.get(lastNode).coordinates}
            onClick={() => deleteNode(lastNode)}
          />
        ) : lastLine ? (
          <DeletePopup
            coordinates={midPoint(
              graph.get(lastLine[0]).coordinates,
              graph.get(lastLine[1]).coordinates
            )}
            onClick={() => deleteAdjacency(lastLine[0], lastLine[1])}
          />
        ) : null)}
    </>
  );
};

// Find the midpoint
const midPoint = (point1, point2) => {
  return [(point1[0] + point2[0]) / 2, (point1[1] + point2[1]) / 2];
};

// Component responsible for feature popup
const DeletePopup = ({ coordinates, onClick }) => {
  return (
    <Popup coordinates={coordinates} offset={{ bottom: [0, -15] }}>
      <DeleteButton id="delete" onClick={() => onClick()}>
        <Trash />
      </DeleteButton>
    </Popup>
  );
};

// Styled Button
const DeleteButton = styled.button`
  border: none;
  padding-top: 4;
  background-color: #fff;
  &:hover {
    color: #ff2f0d;
  }
  cursor: "pointer";
`;

// Styled Icon
const Trash = styled(DeleteOutlined)`
  width: 22px;
  height: 22px;
  font-size: 22px;
`;

export default PathEditor;
