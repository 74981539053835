import React from "react";
import ReactMapboxGl, { Layer, Source, MapContext } from "react-mapbox-gl";

const Mapbox = ReactMapboxGl({
  accessToken: process.env.GATSBY_MAPBOX_API_KEY,
  logoPosition: "bottom-right",
});

const MapWithImage = ({ coordinates, opacity = 0.4, url }) => {
  const IMAGE_SOURCE = {
    type: "image",
    url,
    coordinates,
  };
  const imagePaint = {
    "raster-opacity": opacity,
  };

  return (
    <Mapbox
      //eslint-disable-next-line
      style="mapbox://styles/mapbox/dark-v10"
      containerStyle={{
        height: "80vh",
        width: "80vw",
      }}
    >
      <MutableImageLayer id="nyan" source={IMAGE_SOURCE} paint={imagePaint} />
    </Mapbox>
  );
};

export const ImageLayer = ({ coordinates, opacity = 0.4, url }) => {
  const imagePaint = {
    // "raster-opacity": opacity,
    "raster-opacity": 0.8,
    // "raster-fade-duration": 0,
  };

  return url ? (
    // <MutableImageLayer id="nyan" source={IMAGE_SOURCE} paint={imagePaint} />
    <MutableImageLayer
      id="nyan"
      url={url}
      coordinates={coordinates}
      paint={imagePaint}
    />
  ) : null;
};

// source: { type, url, coordinates: [top left, top right, bottom right, bottom left]}
// const MutableImageLayer = ({ id, source, paint }) => {
// const MutableImageLayer = ({ id, source }) => {
const MutableImageLayer = ({ id, url, coordinates }) => {
  const map = React.useContext(MapContext);

  React.useEffect(() => {
    // Update coordinates using the map context api
    const imageSource = map.getSource(id);
    if (imageSource) {
      // imageSource.setCoordinates(source.coordinates);
      imageSource.setCoordinates(coordinates);
    }
    map.moveLayer(`${id}-layer`, "lines");
    // }, [map, source, id]);
  }, [map, url, coordinates, id]);

  return (
    <>
      <Source
        id={id}
        tileJsonSource={{
          type: "image",
          url,
          coordinates,
        }}
      />
      <Layer
        id={`${id}-layer`}
        sourceId={id}
        type="raster"
        paint={{
          // "raster-opacity": opacity,
          "raster-opacity": 0.8,
          // "raster-fade-duration": 0,
        }}
      />
    </>
  );
};

export default MapWithImage;
